import React from 'react'
import { Text } from '../../atoms'
import { TitleSection } from '../../molecules'
import { Client } from '../../../contexts'

export const Specialties = () => {
    const { coiffeurSelected } = Client.useCoiffeur()
    
    return (
        <>
            <TitleSection textTitle={'Ses spécialités'}>
                <Text.Paragraph.Normal className="flex flex-wrap gap-2">
                    {coiffeurSelected?._profil?._specialities.map((speciality) => (
                        <span className="px-2 py-1 rounded-full bg-green" key={speciality.key}>
                            {speciality.name}
                        </span>
                    ))}
                </Text.Paragraph.Normal>
            </TitleSection>
        </>
    )
}
