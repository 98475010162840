import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import './themes/index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)

if (process.env.REACT_APP_VERSION !== '1.2.19') {
    serviceWorkerRegistration.unregister()
} else {
    serviceWorkerRegistration.register()
}
