import React, { createContext, useContext, useState, useEffect } from 'react'

import { getSessionStorageValue, setSessionStorageValue } from '../storage'
import { request, cleanCache, createKey, createObject, addRequestToCache } from './utils'

import {Icon} from "../../components/atoms"

/* eslint no-unused-vars: 0 */
const RequestContext = createContext({
    message: Object,
    requests: Object,
    handleRequest: async (method, path, body, token, contentType, withoutCache) => {
        return Promise.resolve()
    },
    setMessage: (value) => {},
})

export function RequestContextProvider({ children }) {
    const [message, setMessage] = useState({ type: 'info', message: null })
    const [requests, setRequests] = useState({})
    const [noConnexionMode, setNoConnexionMode] = useState(false)

    const handleSetRequests = (value) => {
        setRequests(value)
        setSessionStorageValue('requests', JSON.stringify(value))
    }

    useEffect(() => {
        const requestsStored = JSON.parse(getSessionStorageValue('requests'))

        if (requestsStored) {
            setRequests(requestsStored)
        }

        return () => {}
    }, [])

    const getRequestCache = (method, path, body, token) => {
        const key = createKey(method, path)
        let isInCache = false

        const similarRequest = requests[key]

        if (similarRequest) {
            if (Object.is(similarRequest.body, body)) {
                if (Object.is(similarRequest.token, token)) {
                    isInCache = true
                }
            }
        }

        if (isInCache) {
            return similarRequest.response
        } else return null
    }

    const addRequest = (method, path, body, token, response) => {
        const key = createKey(method, path)
        const object = createObject(token, body, response)

        const cache = cleanCache(addRequestToCache(requests, key, object))

        handleSetRequests(cache)

        return
    }

    const axiosRequest = async (method, path, body, token, contentType) => {
        try {
            const response = await request(method, path, body, token, contentType)

            if (!response.error) {
                setNoConnexionMode(false)
                addRequest(method, path, body, token, response)

                return response
            } else {
                if (response.disconnected) {
                    throw 'Identifiant invalide'
                } else {
                    throw response.data?.error ? response.data.error : response.data?.message
                }
            }
        } catch (error) {
            if (typeof error === 'string') {
                if (error === 'Network Error') {
                    if (!noConnexionMode) {
                        setNoConnexionMode(true)
                    }
                } else if(error !== "Identifiant invalide") {
                    setMessage({ type: 'warning', message: error })
                }
            } else {
                if (error.message) {
                    setMessage({ type: 'error', message: error.message })
                } else {
                    setMessage({ type: 'error', message: 'Une erreur est survenue, réessaye plus tard' })
                }
            }
        }
    }

    const handleRequest = async (method, path, body, token, contentType, withoutCache) => {
        if (withoutCache) {
            return await axiosRequest(method, path, body, token, contentType)
        } else {
            const responseCache = getRequestCache(method, path, body)

            if (!responseCache) {
                return await axiosRequest(method, path, body, token, contentType)
            } else {
                return responseCache
            }
        }
    }

    return (
        <RequestContext.Provider
            value={{
                message: message,
                requests: requests,
                handleRequest: handleRequest,
                setMessage: setMessage,
            }}
        >
            {noConnexionMode && (
                <span className="absolute top-0 w-[90vw] left-[5vw] bg-white bg-opacity-70 rounded p-2 font-bold z-[99] flex items-center justify-center"><Icon.Offline className="w-6 mr-3" /> Pas de réseau</span>
            )}
            {children}
        </RequestContext.Provider>
    )
}

export const useRequest = () => useContext(RequestContext)
