import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Icon, Text } from '../../atoms'
import { Hairdresser } from '../../../contexts'

function Specialities({ initialItems, setNewValues, deleteItem }) {
    const [items, setItems] = useState(initialItems)

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const reorderedItems = Array.from(items)
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, reorderedItem)

        setItems(reorderedItems)
        setNewValues(reorderedItems)
    }

    useEffect(() => {
        setItems(initialItems)
    }, [initialItems])

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => (
                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            ...provided.draggableProps.style,
                                        }}
                                        className="flex p-3 mb-3 space-x-2 "
                                    >
                                        <Icon.DotMoveDrag className="w-4 text-gray-300" />
                                        <div className="mr-auto w-[80vw]">{item.name}</div>
                                        <div onClick={() => deleteItem(index)}>
                                            <Icon.Close className="text-gray-300 w-7" />
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

function Prestations({ initialItems, setNewValues }) {
    const { setPrestationSelected, setStep } = Hairdresser.usePrestation()
    const [items, setItems] = useState(initialItems)

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const reorderedItems = Array.from(items)
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, reorderedItem)

        setItems(reorderedItems)
        setNewValues(reorderedItems)
    }

    useEffect(() => {
        setItems(initialItems)
    }, [initialItems])

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => (
                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            ...provided.draggableProps.style,
                                        }}
                                        className="flex p-3 mb-3 space-x-6"
                                        onClick={() => {
                                            setPrestationSelected(item)
                                            setStep('edit')
                                        }}
                                    >
                                        <Icon.DotMoveDrag className="w-4 text-gray-300" />
                                        {item._photos && item._photos.length > 0 ? (
                                            <div
                                                className="rounded-full w-[44px] h-[44px] bg-light-gray border border-black border-opacity-10"
                                                style={{
                                                    backgroundImage: `url(${item._photos[0].thumbnailUrl})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center center',
                                                    backgroundSize: 'cover',
                                                }}
                                            ></div>
                                        ) : (
                                            <div className="p-3 rounded-full bg-light-gray">
                                                <Icon.Image className="w-5 text-gray-400" />
                                            </div>
                                        )}
                                        <div className="grid gap-2">
                                            <Text.Paragraph.Normal className="mr-auto">
                                                {item.name}
                                            </Text.Paragraph.Normal>
                                            <Text.Paragraph.Small className="">
                                                {item.price}€ - {item.duration / 60000}min
                                            </Text.Paragraph.Small>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}


export { Specialities, Prestations }
