import React from 'react'
import { HomeClient } from '../../components/organisms'

const Home = () => {
    return (
        <>
            <HomeClient.Title />
            <HomeClient.SearchSection />
        </>
    )
}

export default Home
