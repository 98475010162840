import React, { useEffect, useState } from 'react'
import { Text, Icon, Button } from '../../components/atoms'
import { HairDresser } from '../../components/organisms'
import { Hairdresser } from '../../contexts'
import { useLocation } from 'react-router'

const Clients = () => {
    const location = useLocation()
    const { clientSelected, method, setMethod, setClientSelected } = Hairdresser.useClients()
    const { search } = useLocation()
    const [loaded, setLoaded] = useState(false)

    if (search.indexOf('new') !== -1) {
        setMethod('create')
    }

    useEffect(() => {
        setClientSelected(null)
        setMethod()
        window.scrollTo(0, 0)
        setLoaded(true)
    }, [location])

    return (
        loaded && (
            <div className="relative">
                {method !== 'create' && (
                    <>
                        {clientSelected?._id ? (
                            <HairDresser.Clients.ClientDetails />
                        ) : (
                            <>
                                <div className="fixed z-[10] right-[5%] top-[83vh]">
                                    <Button.Primary
                                        onClick={() => setMethod('create')}
                                        iconLeft={<Icon.Add className="w-6 h-6 pr-1" />}
                                    />
                                </div>
                                <div className="fixed top-client grid w-[95%] bg-white py-3 gap-7">
                                    <Text.h1 className="text-center">Répertoire clients</Text.h1>
                                    <HairDresser.Clients.Search />
                                </div>
                                <div className="mt-[142px]"></div>
                                <HairDresser.Clients.List />
                            </>
                        )}
                    </>
                )}
                {method === 'create' && <HairDresser.Clients.Modal.Create />}
            </div>
        )
    )
}

export default Clients
