import React, { useState } from 'react'
import { Icon } from '..'
import { TimePicker } from 'react-ios-time-picker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const CloseFilledIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.6496 14.0003L21.8248 7.82517C22.281 7.369 22.281 6.63167 21.8248 6.1755C21.3686 5.71933 20.6313 5.71933 20.1751 6.1755L14 12.3506L7.8248 6.1755C7.36863 5.71933 6.6313 5.71933 6.17513 6.1755C5.71897 6.63167 5.71897 7.369 6.17513 7.82517L12.3503 14.0003L6.17513 20.1756C5.71897 20.6316 5.71897 21.3689 6.17513 21.8251C6.40263 22.0526 6.7013 22.1669 6.99997 22.1669C7.29863 22.1669 7.5973 22.0526 7.8248 21.8251L14 15.6501L20.1751 21.8251C20.4026 22.0526 20.7013 22.1669 21 22.1669C21.2986 22.1669 21.5973 22.0526 21.8248 21.8251C22.281 21.3689 22.281 20.6316 21.8248 20.1756L15.6496 14.0003Z"
            fill="#1C1C1C"
        />
    </svg>
)

const Classic = ({
    labelValue,
    placeholder,
    value,
    setValue,
    rightIcon,
    rightText,
    type,
    onBlur,
    unit,
    readonly,
    hideClearButton,
    min,
    className,
    hiddeClock,
}) => {
    return (
        <div className="relative grid w-full gap-1">
            <label className="ml-2 text-lg font-medium">{labelValue}</label>
            <div
                className={`flex justify-between border border-black rounded-full px-6 py-2 w-full ${
                    className ? className : ''
                }`}
            >
                <input
                    name={labelValue}
                    className={`text-lg font-normal focus:border-none w-[100%] ${hiddeClock ? 'hidde-clock' : ''}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => {
                        if (type === 'email') {
                            setValue(e.target.value.split(' ')[0])
                        } else {
                            setValue(e.target.value)
                        }
                    }}
                    type={type || 'text'}
                    onBlur={() => {
                        if (onBlur) onBlur()
                    }}
                    readOnly={readonly}
                    min={min}
                />
                {unit && <div className="absolute bg-white right-4">{unit}</div>}
                {rightText && !rightIcon && <div className="absolute flex right-4 bottom-3">{rightText}</div>}
                {rightIcon && <div className="absolute flex right-4 bottom-3">{rightIcon}</div>}
                {!unit && value && !hideClearButton && (
                    <div
                        className={`absolute bg-white ${rightIcon ? 'right-14' : 'right-4'}`}
                        onClick={() => {
                            if (type !== 'date') setValue('')
                        }}
                    >
                        <CloseFilledIcon />
                    </div>
                )}
            </div>
        </div>
    )
}

const Password = ({ labelValue, placeholder, value, setValue }) => {
    const [type, setType] = useState('password')

    return (
        <div className="relative grid gap-1">
            <label className="ml-2 text-lg font-medium">{labelValue}</label>
            <div className="flex justify-between w-full px-6 py-3 border border-black rounded-full items-flex">
                <input
                    name={labelValue}
                    className="text-lg font-normal focus:border-none"
                    label={labelValue}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    type={type || 'text'}
                />
                <div
                    className="absolute bg-white right-5 bottom-3"
                    onClick={() => setType(type === 'password' ? 'text' : 'password')}
                >
                    {type === 'password' ? <Icon.EyeClose className="w-6" /> : <Icon.EyeOpen className="w-5" />}
                </div>
            </div>
        </div>
    )
}

const TextArea = ({ labelValue, placeholder, value, setValue, height }) => {
    return (
        <div className="grid gap-2">
            <p className="text-lg font-medium">{labelValue}</p>
            <textarea
                className={`px-6 py-3 border border-black rounded-lg min-h-[200px] ${
                    height ? height : 'h-[200px]'
                } overflow-y-auto`}
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
    )
}

const TimePickerInput = ({ labelValue, placeholder, value, className, onSave }) => {
    return (
        <div className="relative grid w-full gap-1">
            <label className="ml-2 text-lg font-medium">{labelValue}</label>
            <div
                className={`flex justify-between border border-black rounded-full px-6 py-3 w-full ${
                    className ? className : ''
                }`}
            >
                <TimePicker
                    pickerDefaultValue={'12:00'}
                    placeHolder={placeholder}
                    cancelButtonText="Annuler"
                    saveButtonText="Enregistrer"
                    inputClassName={`text-lg font-normal border-none w-[100%]`}
                    value={value}
                    onSave={(value) => {
                        onSave(value)
                    }}
                />
            </div>
        </div>
    )
}

const Date = ({ labelValue, value, setValue }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label={labelValue} value={value} onChange={(newValue) => setValue(newValue)} />
        </LocalizationProvider>
    )
}

export { Classic, Password, TextArea, TimePickerInput, Date }
