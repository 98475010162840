import React from 'react'
import { Select, SelectItem } from '@nextui-org/react'
import { Icon } from '..'

const Dropdown = ({ value, options, setValue }) => {
    return (
        <Select
            aria-label="dropdown"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="px-6 border border-black rounded-full"
            classNames={{
                listbox: ['border', 'border-black', 'rounded-3xl', 'p-3', 'w-full', 'bg-white'],
            }}
            defaultSelectedKeys={[value]}
        >
            {options.map((option) => (
                <SelectItem
                    value={option.value}
                    key={option.value}
                    classNames={{ base: ['data-[selected=true]:bg-light-gray', 'rounded-lg', 'p-3'] }}
                >
                    {option.label}
                </SelectItem>
            ))}
        </Select>
    )
}

const SelectAdd = ({ value, options, setValue }) => {
    return (
        <div className="flex items-center w-full grid-cols-12 px-2 border border-black rounded-full">
            <Select
                aria-label="dropdown"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                className="mx-auto"
                classNames={{
                    listbox: ['border', 'border-black', 'rounded-full', 'p-3', 'w-full', 'bg-white'],
                    trigger: ['no-border']
                }}
                defaultSelectedKeys={[value]}
                endContent={() => <Icon.Add />}
            >
                {options.map((option) => (
                    <SelectItem
                        value={option.value}
                        key={option.value}
                        classNames={{ base: ['data-[selected=true]:bg-light-gray', 'rounded-lg', 'p-3'] }}
                    >
                        {option.label}
                    </SelectItem>
                ))}
            </Select>
        </div>
    )
}

export default Dropdown
export { SelectAdd }
