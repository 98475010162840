import React from 'react'

import { Text } from '../../../atoms'
import { Card } from '../../../molecules'

import { formatSecondsToHHhmm } from '../../../../utils'
import { Hairdresser } from '../../../../contexts'
import dayjs from 'dayjs'

const Day = () => {
    const { data } = Hairdresser.useAgenda()
    const { handleBookingSelected, readBooking } = Hairdresser.useBooking()

    return (
        <div className="grid gap-6">
            {data.waitings?.map((waiting, index) => {
                const displayPrice = waiting.priceVariable ? `À partir de ${waiting.price}` : `${waiting.price}`

                if (waiting.state === 'waiting') {
                    return (
                        <div key={waiting._id}>
                            {index !== 0 ? (
                                data.waitings[index - 1]?.date !== waiting.date ? (
                                    <div className="flex items-center text-nowrap">
                                        <Text.h2 className="mr-2 w-fit-content">
                                            {dayjs(waiting.date).format('ddd DD MMMM')}
                                        </Text.h2>
                                        <div className="w-full h-1 bg-opacity-50 bg-purple"></div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <div className="flex items-center text-nowrap">
                                    <Text.h2 className="mr-2 w-fit-content">
                                        {dayjs(waiting.date).format('ddd DD MMMM')}
                                    </Text.h2>
                                    <div className="w-full h-1 bg-opacity-50 bg-purple"></div>
                                </div>
                            )}

                            <Card.WaitingEvent
                                start={formatSecondsToHHhmm(waiting.from)}
                                price={displayPrice}
                                prestations={waiting._prestation}
                                client={waiting._client}
                                address={waiting.address}
                                updateBooking={() => {
                                    handleBookingSelected('update', waiting)
                                }}
                                deleteBooking={() => {
                                    handleBookingSelected('delete', waiting)
                                }}
                                acceptBooking={() => {
                                    handleBookingSelected('accept', waiting)
                                }}
                                selectBooking={() => handleBookingSelected('generalWaiting', waiting)}
                            />
                        </div>
                    )
                }
                if (waiting.state === 'cancel') {
                    return (
                        <div key={waiting._id}>
                            {index !== 0 ? (
                                data.waitings[index - 1]?.date !== waiting.date ? (
                                    <div className="flex items-center text-nowrap">
                                        <Text.h2 className="mr-2 w-fit-content">
                                            {dayjs(waiting.date).format('ddd DD MMMM')}
                                        </Text.h2>
                                        <div className="w-full h-1 bg-opacity-50 bg-purple"></div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <div className="flex items-center text-nowrap">
                                    <Text.h2 className="mr-2 w-fit-content">
                                        {dayjs(waiting.date).format('ddd DD MMMM')}
                                    </Text.h2>
                                    <div className="w-full h-1 bg-opacity-50 bg-purple"></div>
                                </div>
                            )}

                            <Card.CancelEvent
                                start={formatSecondsToHHhmm(waiting.from)}
                                price={displayPrice}
                                prestations={waiting._prestation}
                                client={waiting._client}
                                address={waiting.address}
                                sayOkay={() => readBooking(waiting._id)}
                                selectBooking={() => handleBookingSelected('generalWaiting', waiting)}
                            />
                        </div>
                    )
                }
            })}
            {data.waitings?.length === 0 && (
                <div className="h-[50vh] flex">
                    <Text.Paragraph.Normal className="my-auto text-center">
                        {' '}
                        Vous n’avez aucune réservation à valider pour le moment.
                    </Text.Paragraph.Normal>
                </div>
            )}
        </div>
    )
}

export default Day
