import React from 'react'
import { Bookings } from '../../components/organisms'

const Profil = () => {
    return (
        <>
            <Bookings.Title />
            <Bookings.Form />
            <Bookings.Logout />
        </>
    )
}
export default Profil
