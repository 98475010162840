import React from 'react'
import { Button, Icon, Text } from '../../../atoms'

import { Hairdresser } from '../../../../contexts'

const Add = () => {
    const { handleBookingSelected, showMenu, setShowMenu } = Hairdresser.useBooking()
    return (
        <div className={` ${showMenu?'fixed top-0 left-0 w-full h-svh z-[14]':''}`}>
            <div className={`fixed right-5 bottom-[84px] grid gap-2 z-[13] `}>
                <div
                    className={`bg-white border-[1.5px] border-black p-5 rounded-xl ${
                        showMenu ? 'grid gap-5' : 'hidden'
                    }`}
                >
                    <div
                        className="flex justify-between cursor-pointer"
                        onClick={() => handleBookingSelected('create', null)}
                    >
                        <Text.Clickable className="mr-3">Ajouter un rendez-vous</Text.Clickable>
                        <Icon.ArrowNext className="w-5" />
                    </div>
                    <div
                        className="flex justify-between cursor-pointer"
                        onClick={() => handleBookingSelected('create-private', null)}
                    >
                        <Text.Clickable className="mr-3">Ajouter une indiponibilité</Text.Clickable>
                        <Icon.ArrowNext className="w-5" />
                    </div>
                </div>
                <div className="ml-auto">
                    <Button.Secondary iconRight={<Icon.Add />} onClick={() => {setShowMenu(!showMenu)}} />
                </div>
            </div>
        </div>
    )
}

export default Add
