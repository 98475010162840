import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, DateProvider, Icon, Modal, Text, Input } from '../../atoms'
import { Card } from '../../molecules'

import { Client } from '../../../contexts'
import { DateCalendar } from '@mui/x-date-pickers'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { formatSecondsToHHhmm } from '../../../utils'
import placeholder from '../../../assets/icons/Image.svg'

import dayjs from 'dayjs'
import fr from 'dayjs/locale'
dayjs.locale(fr)

const Prestation = () => {
    const { prestationSelected, setPrestationSelected } = Client.useBasket()

    const renderPhotoPairs = () => {
        const photos = prestationSelected?._photos || []

        const pairs = []
        for (let i = 0; i < photos.length; i += 2) {
            const firstPhoto = photos[i]
            const secondPhoto = i + 1 < photos.length ? photos[i + 1] : null

            pairs.push(
                <div key={`pair-${i}`} className="flex justify-center space-x-4">
                    {renderPhoto(firstPhoto)}
                    {secondPhoto && renderPhoto(secondPhoto)}
                </div>
            )
        }

        return pairs
    }

    const renderPhoto = (photo) => (
        <div
            key={photo._id}
            className="w-[42vw] h-[42vw] bg-light-gray rounded relative border border-opacity-10"
            style={{
                backgroundImage: `url(${photo?.thumbnailUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            }}
        ></div>
    )

    return (
        <Modal.default
            isOpen={prestationSelected}
            title={prestationSelected?.name}
            setIsOpen={() => setPrestationSelected(null)}
        >
            <Text.Paragraph.Normal className="text-[24px] font-medium text-center">
                {prestationSelected?.price}€ - {prestationSelected?.duration / 60000}min
            </Text.Paragraph.Normal>
            <Text.Paragraph.Normal className="text-center">{prestationSelected?.description}</Text.Paragraph.Normal>
            {renderPhotoPairs()}
        </Modal.default>
    )
}

function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props

    const isSelected = highlightedDays.filter((day) => day.date === dayjs(props.day).format('YYYY-MM-DD'))?.length > 0

    return (
        <div className="relative mx-auto">
            <div
                className={`absolute top-0 bottom-0 rounded-full bg-purple ${
                    isSelected && !outsideCurrentMonth ? 'w-full h-full' : ''
                } ${isSelected ? '' : 'bg-white z-50 h-full w-full opacity-60'}`}
            ></div>
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </div>
    )
}

const Algorithme = () => {
    const { coiffeurSelected, address } = Client.useCoiffeur()
    const {
        prestations,
        algorithm,
        loading,
        getAlgorithmResult,
        setAlgorithm,
        results,
        bookgSchedule,
        step,
        setStep,
        changePrestation,
    } = Client.useBasket()

    const [daySelected, setDaySelected] = useState(dayjs())
    const [bookableDays, setbookableDays] = useState(results?.filter((result) => result.bookings?.length > 0))
    const [bookings, setBookings] = useState([])
    const [bookingSelected, setBookingSelected] = useState()

    useEffect(() => {
        setbookableDays(results?.filter((result) => result.bookings?.length > 0))
    }, [results])

    const [price, setPrice] = useState(0)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        if (prestations) {
            const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

            let newDuration = 0
            let newPrice = 0
            prestationsSelected.forEach((prestation) => {
                newDuration += (prestation.duration / 1000) * prestation.quantity
                const splitted = prestation.price.split(' ')
                splitted.forEach((split) => {
                    if (parseFloat(split).toString() != 'NaN') newPrice += parseFloat(split) * prestation.quantity
                })
            })

            setPrice(newPrice)
            setDuration(newDuration)
        }
    }, [prestations])

    useEffect(() => {
        const isSelected = bookableDays.filter((day) => day.date === daySelected.format('YYYY-MM-DD'))

        if (isSelected.length > 0) {
            setBookings(isSelected[0]?.bookings)
        } else {
            setBookings([])
        }
    }, [daySelected])

    return (
        <Modal.default
            isOpen={step === 'schedule'}
            title={"Choix de la date et de l'heure"}
            setIsOpen={() => {
                setStep('general')
                setAlgorithm(false)
            }}
            confirmButton={
                algorithm ? (
                    <Button.Primary
                        iconRight={<Icon.ArrowNext className="w-5" />}
                        onClick={() => {
                            bookgSchedule(daySelected.format('YYYY-MM-DD'), bookingSelected, address)
                        }}
                    >
                        Confirmer
                    </Button.Primary>
                ) : (
                    <Button.Primary
                        iconRight={<Icon.ArrowNext className="w-5" />}
                        onClick={() => {
                            getAlgorithmResult(address)
                        }}
                        disabled={prestations?.filter((prestation) => prestation.quantity > 0).length === 0}
                    >
                        Suivant
                    </Button.Primary>
                )
            }
        >
            {loading ? (
                <div className="grid w-full gap-8 py-10">
                    <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
                    <Text.Paragraph.Normal className="text-[24px] text-center">
                        Recherche des places disponibles ...
                    </Text.Paragraph.Normal>
                </div>
            ) : algorithm ? (
                <div>
                    <DateProvider>
                        <DateCalendar
                            value={daySelected}
                            onChange={(value) => {
                                setDaySelected(value)
                            }}
                            views={['day']}
                            slots={{ day: ServerDay }}
                            slotProps={{ day: { highlightedDays: bookableDays } }}
                        />
                    </DateProvider>

                    <div className="grid gap-3">
                        <Text.Paragraph.Normal className="capitalize text-center font-medium text-[18px]">
                            {daySelected.format('dddd DD MMMM')}
                        </Text.Paragraph.Normal>
                        {bookings.length > 0 ? (
                            <div className="grid grid-cols-3 gap-3">
                                {bookings.map((booking) => {
                                    return (
                                        <div className="mx-auto" key={booking.start}>
                                            <div
                                                className={`transition-all py-2 px-4 text-center border border-black rounded ${
                                                    booking.start === bookingSelected?.start
                                                        ? 'bg-blue'
                                                        : 'border-purple bg-light-gray'
                                                }`}
                                                onClick={() => setBookingSelected(booking)}
                                            >
                                                {dayjs('2000-01-01')
                                                    .set('hour', booking.start / 3600)
                                                    .set('minutes', (booking.start / 60) % 60)
                                                    .format('HH[h]mm')}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <Text.Paragraph.Normal>
                                {coiffeurSelected?._profil.firstName} {coiffeurSelected?._profil.lastName} n'a plus de
                                disponibilité où n'est pas dans votre secteur ce jour là.
                            </Text.Paragraph.Normal>
                        )}
                    </div>
                </div>
            ) : (
                <div className="grid gap-10 mb-10">
                    <div className="grid gap-4">
                        <Text.Paragraph.Normal className="text-[px]">Prestations</Text.Paragraph.Normal>
                        {prestations?.map((prestation, index) => {
                            if (prestation.quantity > 0) {
                                return (
                                    <Card.PrestationRecap
                                        key={prestation._id}
                                        srcImage={`${
                                            prestation._photos?.length > 0
                                                ? prestation._photos[0].thumbnailUrl
                                                : placeholder
                                        }`}
                                        alt={`Avatar Boy`}
                                        title={prestation.name}
                                        subTitle={`${prestation.price} - ${prestation.duration / 1000 / 60}min`}
                                        quantity={prestation.quantity}
                                        changePrestation={(quantity) => changePrestation(index, prestation, quantity)}
                                    />
                                )
                            }
                        })}
                    </div>
                    <div className="grid gap-4">
                        <Text.Paragraph.Normal>Total</Text.Paragraph.Normal>
                        <div className="grid grid-cols-2">
                            <div className="flex items-center">
                                <Icon.Coins className="w-9" />
                                <Text.Paragraph.Normal>{price}€</Text.Paragraph.Normal>
                            </div>
                            <div className="flex items-center">
                                <Icon.Clock className="w-9" />
                                <Text.Paragraph.Normal>{duration / 60}min</Text.Paragraph.Normal>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-4">
                        <Text.Paragraph.Normal>Adresse</Text.Paragraph.Normal>
                        <Text.Paragraph.Normal className="italic">{address.formatted}</Text.Paragraph.Normal>
                    </div>
                </div>
            )}
        </Modal.default>
    )
}

const Success = () => {
    const { coiffeurSelected } = Client.useCoiffeur()
    const { prestations, dateSelected, timeSelected, step, setStep, clearBasket } = Client.useBasket()

    const [price, setPrice] = useState(0)
    const [priceVariable, setPriceVariable] = useState(false)
    const [localPrestations, setLocalPrestations] = useState([])

    useEffect(() => {
        if (prestations) {
            const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

            let newPrice = 0
            let isPriceVariable = false
            prestationsSelected.forEach((prestation) => {
                const splitted = prestation.price.split(' ')
                splitted.forEach((split) => {
                    if (parseFloat(split).toString() != 'NaN') newPrice += parseFloat(split) * prestation.quantity
                })
                if (splitted.length > 1) {
                    isPriceVariable = true
                }
            })

            const prestationsArray = []
            prestationsSelected.map((prestation) => {
                for (let i = 0; i < prestation.quantity; i++) {
                    prestationsArray.push(prestation._id)
                }
            })
            setLocalPrestations(prestationsSelected)
            setPrice(newPrice)
            setPriceVariable(isPriceVariable)
        }
    }, [prestations])

    const displayPrice = priceVariable ? `À partir de ${price}€` : `${price}€`

    return (
        <Modal.default
            title={`Vous recevrez un mail de validation lorsque votre coiffeur aura confirmé votre rendez-vous`}
            isOpen={step === 'confirm'}
            setIsOpen={() => {
                clearBasket()
                setStep()
            }}
            // confirmButton={<Button.Primary iconLeft={<Icon.CalendarCheck />}>Ajouter à mon calendrier</Button.Primary>}
            backButton={<></>}
        >
            <Card.PrestationRecapConfirmed
                srcImage={`${coiffeurSelected?._profil?.avatar?.thumbnailUrl}`}
                title={`${coiffeurSelected?._profil.firstName} ${coiffeurSelected?._profil.lastName}`}
                subTitle={localPrestations.map(
                    (prestation, index) => `${index !== 0 ? ' - ' : ''}${prestation.name} (${prestation.type})`
                )}
                infoRdv={`${dayjs(dateSelected).format('ddd DD MMMM')} à ${formatSecondsToHHhmm(
                    timeSelected
                )} - ${displayPrice}`}
                text={'Vous recevrez un mail de validation lorsque votre coiffeur aura confirmé votre rendez-vous'}
            />
        </Modal.default>
    )
}

const Address = () => {
    const { address, coiffeurSelected, coiffeurs, getCoiffeurs } = Client.useCoiffeur()
    const { step, setStep } = Client.useBasket()

    const [verified, setVerified] = useState(false)
    const [isInTheZone, setIsInTheZone] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(false)
        console.log('coiffeur error', coiffeurs)
        if (coiffeurs) {
            let coiffeurInZone = coiffeurs?.filter(
                (coiffeur) =>
                    coiffeur._id == coiffeurSelected._id &&
                    address.formatted === coiffeur.addressOfTheZone.formatted &&
                    coiffeur.isInTheZone
            )
            if (coiffeurInZone?.length > 0) {
                setIsInTheZone(true)
                setVerified(true)
            } else {
                setVerified(false)
            }
        }
    }, [coiffeurs])

    useEffect(() => {
        if (coiffeurs) {
            if (
                coiffeurs?.filter((coiffeur) => coiffeur._id == coiffeurSelected._id).length <= 0 ||
                address.formatted === ''
            ) {
                setStep('address')
            }
        }
    }, [step])

    if (step === 'address')
        return (
            <div className="grid gap-5">
                <Text.h2>Veuillez renseigner votre adresse</Text.h2>
                <Text.Paragraph.Normal>
                    Pour prendre rendez-vous, vous devez renseigner l'adresse de votre domicile afin de vérifier que ce
                    coiffeur puisse se déplacer chez vous.
                </Text.Paragraph.Normal>
                <Input.Searchbar
                    setValue={async (value) => {
                        console.log(value)
                        console.log(value)
                        setLoading(true)
                        await getCoiffeurs(value)
                    }}
                    setLoading={(value) => {
                        if (value) {
                            setLoading(value)
                        }
                    }}
                />
                {loading ? (
                    <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
                ) : verified ? (
                    isInTheZone ? (
                        <Button.Primary iconLeft={<Icon.ArrowNext />} onClick={() => setStep()}>
                            Réserver
                        </Button.Primary>
                    ) : (
                        <>
                            <Text.Paragraph.Normal>
                                Ce professionnel ne se déplace pas dans votre secteur
                            </Text.Paragraph.Normal>
                        </>
                    )
                ) : (
                    <>
                        {address.formatted !== '' ? (
                            <>
                                <Text.Paragraph.Normal>
                                    Ce professionnel ne se déplace pas dans votre secteur
                                </Text.Paragraph.Normal>
                                <Button.Primary iconLeft={<Icon.ArrowNext />} onClick={() => navigate('/')}>
                                    Rechercher un coiffeur
                                </Button.Primary>
                            </>
                        ) : (
                            <>
                                <Text.Paragraph.Normal>Veuillez saisir une adresse</Text.Paragraph.Normal>
                            </>
                        )}
                    </>
                )}
            </div>
        )
}

export { Prestation, Algorithme, Success, Address }
