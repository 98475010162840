import React, { useRef, useEffect, useState } from 'react'
import mapboxgl from '!mapbox-gl'

mapboxgl.accessToken = 'pk.eyJ1IjoiYXJlYXRvbWljIiwiYSI6ImNsZm05c2F5ZDA5aWk0NG5ya254OTBydTIifQ._0H7y7lJd-u6Ka1ALlCp_g'

const Tour = ({ bookings, address, selectBooking, dataView }) => {
    const mapContainer = useRef(null)
    const map = useRef(null)
    const [lng, setLng] = useState(address?.lon)
    const [lat, setLat] = useState(address?.lat)
    const [zoom, setZoom] = useState(9)

    const [markers, setMarkers] = useState([])

    const createMarkers = () => {
        const markersCreated = []

        if (bookings) {
            bookings.map((booking, index) => {
                const el = document.createElement('div')
                el.className =
                    'flex items-center justify-center px-2 text-xl border rounded-full w-fit h-fit border-favorite marker bg-purple'
                el.innerHTML = `${index + 1}`
                el.style.backgroundSize = '100%'
                el.onclick = () => selectBooking(booking)

                const marker = new mapboxgl.Marker(el)
                    .setLngLat([booking.address?.lon, booking.address?.lat])
                    .addTo(map.current)
                markersCreated.push(marker)
            })

            if (bookings.length > 0) map.current.flyTo({ center: [bookings[0].address.lon, bookings[0].address.lat] })
        }
        setMarkers(markersCreated)
    }

    useEffect(() => {
        if (map.current) return // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom,
        })

        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4))
            setLat(map.current.getCenter().lat.toFixed(4))
            setZoom(map.current.getZoom().toFixed(2))
        })
    })

    useEffect(() => {
        if (map.current) {
            markers.map((marker) => marker.remove())

            createMarkers()
        }
    }, [bookings, dataView])

    return <div ref={mapContainer} className="h-[300px] w-full relative" />
}

export default Tour
