import React from 'react'
import { Text } from '../../atoms'

export const Title = () => {
    return (
        <>
            <Text.h1 className="text-center">Mon compte</Text.h1>
        </>
    )
}
