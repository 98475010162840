import React from "react"
import { Button, Icon } from "../../atoms"

export const Footer = () => {
    return(
        <section className="my-10">
            <div className="flex justify-center gap-12">
                <Button.Secondary iconLeft={<Icon.ArrowBack className={`h-8`} />}>Retour</Button.Secondary>
                <Button.Primary iconRight={<Icon.ArrowNext className={`h-8`} />}>Confirmer</Button.Primary>
            </div>
        </section>
    )
}