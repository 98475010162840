import React from 'react'

import { Text } from '../../components/atoms'
import { HairDresser } from '../../components/organisms'

const Waiting = () => {
    return (
        <div className="grid gap-8">
            <Text.h1 className="text-center">Réservations en attente</Text.h1>
            <HairDresser.Waiting.Day />
            <HairDresser.Agenda.Modal />
        </div>
    )
}

export default Waiting
