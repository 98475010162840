import React, { useEffect } from 'react'

import { HairDresser } from '../../components/organisms'
import { Hairdresser as Context } from '../../contexts'
import { useLocation } from 'react-router'

const Home = () => {
    const { setClientSelected } = Context.useClients()
    const { showMenu, setShowMenu } = Context.useBooking()
    const { search } = useLocation()

    useEffect(() => {
        if (search.indexOf('clientSelected') === -1) {
            setClientSelected()
        }
    }, [])

    return (
        <div
            className="grid gap-6"
            onClick={() => {
                showMenu === true && setShowMenu(false)
            }}
        >
            <HairDresser.Agenda.Calendar />
            <HairDresser.Agenda.Bookings />
            <HairDresser.Agenda.Modal />
            <HairDresser.Agenda.Add />
        </div>
    )
}

export default Home
