import React, { useEffect, useState } from 'react'

const Radio = ({ initialValue, setValue, controlled, controlledValue }) => {
    const [active, setActive] = useState(initialValue)

    const handleChange = () => {
        const value = !active
        if (controlled) {
            if (value) {
                setActive(true)
                setValue(true)
            } else {
                setActive(controlledValue)
            }
        } else {
            setActive(value)
            if (setValue) {
                setValue(value)
            }
        }
    }

    useEffect(() => {
        setActive(controlledValue)
    }, [controlledValue])

    return (
        <div
            className={`w-6 h-6 rounded-full border-[1.5px] border-black flex items-center justify-center transition-all ${
                active ? 'bg-green' : 'bg-white'
            }`}
            onClick={handleChange}
        >
            <div className={`w-4 h-4 rounded-full transition-all ${active ? 'bg-black' : 'bg-white'}`}></div>
        </div>
    )
}

export default Radio
