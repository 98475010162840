import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useHistoryTracking = () => {
    const [history, setHistory] = useState([])
    const location = useLocation()

    useEffect(() => {
        // Add the current location to the history stack
        setHistory((prevHistory) => [...prevHistory, location.pathname])
    }, [location])

    // Function to get the last visited path
    const getHistory = () => {
        return history
    }

    return { getHistory }
}

export default useHistoryTracking
