import React, { useEffect } from 'react'

import { Hairdresser } from '../../../../contexts'
import { Icon, Input, Text } from '../../../atoms'
import { Card, Return, TitleSection } from '../../../molecules'

import Boy from '../../../../assets/avatar/Boy.svg'
import { formatSecondsToHHhmm } from '../../../../utils'
import dayjs from 'dayjs'
import { Modal } from '.'
import { useAuth } from '../../../../contexts/auth'

const Search = () => {
    const { search, getClients, filterClients } = Hairdresser.useClients()
    const { isLogged, id, token } = useAuth()

    useEffect(() => {
        if (isLogged()) {
            if (search !== '') {
                filterClients(search)
            } else {
                getClients()
            }
        }
    }, [id, token])

    return (
        <Input.Text.Classic
            placeholder="Jean Dupont"
            value={search}
            setValue={(value) => {
                filterClients(value)
            }}
            rightIcon={<Icon.Search className="w-6" />}
        />
    )
}

const ClientDetails = () => {
    const { clientSelected, method, setClientSelected, getClientById, setMethod } = Hairdresser.useClients()
    const { handleBookingSelected } = Hairdresser.useBooking()

    useEffect(() => {
        getClientById(clientSelected._user._id)
    }, [])

    return (
        <div>
            {method !== 'edit' ? (
                <>
                    <Return
                        className="h-6 cursor-pointer"
                        onClick={() => {
                            setClientSelected(null)
                        }}
                    >
                        <div onClick={() => setMethod('delete')}>
                            <Icon.Trash className={`h-8`} />
                        </div>
                    </Return>
                    <Card.LargeProfil
                        srcImage={
                            clientSelected?._user?._profil?.avatar?.urlThumbnail
                                ? clientSelected?._user?._profil?.avatar?.urlThumbnail
                                : Boy
                        }
                        firstName={clientSelected?._user?._profil?.firstName}
                        lastName={clientSelected?._user?._profil?.lastName}
                    />
                    <TitleSection
                        textTitle={`Coordonnées`}
                        icon={
                            clientSelected._user?.associationForUnLoggedBooking && (
                                <div onClick={() => setMethod('edit')}>
                                    <Text.Clickable className="flex items-center">
                                        <Icon.Pencil className="h-6" />
                                        Modifier
                                    </Text.Clickable>
                                </div>
                            )
                        }
                    >
                        <div className="grid gap-2 ">
                            <Text.Paragraph.Normal className="flex items-center gap-4">
                                <Icon.LocationPin className="h-8" />
                                {clientSelected?._user?._profil?.address?.formatted}
                            </Text.Paragraph.Normal>
                            <Text.Link
                                className="flex items-center gap-4 no-underline"
                                href={`tel:${
                                    clientSelected?._user._profil?.phoneNumber?.phone.length === 10
                                        ? clientSelected?._user._profil?.phoneNumber?.phone
                                        : `0${clientSelected?._user._profil?.phoneNumber?.phone}`
                                }`}
                            >
                                <Icon.Phone className="h-8" />
                                {clientSelected?._user?._profil?.phoneNumber?.phone.length === 9
                                    ? ('0' + clientSelected?._user?._profil?.phoneNumber?.phone).replace(
                                          /(.{2})/g,
                                          '$1 '
                                      )
                                    : clientSelected?._user?._profil?.phoneNumber?.phone.replace(/(.{2})/g, '$1 ')}
                            </Text.Link>
                            <Text.Paragraph.Normal className="flex items-center gap-4">
                                <Icon.Email className="h-8" />
                                {!clientSelected._user?.associationForUnLoggedBooking
                                    ? clientSelected?._user?.email
                                    : ''}
                            </Text.Paragraph.Normal>
                        </div>
                    </TitleSection>
                    <TitleSection textTitle={`Réservation à venir`}>
                        {clientSelected?.bookings?.map((booking) => {
                            if (booking.state === 'booked' && dayjs(booking.date).isAfter(dayjs())) {
                                const prestationsDisplay = booking._prestation
                                    .reduce((accumulator, currentValue) => {
                                        const valueFind = accumulator.find((item) => item.name === currentValue.name)

                                        if (valueFind) {
                                            valueFind.count = (valueFind.count || 1) + 1
                                        } else {
                                            accumulator.push({ ...currentValue, count: 1 })
                                        }
                                        return accumulator
                                    }, [])
                                    .map(
                                        (prestation) =>
                                            `${prestation.name}${prestation.count > 1 ? ` x${prestation.count}` : ''}`
                                    )
                                    .join(', ')

                                return (
                                    <>
                                        <div className="z-0 flex justify-between w-full my-4" key={booking._id}>
                                            <div className="flex space-x-2">
                                                <span className={`w-5 h-5 rounded-full z-[12] bg-blue`}></span>
                                                <div
                                                    className="grid cursor-pointer"
                                                    onClick={() => {
                                                        handleBookingSelected('details', booking)
                                                    }}
                                                >
                                                    <div className="grid">
                                                        <Text.Paragraph.Normal className="text-xl font-medium truncate"></Text.Paragraph.Normal>

                                                        <Text.Paragraph.Normal className="font-bold">
                                                            {prestationsDisplay} -{' '}
                                                            {booking.priceVariable
                                                                ? `À partir de ${booking.price}€`
                                                                : `${booking.price}€`}
                                                        </Text.Paragraph.Normal>
                                                        <Text.Paragraph.Normal>{`${dayjs(booking.date).format(
                                                            'ddd DD MMMM'
                                                        )} à ${formatSecondsToHHhmm(
                                                            booking.from
                                                        )}`}</Text.Paragraph.Normal>
                                                        <Text.Paragraph.Normal>
                                                            {booking.note.map((comment) => {
                                                                return <span key={comment._id}>{comment.value}</span>
                                                            })}
                                                        </Text.Paragraph.Normal>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={() => handleBookingSelected('note', booking)}>
                                                <Icon.Message className="h-8 cursor-pointer" />
                                            </div>
                                        </div>
                                    </>
                                )
                            } else {
                                return ''
                            }
                        })}
                    </TitleSection>
                    <TitleSection textTitle={`Historique des réservations`}>
                        <div className="relative">
                            {clientSelected?.bookings?.map((booking, index) => {
                                if (booking.state === 'done' || dayjs(booking.date).isBefore(dayjs())) {
                                    return (
                                        <>
                                            <div className="z-0 flex justify-between w-full my-4" key={booking._id}>
                                                <div className="flex space-x-2">
                                                    <span className={`w-5 h-5 rounded-full z-[12] bg-gray-300`}></span>
                                                    <div
                                                        className="grid cursor-pointer"
                                                        onClick={() => {
                                                            handleBookingSelected('details', booking)
                                                        }}
                                                    >
                                                        <div className="grid">
                                                            <Text.Paragraph.Normal className="text-xl font-medium truncate"></Text.Paragraph.Normal>
                                                            {booking?._prestation.map((prestation) => {
                                                                return (
                                                                    <>
                                                                        <Text.Paragraph.Normal
                                                                            className="font-bold"
                                                                            key={prestation._id}
                                                                        >
                                                                            {prestation.name} - {prestation.price} €
                                                                        </Text.Paragraph.Normal>
                                                                    </>
                                                                )
                                                            })}
                                                            <Text.Paragraph.Normal>{`${dayjs(booking.date).format(
                                                                'ddd DD MMMM'
                                                            )} à ${formatSecondsToHHhmm(
                                                                booking.from
                                                            )}`}</Text.Paragraph.Normal>
                                                            <Text.Paragraph.Normal>
                                                                {booking.note.map((comment) => {
                                                                    return (
                                                                        <span key={comment._id}>{comment.value}</span>
                                                                    )
                                                                })}
                                                            </Text.Paragraph.Normal>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={() => handleBookingSelected('note', booking)}>
                                                    <Icon.Message className="h-8 cursor-pointer" />
                                                </div>
                                            </div>
                                            {index == clientSelected?.bookings.length - 1 && (
                                                <span className="absolute left-[30px] w-[20px] h-[93%] bg-white z-[13]"></span>
                                            )}
                                        </>
                                    )
                                } else {
                                    return ''
                                }
                            })}
                            <div className="absolute w-1 h-[83%] bg-gray-300 left-[8px] top-[13px] z-[11]"></div>
                            <div className="absolute w-1 h-[15%] bg-white left-[8px] bottom-[0px] z-[11]"></div>
                        </div>
                    </TitleSection>
                    <Modal.AddNote />
                    <Modal.Delete />
                    <Modal.DetailsBooking />
                </>
            ) : (
                <Modal.Edit />
            )}
        </div>
    )
}

const List = () => {
    const { clients, clientSelected, setClientSelected } = Hairdresser.useClients()

    return (
        <div className="grid w-full gap-5 overflow-y-auto">
            {clients.map((client) => {
                if (client._user?._profil && !client.hidde) {
                    return (
                        <div
                            key={client._id}
                            onClick={() => {
                                setClientSelected(client)
                            }}
                            className={`transition-all cursor-pointer ${
                                client?._id === clientSelected?._id ? 'bg-pink p-2 rounded' : 'bg-white'
                            }`}
                        >
                            <Card.Profil
                                key={client?._user?._id}
                                srcImage={Boy}
                                title={`${client?._user?._profil?.lastName} ${client?._user?._profil?.firstName}`}
                                subTitle={client?._user?._profil?.address?.formatted}
                                icon={<Icon.ArrowNext className="w-9" />}
                            />
                        </div>
                    )
                }
            })}
        </div>
    )
}

export { Search, List, ClientDetails }
