import React, { useState } from 'react'
import { Card } from '../../molecules'
import Boy from '../../../assets/avatar/Boy.svg'
import { Client } from '../../../contexts'

import { Button, Icon, Modal } from '../../atoms'

export const Main = () => {
    const { favorites, handleSaveAttribute, getProfil } = Client.useProfil()
    const { selectCoiffeur } = Client.useCoiffeur()

    const [deleteCoiffeur, setDeleteCoiffeur] = useState()

    return (
        <div className="flex flex-col space-y-6 h-80">
            {favorites?.map((favorite) => (
                <Card.Favorite
                    key={favorite._id}
                    srcImage={`${favorite.avatar ? favorite.avatar?.thumbnailUrl : Boy}`}
                    alt={`Logo iléa de : ${favorite.firstName} ${favorite.lastName}`}
                    title={`${favorite.firstName} ${favorite.lastName}`}
                    handleDelete={() => {
                        setDeleteCoiffeur({ ...favorite, _id: favorite._owner._id })
                    }}
                    handleSelect={() => {
                        selectCoiffeur({
                            _id: favorite._owner._id,
                            siret: favorite.siret,
                            email: favorite._owner.email,
                            _profil: { ...favorite },
                        })
                    }}
                />
            ))}
            <Modal.default
                isOpen={deleteCoiffeur}
                title="Supprimer des favoris"
                setIsOpen={() => setDeleteCoiffeur()}
                backButton={
                    <Button.Secondary
                        onClick={() => {
                            setDeleteCoiffeur()
                        }}
                        className="px-8"
                    >
                        Annuler
                    </Button.Secondary>
                }
                confirmButton={
                    <Button.Red
                        iconRight={<Icon.ArrowNext className="w-5" />}
                        onClick={async () => {
                            const newFavorites = favorites.map((coiffeur) => {
                                return coiffeur._owner._id
                            })

                            await handleSaveAttribute(
                                'favorites',
                                newFavorites.filter((coiffeur) => coiffeur !== deleteCoiffeur._owner._id)
                            )

                            await getProfil()
                            setDeleteCoiffeur()
                        }}
                    >
                        Confirmer
                    </Button.Red>
                }
            ></Modal.default>
        </div>
    )
}
