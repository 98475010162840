/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'
import { useProfil } from '../profil'

import { getSessionStorageValue, setSessionStorageValue } from '../../storage'

const BasketContext = createContext({
    prestations: Array,
    prestationSelected: Object,
    activeView: String,
    algorithm: Boolean,
    loading: Boolean,
    results: Array,
    step: String,
    setActiveView: (value) => {},
    setPrestationSelected: (value) => {},
    changePrestation: (index, quantity) => {},
    getAlgorithmResult: () => {},
    setAlgorithm: (value) => {},
    bookgSchedule: (date, event) => {},
    setStep: (value) => {},
})

export function BasketContextProvider({ children }) {
    const { handleRequest } = useRequest()
    // const { token, id } = useAuth()
    // const { address } = useProfil()

    const [prestations, setPrestations] = useState([])
    const [prestationSelected, setPrestationSelected] = useState(null)
    const [activeView, setActiveView] = useState('femme')

    const [algorithm, setAlgorithm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState([])
    const [step, setStep] = useState('general')

    const [showMenu, setShowMenu] = useState(false)

    const changePrestation = (index, quantity) => {
        const newPrestations = [...prestations]
        const newPrestationSelected = { ...prestationSelected, quantity: quantity }
        newPrestations[index] = newPrestationSelected

        setPrestationSelected(newPrestationSelected)
        setPrestations(newPrestations)
    }

    const getAlgorithmResult = async () => {
        setAlgorithm(true)
        setLoading(true)
        const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

        let duration = 0
        let price = 0
        prestationsSelected.forEach((prestation) => {
            duration += prestation.duration / 1000
            price += parseInt(prestation.price)
        })

        // const response = await handleRequest(
        //     'post',
        //     `client/algorithme`,
        //     {
        //         coiffeurId: coiffeurSelected._id,
        //         clientLocation: [address.lon, address.lat],
        //         duration: duration,
        //     },
        //     token,
        //     null,
        //     true
        // )
        setLoading(false)
    }

    const bookgSchedule = async (date, event) => {
        const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

        let duration = 0
        let price = 0
        prestationsSelected.forEach((prestation) => {
            duration += prestation.duration / 1000
            price += parseInt(prestation.price)
        })

        const prestationsArray = []
        prestationsSelected.map((prestation) => {
            for (let i = 0; i < prestation.quantity; i++) {
                prestationsArray.push(prestation._id)
            }
        })

        // const response = await handleRequest(
        //     'post',
        //     `client/bookings/${coiffeurSelected._id}`,
        //     {
        //         date: date,
        //         prestations: prestationsArray,
        //         start: event.start,
        //         end: event.end,
        //         client: id,
        //         price: price,
        //         duration: duration,
        //         address: address,
        //     },
        //     token
        // )
    }

    return (
        <BasketContext.Provider
            value={{
                prestations: prestations,
                prestationSelected: prestationSelected,
                activeView: activeView,
                algorithm: algorithm,
                loading: loading,
                results: results,
                step: step,
                showMenu: showMenu,
                setActiveView: setActiveView,
                setPrestationSelected: setPrestationSelected,
                changePrestation: changePrestation,
                getAlgorithmResult: getAlgorithmResult,
                setAlgorithm: setAlgorithm,
                bookgSchedule: bookgSchedule,
                setStep: setStep,
                setShowMenu: setShowMenu,
            }}
        >
            {children}
        </BasketContext.Provider>
    )
}

export const useBasket = () => useContext(BasketContext)
