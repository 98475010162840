import React from 'react'
import { Button, Text } from '../../atoms'
import { Link } from '@nextui-org/react'

export const Benefits = () => {
    return (
        <div className="grid gap-4">
            <Text.h2 className="text-center">Tu es un professionel de la beauté ?</Text.h2>
            <Text.Paragraph.Normal className="text-center">
                Ne gaspille plus ton temps !<br />
                Optimise tes déplacements pour gagner du temps et offrir un meilleur service à tes clients.
            </Text.Paragraph.Normal>
            <div className="mx-auto">
                <Link href="/signup">
                    <Button.Primary>Inscrivez-vous</Button.Primary>
                </Link>
            </div>
        </div>
    )
}
