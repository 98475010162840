import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Navbar } from '../molecules'
import { Auth, Request } from '../../contexts'
import { Message } from '../atoms'

const UnLogged = () => {
    const { message } = Request.useRequest()
    const { isLogged, type } = Auth.useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (isLogged()) {
            if (type === 'coiffeur') {
                navigate('/hairdresser')
            } else if (type === 'client') {
                navigate('/client')
            }
        }
    }, [isLogged, type])

    return (
        <div className="container relative text-black">
            <Navbar.UnLogged />
            <div className="px-2 py-4 overflow-x-hidden md:px-6 z-[99] h-unlogged overflow-y-auto">
                {message.message && (
                    <>
                        {message.type === 'info' && <Message.Info message={message.message} />}
                        {message.type === 'warning' && <Message.Warning message={message.message} />}
                        {message.type === 'error' && <Message.Error message={message.message} />}
                        {message.type === 'success' && <Message.Success message={message.message} />}
                    </>
                )}
                <Outlet />
            </div>
        </div>
    )
}

const UnLoggedBooking = () => {
    const { message } = Request.useRequest()
    const navigate = useNavigate()

    useEffect(() => {
        const location = window.location.pathname
        if (!location.endsWith('details')) {
            navigate(`${location}/details`)
        }
    }, [])

    return (
        <div className="container relative text-black">
            <Navbar.UnLogged />
            <div className="px-2 py-4 overflow-x-hidden md:px-6 z-[99] h-unlogged overflow-y-auto">
                {message.message && (
                    <>
                        {message.type === 'info' && <Message.Info message={message.message} />}
                        {message.type === 'warning' && <Message.Warning message={message.message} />}
                        {message.type === 'error' && <Message.Error message={message.message} />}
                        {message.type === 'success' && <Message.Success message={message.message} />}
                    </>
                )}
                <Outlet />
            </div>
        </div>
    )
}

const Client = () => {
    const { id, isLogged, type, restored } = Auth.useAuth()
    const { message } = Request.useRequest()
    const navigate = useNavigate()

    useEffect(() => {
        if (isLogged()) {
            if (type === 'coiffeur') {
                navigate('/hairdresser')
            } else {
                if (window.location.pathname.includes('hairdresser')) {
                    return
                } else {
                    navigate('/client/favorites')
                }
            }
        } else {
            if (restored) {
                navigate('/login')
            }
        }
    }, [id, restored])

    return (
        <div className="container relative text-black">
            <Navbar.LoggedTop />
            <div className="px-2 py-4 overflow-x-hidden overflow-y-auto md:px-6 h-logged">
                <div className="grid gap-4">
                    {message.message && (
                        <>
                            {message.type === 'info' && <Message.Info message={message.message} />}
                            {message.type === 'warning' && <Message.Warning message={message.message} />}
                            {message.type === 'error' && <Message.Error message={message.message} />}
                            {message.type === 'success' && <Message.Success message={message.message} />}
                        </>
                    )}
                    <Outlet />
                </div>
            </div>
            <Navbar.Client />
        </div>
    )
}

const Coiffeur = () => {
    const { id, isLogged, type, restored } = Auth.useAuth()
    const { message } = Request.useRequest()
    const navigate = useNavigate()

    useEffect(() => {
        if (isLogged()) {
            if (type === 'client') {
                navigate('/client')
            } else {
                return
            }
        } else {
            if (restored) {
                navigate('/login')
            }
        }
    }, [id, restored])

    return (
        <div className="container relative text-black">
            <Navbar.LoggedTop />
            <div className="px-2 py-4 overflow-x-hidden overflow-y-auto md:px-6 h-logged">
                <div className="grid gap-4">
                    {message.message && (
                        <>
                            {message.type === 'info' && <Message.Info message={message.message} />}
                            {message.type === 'warning' && <Message.Warning message={message.message} />}
                            {message.type === 'error' && <Message.Error message={message.message} />}
                            {message.type === 'success' && <Message.Success message={message.message} />}
                        </>
                    )}
                    <Outlet />
                </div>
            </div>
            <div className="fixed bottom-0 w-full">
                <Navbar.Coiffeur />
            </div>
        </div>
    )
}

export { UnLogged, UnLoggedBooking, Client, Coiffeur }
