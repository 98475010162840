import React from "react"
import { Card, TitleSection } from "../../molecules"
import { Button, Icon, Input, Text } from "../../atoms"
import Girl from '../../../assets/avatar/Girl.svg'

export const Main = () => {
    return(
        <>
            <Card.ProfilStars
                srcImage={`${Girl}`}
                alt={`Avatar Girl`}
                title={`Monique Chazal`}
                note={`5`}
                profession={`Coiffeur(se)`}
                classNameDiv={`flex-wrap`}
            />
            <TitleSection textTitle={`Prestations`}>
                <Card.Profil
                    srcImage={`${Image}`}
                    alt={`Image prestation`}
                    title={`Coupe homme courte`}
                    subTitle={`20€  - 25min`}
                />
            </TitleSection>
            <TitleSection textTitle={`Total`}>
                <div className="grid w-full grid-cols-2">
                    <div className="flex items-center gap-x-2">
                        <Icon.Coins className="h-8" />
                        <Text.Paragraph.Normal>20€</Text.Paragraph.Normal>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <Icon.Clock className="h-8" />
                        <Text.Paragraph.Normal>25min</Text.Paragraph.Normal>
                    </div>
                </div>
            </TitleSection>
            <TitleSection textTitle={`Date et heure`}>
                <div className="flex items-center gap-6">
                    <Button.Secondary iconRight={<Icon.Calendar className={`h-6`} />}>23 Déc.</Button.Secondary>
                    <Button.Secondary iconRight={<Icon.Clock className={`h-6 `} />}>15h30.</Button.Secondary>
                </div>
            </TitleSection>
            <TitleSection textTitle={`Adresse`}>
                <div className="flex items-center">
                    <Icon.LocationPin className="h-8 mr-4" />
                    <Text.Link
                        href={`https://www.google.com/maps/place/12+All.+Forain-Fran%C3%A7ois+Verdier,+31000+Toulouse/@43.5977913,1.4502774,17z/data=!3m1!4b1!4m6!3m5!1s0x12aebc856340678d:0xcc3f636953a3e1db!8m2!3d43.5977874!4d1.4528523!16s%2Fg%2F11c0ygzwd1?entry=ttu`}
                        className={`no-underline`}
                    >
                        12 boulevard François Verdier <br />
                        31000 Toulouse
                    </Text.Link>
                </div>
            </TitleSection>
            <TitleSection textTitle={`Commentaire`}>
                <Input.Text.Classic placeholder={`Commentaire pour le/la coiffeur(se)`} />
            </TitleSection>
        </>
    )
}