/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'
import { useProfil } from '../profil/context'
import { getSessionStorageValue, setSessionStorageValue } from '../../storage'
import { useNavigate } from 'react-router-dom'
import useHistoryTracking from '../../../routes'

const CoiffeurContext = createContext({
    loading: Boolean,
    address: Object,
    coiffeurs: Array,
    favorites: Array,
    coiffeurSelected: Object,
    categories: Array,
    setCategories: (value) => {},
    getCoiffeurs: async () => {},
    selectCoiffeur: (coiffeur) => {},
    isFavorite: () => {},
    getCoiffeurById: async (id) => {},
    setCoiffeurSelected: (coiffeur) => {},
})

export function CoiffeurContextProvider({ children }) {
    const { getHistory } = useHistoryTracking()

    const navigate = useNavigate()
    const { handleRequest } = useRequest()
    const { id, token, isLogged } = useAuth()
    const { address, favorites } = useProfil()

    const [loading, setLoading] = useState(true)
    const [localAddress, setLocalAddress] = useState(address)
    const [coiffeurs, setCoiffeurs] = useState([])
    const [coiffeurSelected, setCoiffeurSelected] = useState()
    const [categories, setCategories] = useState(['coiffure', 'esthetique'])

    const getCoiffeurs = async (address) => {
        setLoading(true)
        setLocalAddress(address)

        const response = await handleRequest(
            'put',
            `profil/coiffeurs/${id}${categories.length === 1 ? `?category=${categories[0]}` : ''}`,
            { address: address },
            token
        )

        if (response?.data) {
            let coiffeursRegion = response.data.profils.filter((profil) => profil.isInTheZone)

            setCoiffeurs(coiffeursRegion)
            setSessionStorageValue('coiffeurs', JSON.stringify(coiffeursRegion))
            setSessionStorageValue('address', JSON.stringify(address))
            localStorage.setItem('address', JSON.stringify(address))
            setLoading(false)
        }
    }
    const getCoiffeurById = async (id) => {
        const response = await handleRequest('get', `profil/unlogged/coiffeur/${id}`)

        if (response?.data) {
            setCoiffeurSelected(response.data.coiffeur)
            setSessionStorageValue('coiffeurSelected', JSON.stringify(response.data.coiffeur))
        }
    }

    useEffect(() => {
        if (address) {
            if (localAddress && localAddress?.formatted !== '') {
                if (!localAddress) {
                    getCoiffeurs(address)
                } else {
                    getCoiffeurs(localAddress)
                }
            } else {
                console.log(localAddress, !localAddress)
                if (!localAddress) {
                    setLocalAddress(address)
                    getCoiffeurs(address)
                }
            }
        }
    }, [address])

    useEffect(() => {
        if (localAddress?.formatted !== '') {
            getCoiffeurs(localAddress)
        }
    }, [categories])

    const selectCoiffeur = (coiffeur) => {
        const history = getHistory()

        if (coiffeur) {
            if (isLogged()) {
                navigate(`client/hairdresser/${coiffeur._id}/details`)
            } else {
                navigate(`hairdresser/${coiffeur._id}/details`)
            }
        } else {
            if (history.length > 1) {
                if (
                    history[history.length - 2].indexOf('services') !== -1 ||
                    history[history.length - 2].indexOf('details') !== -1
                ) {
                    navigate(history[history.length - 4])
                } else {
                    navigate(-1)
                }
            } else {
                if (isLogged()) {
                    navigate('/client')
                } else {
                    navigate('/')
                }
            }
        }
        setCoiffeurSelected(coiffeur)

        setSessionStorageValue('coiffeurSelected', JSON.stringify(coiffeur))
    }

    useEffect(() => {
        const addressStored = localStorage.getItem('address')
        const coiffeursStored = getSessionStorageValue('coiffeurs')
        const coiffeurSelectedStored = getSessionStorageValue('coiffeurSelected')

        console.log(addressStored)
        if (addressStored) setLocalAddress(JSON.parse(addressStored))
        if (coiffeursStored) setCoiffeurs(JSON.parse(coiffeursStored))
        if (coiffeurSelectedStored) setCoiffeurSelected(JSON.parse(coiffeurSelectedStored))
    }, [])

    const isFavorite = (coiffeur) => {
        let returnValue = false

        favorites?.map((favorite) => {
            if (favorite._owner?._id == coiffeur._id) {
                returnValue = true
            }
        })

        return returnValue
    }

    return (
        <CoiffeurContext.Provider
            value={{
                loading: loading,
                address: localAddress,
                coiffeurs: coiffeurs,
                favorites: favorites,
                coiffeurSelected: coiffeurSelected,
                categories: categories,
                setCategories: setCategories,
                getCoiffeurs: getCoiffeurs,
                selectCoiffeur: selectCoiffeur,
                isFavorite: isFavorite,
                getCoiffeurById: getCoiffeurById,
                setCoiffeurSelected: setCoiffeurSelected,
            }}
        >
            {children}
        </CoiffeurContext.Provider>
    )
}

export const useCoiffeur = () => useContext(CoiffeurContext)
