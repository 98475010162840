import React from 'react'

import { HomeOrganism } from '../../components/organisms'

const Home = () => {
    return (
        <div className="grid gap-20 mb-6">
            <HomeOrganism.Research />
            <HomeOrganism.Benefits />
            <HomeOrganism.Inscription />
        </div>
    )
}

export default Home
