import React from 'react'
import { Text, Icon } from '../atoms'

export const Return = ({ children, className, onClick }) => {
    return (
        <div className={`flex justify-between ${className ? className : ''}`}>
            <div
                onClick={() => {
                    if (onClick) {
                        onClick()
                    }
                }}
            >
                <Text.Link className="flex items-center no-underline gap-x-4">
                    <Icon.ArrowBack className="h-4" /> Retour
                </Text.Link>
            </div>
            {children}
        </div>
    )
}
