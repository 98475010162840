import React from 'react'
import { Icon, Text } from '../../atoms'
import { TitleSection } from '../../molecules'
import { Client } from '../../../contexts'

export const Contact = () => {
    const { coiffeurSelected } = Client.useCoiffeur()
    return (
        <>
            <TitleSection textTitle={'Coordonnées'}>
                <address className="grid w-full gap-2">
                    <div className="flex items-center gap-x-2">
                        <Icon.Email className="h-6" />
                        <Text.Link href={`mailto:${coiffeurSelected?.email}`} className="not-italic no-underline">
                            {coiffeurSelected?.email}
                        </Text.Link>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <Icon.Phone className="h-6" />
                        <Text.Link
                            href={`tel:${
                                coiffeurSelected?._profil?.phoneNumber?.phone.length === 10
                                    ? coiffeurSelected?._profil?.phoneNumber?.phone
                                    : `0${coiffeurSelected?._profil?.phoneNumber?.phone}`
                            }`}
                            className="not-italic no-underline"
                        >
                            {coiffeurSelected?._profil?.phoneNumber?.phone.length === 9
                                ? ('0' + coiffeurSelected?._profil?.phoneNumber?.phone).replace(/(.{2})/g, '$1 ')
                                : coiffeurSelected?._profil?.phoneNumber?.phone.replace(/(.{2})/g, '$1 ')}
                        </Text.Link>
                    </div>
                </address>
            </TitleSection>
        </>
    )
}
