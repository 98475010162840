import React, { useState } from 'react'
import { Button, Icon, Modal, Text } from '../../atoms'
import { Auth } from '../../../contexts'

export const Logout = () => {
    const { handleLogout, handleDelete } = Auth.useAuth()

    const [deleteAccount, setDeleteAccount] = useState(false)

    return (
        <>
            <div className="flex flex-col items-center mt-10 gap-y-6">
                <Button.Primary
                    iconLeft={<Icon.Logout />}
                    onClick={() => {
                        handleLogout()
                    }}
                >
                    Déconnexion
                </Button.Primary>
                <div className="mt-10" onClick={() => setDeleteAccount(true)}>
                    Supprimer le compte
                </div>
            </div>
            <Modal.default
                title="Veux-tu vraiment supprimer ton compte ?"
                isOpen={deleteAccount}
                setIsOpen={() => setDeleteAccount(false)}
                confirmButton={<Button.Red onClick={() => handleDelete()}>Supprimer</Button.Red>}
                backButton={
                    <div className="mr-2">
                        <Button.Secondary onClick={() => setDeleteAccount(false)}>Annuler</Button.Secondary>
                    </div>
                }
            >
                <Text.Paragraph.Normal>
                    Cette action est irréversible et toutes les données seront supprimées définitivement, nous ne
                    pourrons pas les restaurer.
                </Text.Paragraph.Normal>
            </Modal.default>
        </>
    )
}
