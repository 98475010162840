import React from 'react'
import { frFR } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'

import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

const Day = ({ labelValue, value, setValue }) => {
    return (
        <LocalizationProvider localeText={frFR} adapterLocale="fr" dateAdapter={AdapterDayjs}>
            <DatePicker label={labelValue} value={value} onChange={(newValue) => setValue(newValue)} />
        </LocalizationProvider>
    )
}

const Time = ({ labelValue, value, onSave }) => {
    return (
        <LocalizationProvider localeText={frFR} dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
                ampm={false}
                label={labelValue}
                value={value}
                onChange={(newValue) => {
                    onSave(newValue)
                }}
            />
        </LocalizationProvider>
    )
}

export { Day, Time }
