import React from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { frFR } from '@mui/x-date-pickers'
import 'dayjs/locale/fr'

const DateProvider = ({ children }) => {
    return (
        <LocalizationProvider
            localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
            dateAdapter={AdapterDayjs}
            adapterLocale={'fr'}
        >
            {children}
        </LocalizationProvider>
    )
}

export default DateProvider
