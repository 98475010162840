import React, { useEffect, useState } from 'react'
import { Profil, Hours, Contact, Specialties } from '../../../components/organisms/HairdresserDetails'
import { Client } from '../../../contexts'
import { useParams } from 'react-router-dom'
import { Text } from '../../../components/atoms'

const Details = () => {
    const { userId } = useParams()

    const { coiffeurSelected, coiffeurs, getCoiffeurById, selectCoiffeur } = Client.useCoiffeur()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let newPrestations = []
        if (coiffeurSelected) {
            const params = new URL(document.location).searchParams
            const prestationParam = params.get('prestation')
            const prestationArray = prestationParam ? prestationParam.split(',') : []

            newPrestations = coiffeurSelected._profil._prestations.map((prestation) => {
                return { ...prestation, quantity: 0 }
            })
            prestationArray.forEach((prestationId) => {
                coiffeurSelected._profil._prestations.forEach((prestation, index) => {
                    if (prestation._id === prestationId) {
                        newPrestations[index] = { ...prestation, quantity: newPrestations[index].quantity + 1 }
                    }
                })
            })
        }
    }, [coiffeurSelected])

    useEffect(() => {
        if (!coiffeurSelected) {
            setLoading(true)
        }
        getCoiffeurById(userId)
    }, [])

    useEffect(() => {
        if (coiffeurs) {
            const coiffeurLoaded = coiffeurs?.filter((coiffeur) => coiffeur._id == userId)

            if (coiffeurLoaded.length > 0) {
                selectCoiffeur(coiffeurLoaded[0])
            }
        }
    }, [coiffeurs])

    useEffect(() => {
        if (coiffeurSelected) setLoading(false)
    }, [coiffeurSelected])

    return (
        <div>
            {loading ? (
                <div></div>
            ) : (
                <>
                    <Profil />
                    <Hours />
                    <Contact />
                    <Specialties />
                    <div className="grid gap-5 mt-10">
                        <Text.Paragraph.Normal className="text-lg font-medium">Galerie photos</Text.Paragraph.Normal>
                        <div className="grid grid-cols-2 gap-2">
                            {coiffeurSelected?._profil?._photos?.map((photo) => {
                                return (
                                    <>
                                        <div
                                            key={photo._id}
                                            className="relative rounded-lg w-[40vw] h-[40vw] bg-light-gray border border-black border-opacity-10"
                                            style={{
                                                backgroundImage: `url(${photo.thumbnailUrl})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center center',
                                                backgroundSize: 'cover',
                                            }}
                                        ></div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Details
