import React from 'react'

import { Hairdresser } from '../../../molecules'
// import { useAuth } from '../../../../contexts/auth'

const Accueil = ({ goToSettings }) => {
    // const { createAccount } = useAuth()

    return (
        <div className="grid gap-8">
            <Hairdresser.Profil.Card goToSettings={goToSettings} />
            <Hairdresser.Profil.CopyLink />
            <Hairdresser.Profil.Contact />
            <Hairdresser.Profil.SocialAndYears />
            <Hairdresser.Profil.Biography />
            <Hairdresser.Profil.Category />
            <Hairdresser.Profil.Speciality />
            <Hairdresser.Profil.Galery />
            {/* <div onClick={() => createAccount()}>
                <h1 className="text-4xl text-red-500">test account controller</h1>
            </div> */}
        </div>
    )
}

export default Accueil
