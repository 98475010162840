import React, { useEffect, useState } from 'react'
import { Button, Icon } from '../../atoms'
import { Card, Return } from '../../molecules'
import Boy from '../../../assets/avatar/Boy.svg'
import { Client, Hairdresser } from '../../../contexts'
import { useNavigate } from 'react-router-dom'

export const Profil = () => {
    const navigate = useNavigate()

    const { favorites, handleSaveAttribute, getProfil } = Client.useProfil()
    const { facebook, instagram, linkedin, pinterest, google } = Hairdresser.useProfil()
    const { coiffeurSelected, selectCoiffeur, isFavorite } = Client.useCoiffeur()

    const [coiffeurInFavorite, setCoiffeurInFavorite] = useState(false)

    useEffect(() => {
        if (coiffeurSelected) {
            setCoiffeurInFavorite(isFavorite(coiffeurSelected))
        }
    }, [coiffeurSelected, favorites])

    const handleFavorites = async () => {
        if (coiffeurInFavorite) {
            const newFavorites = favorites.map((coiffeur) => {
                return coiffeur._owner._id
            })

            await handleSaveAttribute(
                'favorites',
                newFavorites.filter((coiffeur) => coiffeur !== coiffeurSelected._id)
            )
        } else {
            const newFavorites = favorites.map((coiffeur) => coiffeur._owner._id)
            newFavorites.push(coiffeurSelected._id)
            await handleSaveAttribute('favorites', newFavorites)
        }

        await getProfil()
    }

    return (
        <div className="relative">
            <Return
                className="mb-6 font-semibold"
                onClick={() => {
                    selectCoiffeur(null)
                    navigate(`/client`)
                }}
            ></Return>
            {coiffeurInFavorite ? (
                <div className="absolute w-10 h-10 top-7 right-2" onClick={() => handleFavorites()}>
                    <Icon.Heart className={`text-favorite`} />
                </div>
            ) : (
                <div className="absolute w-10 h-10 top-7 right-2" onClick={() => handleFavorites()}>
                    <Icon.HeartBorder className={`text-favorite`} />
                </div>
            )}
            <Card.HairdresserPresentation
                srcImage={`${
                    coiffeurSelected?._profil?.avatar?.thumbnailUrl
                        ? coiffeurSelected?._profil?.avatar?.thumbnailUrl
                        : Boy
                }`}
                alt={`Avatar professionnel de beauté Iléa`}
                title={`${coiffeurSelected?._profil.firstName} ${coiffeurSelected?._profil.lastName}`}
                xp={`${coiffeurSelected?._profil.yearsExperience} années d'expériences`}
                note={`5`}
                facebook={facebook ? facebook : ''}
                instagram={instagram ? instagram : ''}
                linkedin={linkedin ? linkedin : ''}
                pinterest={pinterest ? pinterest : ''}
                google={google ? google : ''}
            />
            <div className="flex justify-center gap-x-2">
                <Button.Secondary
                    onClick={() => {
                        navigate(`/client/hairdresser/${coiffeurSelected._id}/services`)
                    }}
                >
                    Prestations
                </Button.Secondary>
                <Button.Primary
                    iconRight={<Icon.ArrowNext />}
                    onClick={() => {
                        navigate(`/client/hairdresser/${coiffeurSelected._id}/services`)
                    }}
                >
                    Prendre RDV
                </Button.Primary>
            </div>
        </div>
    )
}
