import React, { useState, useEffect } from 'react'
import { Input, Modal, Button, Icon } from '../../../atoms'
import { Hairdresser } from '../../../../contexts'
import { useRequest } from '../../../../contexts/request'
import dayjs from 'dayjs'
import { useAuth } from '../../../../contexts/auth'

const WorkHours = () => {
    const { date, data, setChangeDateSchedules, changeDateSchedules, handleDate, updateLoadedDay } =
        Hairdresser.useAgenda()
    const { handleRequest } = useRequest()
    const { id, token } = useAuth()
    const { createPrivate } = Hairdresser.useBooking()

    const [blocs, setBlocs] = useState([
        {
            start: 9 * 3600,
            end: 18 * 3600,
        },
    ])
    const [checked, setChecked] = useState(false)

    const handleCreateBreak = async (blocs) => {
        updateLoadedDay(date, {
            ...data.loadedDays[date],
            startOfDay: blocs[0]?.start,
            endOfDay: blocs[blocs.length - 1]?.end,
            isWorkingDay: !checked,
        })

        await handleRequest(
            'put',
            `coiffeur/days/${id}/${date}`,
            { startOfDay: blocs[0]?.start, endOfDay: blocs[blocs.length - 1]?.end, isWorkingDay: !checked },
            token
        )

        const breakblocs = []
        if (blocs.length > 1) {
            for (let i = 0; i < blocs.length; i++) {
                if (i + 1 < blocs.length) {
                    const breakStart = blocs[i].end
                    const breakEnd = blocs[i + 1].start
                    breakblocs.push({ start: breakStart, end: breakEnd })
                }
            }
        }
        if (breakblocs.length > 0) {
            const toCancel = data.loadedDays[date]?.bookings?.filter(
                (booking) => booking.isPrivate && booking.state !== 'cancel'
            )
            for await (let booking of toCancel) {
                await handleRequest(
                    'put',
                    `coiffeur/bookings/${id}/${booking._id}`,
                    { state: 'cancel', reason: '', date: date },
                    token
                )
            }

            breakblocs.map((bloc) => {
                createPrivate(date, bloc.start, bloc.end, date, false)
            })
        }

        handleDate(date)
    }

    useEffect(() => {
        const privates = data.loadedDays[date]?.bookings?.filter(
            (booking) => booking.isPrivate && booking.state !== 'cancel'
        )

        let uniquePrivates = new Set()

        let privatesUnique = privates?.filter((item) => {
            // Check if name is already in the Set
            if (uniquePrivates.has(item.start)) {
                return false // If name is already there, filter out this item
            } else {
                uniquePrivates.add(item.start) // Add the new name to the Set
                return true // Keep this item as its name is unique so far
            }
        })

        const horaires = []
        if (privatesUnique?.length > 0) {
            privatesUnique.map((indispo, index) => {
                horaires.push({
                    start: index === 0 ? data.loadedDays[date]?.startOfDay : privatesUnique[index - 1].to,
                    end: indispo.from,
                })
                horaires.push({
                    start: indispo.to,
                    end:
                        index === privatesUnique.length - 1
                            ? data.loadedDays[date]?.endOfDay
                            : privatesUnique[index + 1].from,
                })
            })
        } else {
            horaires.push({ start: data.loadedDays[date]?.startOfDay, end: data.loadedDays[date]?.endOfDay })
        }

        setBlocs(horaires)
        setChecked(!data.loadedDays[date]?.isWorkingDay)
    }, [date])

    return (
        <Modal.default
            title={dayjs(date).format('ddd DD MMMM')}
            isOpen={changeDateSchedules}
            setIsOpen={() => {}}
            confirmButton={
                <Button.Primary
                    onClick={async () => {
                        await handleCreateBreak(blocs)
                        setChangeDateSchedules(null)
                    }}
                    iconRight={<Icon.ArrowNext className="w-5" />}
                >
                    Enregistrer
                </Button.Primary>
            }
            backButton={<Button.Secondary onClick={() => setChangeDateSchedules(null)}>Retour</Button.Secondary>}
        >
            <div className="min-h-[30vh] py-5">
                <div className="flex items-center gap-4">
                    <Input.Checkbox
                        initialValue={checked}
                        setValue={(e) => {
                            setChecked(e)
                            data.loadedDays[date].isWorkingDay = checked
                        }}
                    />
                    <label>Jour off</label>
                </div>
                <div className="flex items-center">
                    {!data.loadedDays[date]?.isWorkingDay || checked ? (
                        <div className="z-10 flex items-center w-full space-x-4 opacity-50 cursor-not-allowed">
                            <Input.Text.Classic readonly="readonly" type="time" value={'-'} setValue={() => {}} />
                            <span> à </span>
                            <Input.Text.Classic
                                className="z-0"
                                readonly="readonly"
                                type="time"
                                value={'-'}
                                setValue={() => {}}
                            />
                        </div>
                    ) : (
                        <div className="grid w-full">
                            {blocs.map((bloc, index) => {
                                return (
                                    <>
                                        <div className="flex items-center justify-between w-full space-x-1">
                                            <Input.Date.Time
                                                labelValue={'Durée'}
                                                value={dayjs()
                                                    .set('hours', blocs[index].start / 3600)
                                                    .set('minute', (blocs[index].start / 60) % 60)}
                                                onChange={(value) => console.log(value)}
                                                onSave={(value) => {
                                                    const date = dayjs(value)
                                                    const newBlocs = [...blocs]
                                                    const newStart = date.get('hours') * 3600 + date.get('minutes') * 60

                                                    if (index > 0) {
                                                        newBlocs[index].start =
                                                            newStart >= newBlocs[index - 1].end
                                                                ? newStart
                                                                : newBlocs[index - 1].end + 3600
                                                    } else {
                                                        newBlocs[index].start = newStart
                                                    }
                                                    setBlocs(newBlocs)
                                                }}
                                            />
                                            <span> à </span>
                                            <Input.Date.Time
                                                labelValue={'Durée'}
                                                value={dayjs()
                                                    .set('hours', blocs[index].end / 3600)
                                                    .set('minute', (blocs[index].end / 60) % 60)}
                                                onChange={(value) => console.log(value)}
                                                onSave={(value) => {
                                                    const date = dayjs(value)
                                                    const newBlocs = [...blocs]
                                                    const newEnd = date.get('hours') * 3600 + date.get('minutes') * 60

                                                    newBlocs[index].end =
                                                        newEnd >= newBlocs[index].start
                                                            ? newEnd
                                                            : newBlocs[index].start + 3600

                                                    setBlocs(newBlocs)
                                                }}
                                            />
                                            <div className="flex items-center gap-4 w-fit">
                                                {blocs.length - 1 === index ? (
                                                    <div className="flex w-16 ">
                                                        {blocs.length > 1 && (
                                                            <div
                                                                onClick={() => {
                                                                    const newblocs = [...blocs]
                                                                    newblocs.splice(index, 1)
                                                                    setBlocs(newblocs)
                                                                }}
                                                            >
                                                                <Icon.Less className="h-6" />
                                                            </div>
                                                        )}

                                                        <div
                                                            onClick={() => {
                                                                const newblocs = [...blocs]
                                                                newblocs.push({
                                                                    start: blocs[index].end + 3600,
                                                                    end: blocs[index].end + 3600 * 2,
                                                                })
                                                                setBlocs(newblocs)
                                                            }}
                                                        >
                                                            <Icon.Add className="h-6" />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div
                                                            onClick={() => {
                                                                const newblocs = [...blocs]
                                                                newblocs.splice(index, 1)
                                                                setBlocs(newblocs)
                                                            }}
                                                            className={`w-16`}
                                                        >
                                                            <Icon.Less className="h-6" />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </Modal.default>
    )
}

export default WorkHours
