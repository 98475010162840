import React, { useEffect, useState } from 'react'

import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    Link,
    NavbarMenuToggle,
    NavbarMenu,
    NavbarMenuItem,
    NavbarItem,
} from '@nextui-org/react'

import { Icon, Button, Text } from '../atoms'
import { useLocation, useNavigate } from 'react-router-dom'
import { Auth, Hairdresser } from '../../contexts'

const LogoIlea = require('../../assets/Logo/png128.png')

export const UnLogged = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const location = useLocation()

    useEffect(() => {
        setIsMenuOpen(false)
    }, [location])

    return (
        <Navbar isMenuOpen={isMenuOpen} onMenuOpenChange={(value) => setIsMenuOpen(value)} className="bg-white">
            <NavbarContent>
                <NavbarMenuToggle
                    className="!border-none"
                    icon={isMenuOpen ? <Icon.Close className="w-10" /> : <Icon.Burger className="w-10" />}
                    aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                />
                <NavbarBrand>
                    <img className="w-16 mx-auto" src={LogoIlea} alt="Logo Iléa" />
                </NavbarBrand>
                <NavbarItem>
                    {location.pathname.indexOf('login') === -1 && location.pathname.indexOf('signup') === -1 ? (
                        <Link href="/login">
                            <Icon.User className="w-10" />
                        </Link>
                    ) : (
                        <Link href="/">
                            <Icon.Home className="w-10" />
                        </Link>
                    )}
                </NavbarItem>
            </NavbarContent>
            <NavbarMenu className="flex flex-col py-3 space-y-4 bg-white">
                <div className='pt-[var(--safe-area-top)]'></div>
                <NavbarMenuItem>
                    <Link href="/signup">
                        <Text.Clickable>Inscription</Text.Clickable>
                    </Link>
                </NavbarMenuItem>
                <NavbarMenuItem>
                    <Link href="/login">
                        <Text.Clickable>Connexion</Text.Clickable>
                    </Link>
                </NavbarMenuItem>
                <NavbarMenuItem className="grid items-end justify-center gap-2 mt-auto text-center">
                    <Text.Paragraph.Normal>Vous êtes coiffeur ?</Text.Paragraph.Normal>
                    <Link href="/signup">
                        <Button.Primary>Inscrivez-vous ici</Button.Primary>
                    </Link>
                </NavbarMenuItem>
            </NavbarMenu>
        </Navbar>
    )
}

export const LoggedTop = () => {
    const { type } = Auth.useAuth()
    const navigate = useNavigate()

    const location = useLocation()

    return (
        <Navbar className="bg-white">
            <NavbarContent>
                <NavbarBrand>
                    <img className="w-16 mx-auto" src={LogoIlea} alt="Logo Iléa" />
                </NavbarBrand>
                <NavbarItem className="fixed right-6">
                    {location.pathname.indexOf('profil') === -1 ? (
                        <div
                            onClick={() => {
                                navigate(`/${type === 'client' ? 'client' : 'hairdresser'}/profil`, {
                                    state: { from: location.pathname },
                                })
                            }}
                            className="relative"
                        >
                            <Icon.User className="w-10" />
                        </div>
                    ) : (
                        <div
                            onClick={() => {
                                navigate(`/${type === 'client' ? 'client' : 'hairdresser'}`, {
                                    state: { from: location.pathname },
                                })
                            }}
                            className="relative"
                        >
                            <Icon.Home className="w-10" />
                        </div>
                    )}
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    )
}

export const Client = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <Navbar
            classNames={{
                base: 'bg-white border-t-2 border-light-gray',
                content: 'p-8 w-full data-[justify=center]:justify-center',
                item: 'text-purple data-[active=true]:text-favorite',
            }}
        >
            <NavbarContent data-justify="center">
                <NavbarItem isActive={location.pathname === '/client'} state={{ from: location.pathname }}>
                    <div onClick={() => navigate('/client', { state: { from: location.pathname } })}>
                        <Icon.Search className="w-10 " />
                    </div>
                </NavbarItem>
                <NavbarItem isActive={location.pathname === '/client/favorites'} state={{ from: location.pathname }}>
                    <div onClick={() => navigate('/client/favorites', { state: { from: location.pathname } })}>
                        <Icon.Heart className="w-10" />
                    </div>
                </NavbarItem>
                <NavbarItem isActive={location.pathname === '/client/reservations'} state={{ from: location.pathname }}>
                    <div onClick={() => navigate('/client/reservations', { state: { from: location.pathname } })}>
                        <Icon.Receipt className="w-10 " />
                    </div>
                </NavbarItem>
                <NavbarItem isActive={location.pathname === '/client/profil'} state={{ from: location.pathname }}>
                    <div onClick={() => navigate('/client/profil', { state: { from: location.pathname } })}>
                        <Icon.User className="w-10 " />
                    </div>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    )
}

export const Coiffeur = () => {
    const { data } = Hairdresser.useAgenda()
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <Navbar
            classNames={{
                base: 'h-fit bg-white border-t-2 border-light-gray',
                content: 'p-8 w-full data-[justify=center]:justify-center',
                item: 'text-purple data-[active=true]:text-favorite',
            }}
        >
            <NavbarContent data-justify="center">
                <NavbarItem isActive={location.pathname === '/hairdresser'}>
                    <div onClick={() => navigate('/hairdresser', { state: { from: location.pathname } })}>
                        <Icon.Home className="w-10 " />
                    </div>
                </NavbarItem>
                <NavbarItem isActive={location.pathname === '/hairdresser/waiting'} className='relative'>
                    <div onClick={() => navigate('/hairdresser/waiting', { state: { from: location.pathname } })}>
                        {data?.waitings.length > 0 && (
                            <span
                                className={`w-[10px] h-[10px] absolute right-1 top-1 rounded-full ${
                                    data?.waitings.filter((booking) => booking.state === 'cancel').length > 0
                                        ? 'bg-red-400'
                                        : 'bg-favorite'
                                }`}
                            ></span>
                        )}
                        <Icon.WaitingHourglass className="w-10" />
                    </div>
                </NavbarItem>
                <NavbarItem isActive={location.pathname === '/hairdresser/prestations'}>
                    <div onClick={() => navigate('/hairdresser/prestations', { state: { from: location.pathname } })}>
                        <Icon.Cut className="w-10 " />
                    </div>
                </NavbarItem>
                <NavbarItem isActive={location.pathname === '/hairdresser/clients'}>
                    <div onClick={() => navigate('/hairdresser/clients', { state: { from: location.pathname } })}>
                        <Icon.Book className="w-10 " />
                    </div>
                </NavbarItem>
                <NavbarItem isActive={location.pathname === '/hairdresser/profil'}>
                    <div onClick={() => navigate('/hairdresser/profil', { state: { from: location.pathname } })}>
                        <Icon.User className="w-10 " />
                    </div>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    )
}
