/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'

const PrestationContext = createContext({
    prestations: Array,
    prestationSelected: Object,
    activeView: String,
    step: String,
    setPrestations: async () => {
        return Promise.resolve()
    },
    setPrestationSelected: (value) => {},
    setActiveView: (value) => {},
    handleSave: async (value) => {
        Promise.resolve()
    },
    handleCreate: async (forfait, name, type, price, duration, description) => {
        return Promise.resolve()
    },
    handleEdit: async (forfait, name, type, price, duration, description, photos) => {
        return Promise.resolve()
    },
    handleDelete: async () => {},
    setStep: (value) => {},
})

export function PrestationContextProvider({ children }) {
    const { handleRequest } = useRequest()
    const { id, token, isLogged } = useAuth()

    const [prestations, setPrestations] = useState([])
    const [prestationSelected, setPrestationSelected] = useState()
    const [activeView, setActiveView] = useState('femme')
    const [step, setStep] = useState()

    const fetchPrestations = async () => {
        const response = await handleRequest('get', `prestation/user/${id}`, null, token)

        if (response?.data) {
            setPrestations(response.data.prestations)
        }
    }

    const handleSave = async (value, type) => {
        let newPrestations = prestations.filter((prestation) => {
            if (prestation.type.toLowerCase().indexOf(activeView) === -1) {
                if (!prestation.forfait) return true
            }
            return false
        })

        let newForfait = prestations.filter((prestation) => {
            if (prestation.type.toLowerCase().indexOf(activeView) === -1) {
                if (prestation.forfait) return true
            }
            return false
        })

        if (type === 'forfait') {
            newForfait = [...newForfait, ...value]
        } else {
            newPrestations = [...newPrestations, ...value]
        }

        setPrestations([...newPrestations, ...newForfait])
        await handleRequest('put', `profil/prestations`, { prestations: [...newPrestations, ...newForfait] }, token)
    }

    const handleCreate = async (forfait, name, type, price, duration, description) => {
        const response = await handleRequest(
            'post',
            'prestation',
            {
                name: name,
                type: type,
                forfait: forfait,
                price: price,
                duration: duration,
                description: description,
            },
            token
        )

        if (response?.data) {
            fetchPrestations()
        }
        return
    }

    const handleEdit = async (forfait, name, type, price, duration, description, photos) => {
        const response = await handleRequest(
            'put',
            `prestation/${prestationSelected._id}`,
            {
                name: name,
                type: type,
                forfait: forfait,
                price: price,
                duration: duration,
                description: description,
                photos: photos.map((photo) => photo._id),
            },
            token
        )

        if (response?.data) {
            fetchPrestations()
            return response.data
        }
        return
    }

    const handleDelete = async () => {
        const response = await handleRequest('delete', `prestation/${prestationSelected._id}`, {}, token)

        if (response?.data) {
            setPrestationSelected()
            fetchPrestations()
            setStep()
            return response.data
        }

        setStep()
        return
    }

    useEffect(() => {
        if (isLogged()) {
            fetchPrestations()
        }
    }, [id, token])

    return (
        <PrestationContext.Provider
            value={{
                prestations: prestations,
                prestationSelected: prestationSelected,
                activeView: activeView,
                step: step,
                setPrestations: fetchPrestations,
                setPrestationSelected: setPrestationSelected,
                setActiveView: setActiveView,
                handleSave: handleSave,
                handleCreate: handleCreate,
                handleEdit: handleEdit,
                handleDelete: handleDelete,
                setStep: setStep,
            }}
        >
            {children}
        </PrestationContext.Provider>
    )
}

export const usePrestation = () => useContext(PrestationContext)
