import React from 'react'
import { Icon, Input, Text } from '../../atoms'
import { Card } from '../../molecules'
import Girl from '../../../assets/avatar/Girl.svg'
import { Client } from '../../../contexts'

export const SearchSection = () => {
    const { loading, coiffeurs, categories, address, setCategories, selectCoiffeur, getCoiffeurs } =
        Client.useCoiffeur()

    return (
        <>
            <Input.AutocompleteSearch.DefaultClient
                setValue={(value) => {
                    getCoiffeurs(value)
                }}
            />
            {address?.formatted !== '' && (
                <div className="grid gap-2 px-2 mt-2">
                    <Text.Paragraph.Normal>Que recherchez vous ?</Text.Paragraph.Normal>
                    <div className="flex space-x-6">
                        <div className={`flex ${categories?.indexOf('coiffure') === -1 ? 'opacity-30' : ''}`}>
                            <Input.Checkbox
                                initialValue={categories?.indexOf('coiffure') !== -1}
                                setValue={() => {
                                    if (categories?.indexOf('coiffure') === -1) {
                                        setCategories([...categories, 'coiffure'])
                                    } else {
                                        if (categories?.indexOf('esthetique') === -1) setCategories([])
                                        else setCategories(['esthetique'])
                                    }
                                }}
                            />
                            <span className="ml-2">Coiffure</span>
                        </div>
                        <div className={`flex ${categories?.indexOf('esthetique') === -1 ? 'opacity-30' : ''}`}>
                            <Input.Checkbox
                                initialValue={categories?.indexOf('esthetique') !== -1}
                                setValue={() => {
                                    if (categories?.indexOf('esthetique') === -1) {
                                        setCategories([...categories, 'esthetique'])
                                    } else {
                                        if (categories?.indexOf('coiffure') === -1) setCategories([])
                                        else setCategories(['coiffure'])
                                    }
                                }}
                            />
                            <span className="ml-2">Esthétique</span>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-col mt-1">
                {loading ? (
                    <div className="grid w-full py-10">
                        <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
                    </div>
                ) : (
                    <div className="grid w-full gap-2">
                        {coiffeurs.length === 0 && 'Aucun coiffeur dans votre région'}
                        {coiffeurs.map((coiffeur) => (
                            <div key={coiffeur._id} onClick={() => selectCoiffeur(coiffeur)}>
                                <Card.Profil
                                    srcImage={`${
                                        coiffeur._profil.avatar ? coiffeur._profil.avatar?.thumbnailUrl : Girl
                                    }`}
                                    alt={`Avatar Girl`}
                                    title={`${coiffeur._profil.firstName} ${coiffeur._profil.lastName}`}
                                    subTitle={coiffeur._profil._specialities?.map(
                                        (speciality, index) =>
                                            `${speciality.name} ${
                                                index !== coiffeur._profil._specialities.length - 1 && ','
                                            }`
                                    )}
                                    icon={<Icon.ArrowNext className={`h-10 w-10`} />}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}
