import React, { useState } from 'react'

const CheckboxLittle = ({ initialValue, setValue }) => {
    const [active, setActive] = useState(initialValue)

    const handleChange = () => {
        const value = !active

        setActive(value)
        if (setValue) {
            setValue(value)
        }
    }

    return (
        <div
            className={`w-3 h-3 border-[1.5px] border-black flex items-center justify-center relative transition-all ${
                active ? 'bg-black' : 'bg-white'
            }`}
            onClick={handleChange}
        >
            <svg
                className={`absolute right-0 top-0 transition-all ${active ? 'text-white' : 'text-transparent'}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 14"
                fill="currentColor"
            >
                <path d="M5.71103 14C5.43904 14 5.16704 13.8989 4.95986 13.6976L0.311569 9.18163C-0.103856 8.77802 -0.103856 8.12554 0.311569 7.72204C0.726993 7.31843 1.39847 7.31843 1.8139 7.72204L5.71103 11.5083L16.1861 1.30273C17.5 0.000135422 17.6884 0.863272 17.6884 1.30273C17.6884 1.74219 18.1039 2.35871 17.6884 2.76231L6.46218 13.6976C6.25501 13.8989 5.98302 14 5.71103 14Z" />
            </svg>
        </div>
    )
}

export default CheckboxLittle
