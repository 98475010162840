import React, { useState, useEffect } from 'react'
import { Input, Button, Icon, Text, Modal } from '../../atoms'
import Boy from '../../../assets/avatar/Boy.svg'
import { Auth, Client } from '../../../contexts'
import { Image } from '..'

export const Form = () => {
    const { email, handleChangePassword, getNewPassword } = Auth.useAuth()
    const { firstName, lastName, phoneNumber, avatar, handleSaveAttribute } = Client.useProfil()

    const [newFirstName, setNewFirstName] = useState(firstName)
    const [newLastName, setNewLastName] = useState(lastName)
    const [changeAvatar, setChangeAvatar] = useState(false)
    const [newphoneNumber, setNewPhoneNumber] = useState(phoneNumber?.phone)

    const [changePassword, setChangePassword] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [forgotPassword, setForgotPassword] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        setNewFirstName(firstName)
    }, [firstName])

    useEffect(() => {
        setNewLastName(lastName)
    }, [lastName])

    useEffect(() => {
        setNewPhoneNumber(phoneNumber?.phone)
    }, [phoneNumber])

    return (
        <form className="grid gap-5">
            <div className="flex">
                <div className="flex flex-col mr-6">
                    <Input.Text.Classic
                        labelValue="Prénom"
                        placeholder="Prénom"
                        value={newFirstName}
                        setValue={(value) => setNewFirstName(value)}
                        onBlur={() => handleSaveAttribute('firstName', newFirstName)}
                    />
                    <Input.Text.Classic
                        labelValue="Nom"
                        placeholder="Nom"
                        value={newLastName}
                        setValue={(value) => setNewLastName(value)}
                        onBlur={() => handleSaveAttribute('lastName', newLastName)}
                    />
                </div>
                <div className="flex flex-col gap-y-4">
                    <Text.Paragraph.Normal>Avatar</Text.Paragraph.Normal>
                    <div
                        className="rounded-full w-[30vw] h-[30vw] bg-light-gray border border-black border-opacity-10"
                        style={{
                            backgroundImage: `url(${avatar ? avatar?.thumbnailUrl : Boy})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                        }}
                        onClick={() => setChangeAvatar(true)}
                    ></div>
                </div>
            </div>
            <Input.AutocompleteSearch.ProfilClient />
            <Input.Text.Classic
                labelValue="Adresse email"
                placeholder="exemple@exemple.com"
                value={email}
                setValue={() => {}}
            />
            <Input.Text.Classic
                labelValue="Téléphone"
                placeholder="06 12 34 56 78"
                value={newphoneNumber}
                setValue={(value) => setNewPhoneNumber(value)}
                onBlur={() =>
                    handleSaveAttribute('phoneNumber', { code: '+33', short: 'FR', phone: newphoneNumber.toString() })
                }
            />
            <div className="flex justify-center mt-6">
                <Button.Primary
                    iconLeft={<Icon.Lock className={`h-6`} />}
                    onClick={() => {
                        setChangePassword(true)
                    }}
                >
                    Changer de mot de passe
                </Button.Primary>
            </div>
            <Modal.default
                isOpen={changePassword}
                title="Changer de mot de passe"
                confirmButton={
                    <Button.Primary
                        iconRight={<Icon.ArrowNext />}
                        onClick={() => handleChangePassword(oldPassword, password, confirmPassword)}
                    >
                        Valider
                    </Button.Primary>
                }
                setIsOpen={() => setChangePassword(false)}
            >
                <div
                    onClick={() => {
                        setChangePassword(false)
                        setForgotPassword(true)
                    }}
                >
                    <Text.Link>Mot de passe oublié</Text.Link>
                </div>
                <Input.Text.Password
                    labelValue="Mot de passe actuel"
                    placeholder="Taper votre mot de passe actuel..."
                    value={oldPassword}
                    setValue={setOldPassword}
                />
                <Input.Text.Password
                    labelValue="Nouveau mot de passe"
                    placeholder="Taper votre nouveau mot de passe..."
                    value={password}
                    setValue={setPassword}
                />
                <Input.Text.Password
                    labelValue="Confirmer le nouveau mot de pass"
                    placeholder="Retaper le mot de passe..."
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                />
            </Modal.default>
            <Modal.default
                title="Mot de passe oublié ?"
                isOpen={forgotPassword}
                setIsOpen={() => setForgotPassword(false)}
                backButton={
                    <Button.Secondary className="mx-auto" onClick={() => setForgotPassword(false)}>
                        Annuler
                    </Button.Secondary>
                }
                confirmButton={
                    <Button.Primary
                        iconRight={<Icon.ArrowNext className="w-5" />}
                        onClick={async () => {
                            if (email) {
                                await getNewPassword(email)
                                setSuccess(true)
                                setForgotPassword(false)
                            }
                        }}
                    >
                        Envoyer
                    </Button.Primary>
                }
            >
                <Text.Paragraph.Normal className="text-center">
                    Récupérer un nouveau mot de passe par mail pour pouvoir le changer.
                </Text.Paragraph.Normal>
                <Input.Text.Classic value={email} setValue={() => {}} labelValue="Email" />
            </Modal.default>
            <Modal.default
                title="Mot de passe réinitialisé envoyé"
                isOpen={success}
                setIsOpen={() => setSuccess(false)}
                backButton={<Button.Secondary onClick={() => setSuccess(false)}>Retour</Button.Secondary>}
            >
                <Text.Paragraph.Normal className="text-center">
                    Veuillez vous rendre sur votre messagerie électronique et utiliser le mot de passe que nous vous
                    avons envoyé pour vous connecter.
                </Text.Paragraph.Normal>
            </Modal.default>
            <Image.Upload
                title="Changer d'avatar"
                setIsOpen={() => setChangeAvatar(false)}
                isOpen={changeAvatar}
                afterUpload={async (value) => {
                    await handleSaveAttribute('avatar', value)
                    setChangeAvatar(false)
                }}
            />
            <Modal.default
                title="Mot de passe réinitialisé envoyé"
                isOpen={success}
                setIsOpen={() => setSuccess(false)}
                backButton={<Button.Secondary onClick={() => setSuccess(false)}>Retour</Button.Secondary>}
            >
                <Text.Paragraph.Normal className="text-center">
                    Veuillez vous rendre sur votre messagerie électronique et utiliser le mot de passe que nous vous
                    avons envoyé pour vous connecter.
                </Text.Paragraph.Normal>
            </Modal.default>
        </form>
    )
}
