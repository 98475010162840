import { setSessionStorageValue, getSessionStorageValue } from '../../storage'

export const storeValue = (
    firstName,
    lastName,
    avatar,
    description,
    address,
    phoneNumber,
    rayon,
    yearsExperience,
    _photos,
    _specialities,
    facebook,
    instagram,
    linkedin,
    pinterest,
    google,
    schedules,
    category
) => {
    setSessionStorageValue('firstName', firstName)
    setSessionStorageValue('lastName', lastName)
    setSessionStorageValue('avatar', JSON.stringify(avatar))
    setSessionStorageValue('description', JSON.stringify(description))
    setSessionStorageValue('address', JSON.stringify(address))
    setSessionStorageValue('phoneNumber', JSON.stringify(phoneNumber))
    setSessionStorageValue('rayon', rayon)
    setSessionStorageValue('yearsExperience', yearsExperience)
    setSessionStorageValue('_photos', JSON.stringify(_photos))
    setSessionStorageValue('_specialities', JSON.stringify(_specialities))
    setSessionStorageValue('facebook', JSON.stringify(facebook))
    setSessionStorageValue('instagram', JSON.stringify(instagram))
    setSessionStorageValue('linkedin', JSON.stringify(linkedin))
    setSessionStorageValue('pinterest', JSON.stringify(pinterest))
    setSessionStorageValue('google', JSON.stringify(google))
    setSessionStorageValue('schedules', JSON.stringify(schedules))
    setSessionStorageValue('category', JSON.stringify(category))
}

export const getSotredValue = () => {
    const firstName = getSessionStorageValue('firstName')
    const lastName = getSessionStorageValue('lastName')
    const avatar =
        getSessionStorageValue('avatar') !== 'undefined' ? JSON.parse(getSessionStorageValue('avatar')) : null
    const description = JSON.parse(getSessionStorageValue('description'))
    const address = JSON.parse(getSessionStorageValue('address'))
    const phoneNumber = JSON.parse(getSessionStorageValue('phoneNumber'))
    const rayon = getSessionStorageValue('rayon')
    const yearsExperience = getSessionStorageValue('yearsExperience')
    const _photos = JSON.parse(getSessionStorageValue('_photos'))
    const _specialities = JSON.parse(getSessionStorageValue('_specialities'))
    const facebook =
        getSessionStorageValue('facebook') !== 'undefined' ? JSON.parse(getSessionStorageValue('facebook')) : null
    const instagram =
        getSessionStorageValue('instagram') !== 'undefined' ? JSON.parse(getSessionStorageValue('instagram')) : null
    const linkedin =
        getSessionStorageValue('linkedin') !== 'undefined' ? JSON.parse(getSessionStorageValue('linkedin')) : null
    const pinterest =
        getSessionStorageValue('pinterest') !== 'undefined' ? JSON.parse(getSessionStorageValue('pinterest')) : null
    const google =
        getSessionStorageValue('google') !== 'undefined' ? JSON.parse(getSessionStorageValue('google')) : null
    const schedules =
        getSessionStorageValue('schedules') !== 'undefined' ? JSON.parse(getSessionStorageValue('schedules')) : []
    const category =
        getSessionStorageValue('category') !== 'undefined' ? JSON.parse(getSessionStorageValue('category')) : []

    return {
        firstName: firstName,
        lastName: lastName,
        avatar: avatar,
        description: description,
        address: address,
        phoneNumber: phoneNumber,
        rayon: rayon,
        yearsExperience: yearsExperience,
        _photos: _photos,
        _specialities: _specialities,
        facebook: facebook,
        instagram: instagram,
        linkedin: linkedin,
        pinterest: pinterest,
        google: google,
        schedules: schedules,
        category: category,
    }
}
