import React, { useEffect, useState } from 'react'

import { NextUIProvider } from '@nextui-org/react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'

import { Unlogged, Client, Coiffeur } from './pages'

import { Layout } from './components/templates'
import { Request, Auth, Hairdresser, Client as ClientFinal, Images, History } from './contexts'

import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'
import { SafeArea } from 'capacitor-plugin-safe-area'

const Router = () => {
    const { handleRequest } = Request.useRequest()
    const { isLogged, id, token } = Auth.useAuth()
    const location = useLocation()

    const [tokenRegistered, setTokenRegistered] = useState(false)

    const registerDeviceToken = async (deviceToken) => {
        if (Capacitor.getPlatform() !== 'web') {
            if (deviceToken) {
                const response = await handleRequest(
                    'post',
                    'devices',
                    { token: deviceToken, platform: Capacitor.getPlatform() === 'ios' ? 'ios' : 'firebase' },
                    token,
                    null,
                    true
                )

                if (response?.data) setTokenRegistered(true)
            }
        }
    }

    const initiatePushNotifications = () => {
        PushNotifications.requestPermissions().then((result) => {
            if (result.receive === 'granted') {
                PushNotifications.register()
            } else {
                alert(
                    'Si vous souhaitez être informé de vos rendez-vous par notifications vous devez autoriser Iléa App à vous envoyer des notifications.'
                )
            }
        })
    }

    const setSafeArea = async () => {
        const safeAreaData = await SafeArea.getSafeAreaInsets()
        const { insets } = safeAreaData
        const platform = Capacitor.getPlatform()

        document.documentElement.style.setProperty('--safe-area-top', `${platform === 'ios' ? insets.top : 0}px`)
        document.documentElement.style.setProperty(
            '--safe-area-bottom',
            `${platform === 'ios' ? insets.bottom / 2 : 0}px`
        )

        document.body.style.height = `cacl(100% - var(--safe-area-top) var(--safe-area-bottom))`
        document.body.style.marginTop = `var(--safe-area-top)`
    }

    useEffect(() => {
        setSafeArea()
        if (Capacitor.getPlatform() !== 'web') {
            PushNotifications.addListener('registration', (deviceToken) => {
                localStorage.setItem('deviceToken', deviceToken.value)
                if (isLogged()) {
                    registerDeviceToken(deviceToken.value)
                }
            })

            PushNotifications.addListener('registrationError', (error) => {
                console.info("Le service de notification n'a pas pu être enregistré" + JSON.stringify(error))
            })

            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                console.info('Push received: ' + JSON.stringify(notification))
                //TODO: update list of waiting events for hairdresser and list of bookings for clients
            })

            initiatePushNotifications()
        }
    }, [])

    useEffect(() => {
        if (isLogged()) {
            if (!tokenRegistered) {
                const deviceToken = localStorage.getItem('deviceToken')
                registerDeviceToken(deviceToken)
            }
        }
    }, [id, token])

    useEffect(() => {
        setSafeArea()
        if (isLogged()) {
            if (!tokenRegistered) {
                const deviceToken = localStorage.getItem('deviceToken')
                registerDeviceToken(deviceToken)
            }
        }
    }, [location])

    return (
        <Routes>
            <Route path="/" element={<Layout.UnLogged />}>
                <Route path="" element={<Unlogged.Home />} />
                <Route path="login" element={<Unlogged.Login />} />
                <Route path="signup" element={<Unlogged.Signup />} />
            </Route>
            <Route path="/hairdresser/:userId" element={<Layout.UnLoggedBooking />}>
                <Route path="details" element={<Unlogged.Hairdresser.Details />} />
                <Route path="services" element={<Unlogged.Hairdresser.Services />} />
            </Route>
            <Route path="/client" element={<Layout.Client />}>
                <Route path="" element={<Client.Home />} />
                <Route path="favorites" element={<Client.Favoris />} />
                <Route path="basket" element={<Client.Basket />} />
                <Route path="hairdresser/:userId/details" element={<Client.Hairdresser.Details />} />
                <Route path="hairdresser/:userId/services" element={<Client.Hairdresser.Services />} />
                <Route path="profil" element={<Client.Profil />} />
                <Route path="reservations" element={<Client.Bookings />} />
            </Route>
            <Route path="/hairdresser" element={<Layout.Coiffeur />}>
                <Route path="" element={<Coiffeur.Home />} />
                <Route path="profil" element={<Coiffeur.Profil />} />
                <Route path="waiting" element={<Coiffeur.Waiting />} />
                <Route path="clients" element={<Coiffeur.Clients />} />
                <Route path="prestations" element={<Coiffeur.Prestation />} />
            </Route>
            <Route path="*" element={<Unlogged.NotFound />} />
        </Routes>
    )
}

const App = () => {
    const navigate = useNavigate()

    return (
        <NextUIProvider navigate={navigate}>
            <Request.RequestContextProvider>
                <Auth.AuthContextProvider>
                    <History.HistoricContextProvider>
                        <Images.ImagesContextProvider>
                            <Hairdresser.ProfilContextProvider>
                                <Hairdresser.AgendaContextProvider>
                                    <Hairdresser.ClientsContextProvider>
                                        <Hairdresser.PrestationContextProvider>
                                            <Hairdresser.BookingContextProvider>
                                                <ClientFinal.ProfilContextProvider>
                                                    <ClientFinal.CoiffeurContextProvider>
                                                        <ClientFinal.BasketContextProvider>
                                                            <ClientFinal.BookingsClientContextProvider>
                                                                <Router />
                                                            </ClientFinal.BookingsClientContextProvider>
                                                        </ClientFinal.BasketContextProvider>
                                                    </ClientFinal.CoiffeurContextProvider>
                                                </ClientFinal.ProfilContextProvider>
                                            </Hairdresser.BookingContextProvider>
                                        </Hairdresser.PrestationContextProvider>
                                    </Hairdresser.ClientsContextProvider>
                                </Hairdresser.AgendaContextProvider>
                            </Hairdresser.ProfilContextProvider>
                        </Images.ImagesContextProvider>
                    </History.HistoricContextProvider>
                </Auth.AuthContextProvider>
            </Request.RequestContextProvider>
        </NextUIProvider>
    )
}

export default App
