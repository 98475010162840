import React from 'react'
import { useBasket } from '../../../contexts/client'
import { Card } from '../../molecules'
import { List } from '../../atoms'
import { Modal } from '.'

import placeholder from '../../../assets/icons/Image.svg'

const Prestations = () => {
    const { prestations, activeView, setActiveView, setPrestationSelected, changePrestation, step } = useBasket()

    return (
        <div>
            {step !== 'address' ? (
                <>
                    <div className="grid grid-cols-3 gap-3">
                        <button
                            className={`inline-block px-4 py-2 rounded transition-all ${
                                activeView === 'femme' ? 'bg-blue border border-black' : 'bg-light-gray'
                            }`}
                            onClick={() => setActiveView('femme')}
                        >
                            Femme
                        </button>
                        <button
                            className={`inline-block px-4 py-2 rounded transition-all ${
                                activeView === 'homme' ? 'bg-blue border border-black' : 'bg-light-gray'
                            }`}
                            onClick={() => setActiveView('homme')}
                        >
                            Homme
                        </button>
                        <button
                            className={`inline-block px-4 py-2 rounded transition-all ${
                                activeView === 'enfant' ? 'bg-blue border border-black' : 'bg-light-gray'
                            }`}
                            onClick={() => setActiveView('enfant')}
                        >
                            Enfant
                        </button>
                    </div>
                    <List.ListWithPics title={'Prestations'}>
                        {prestations?.map((prestation, index) => {
                            if (prestation.type) {
                                if (prestation.type.toLowerCase().indexOf(activeView) !== -1) {
                                    if (!prestation.forfait) {
                                        return (
                                            <Card.PrestationClassique
                                                key={prestation._id}
                                                srcImage={`${
                                                    prestation._photos?.length > 0
                                                        ? prestation._photos[0].thumbnailUrl
                                                        : placeholder
                                                }`}
                                                alt={`Avatar Boy`}
                                                title={prestation.name}
                                                subTitle={`${prestation.price}€ - ${
                                                    prestation.duration / 1000 / 60
                                                }min`}
                                                badge={prestation.quantity > 0}
                                                onClick={() => {
                                                    setPrestationSelected({ ...prestation, index: index })
                                                }}
                                                quantity={prestation.quantity}
                                                changePrestation={(quantity) =>
                                                    changePrestation(index, prestation, quantity)
                                                }
                                            />
                                        )
                                    }
                                }
                            }
                        })}
                    </List.ListWithPics>
                    {prestations?.filter(
                        (prestation) => prestation.forfait && prestation.type?.toLowerCase().indexOf(activeView) !== -1
                    ).length !== 0 && (
                        <List.ListWithPics title={'Forfaits'}>
                            {prestations?.map((prestation, index) => {
                                if (prestation.type) {
                                    if (prestation.type.toLowerCase().indexOf(activeView) !== -1) {
                                        if (prestation.forfait) {
                                            return (
                                                <Card.PrestationClassique
                                                    key={prestation._id}
                                                    srcImage={`${
                                                        prestation._photos?.length > 0
                                                            ? prestation._photos[0].thumbnailUrl
                                                            : placeholder
                                                    }`}
                                                    alt={`Avatar Boy`}
                                                    title={prestation.name}
                                                    subTitle={`${prestation.price}€ - ${
                                                        prestation.duration / 1000 / 60
                                                    }min`}
                                                    badge={prestation.quantity > 0}
                                                    onClick={() => {
                                                        setPrestationSelected({ ...prestation, index: index })
                                                    }}
                                                    quantity={prestation.quantity}
                                                    changePrestation={(quantity) =>
                                                        changePrestation(index, prestation, quantity)
                                                    }
                                                />
                                            )
                                        }
                                    }
                                }
                            })}
                        </List.ListWithPics>
                    )}
                    <Modal.Prestation />
                    <Modal.Algorithme />
                    <Modal.Success />
                </>
            ) : (
                <Modal.Address />
            )}
        </div>
    )
}

export { Prestations }
