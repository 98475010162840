import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from '../auth'
import { useRequest } from '../request'

const ImagesContext = createContext({
    images: Array,
    getImages: async () => {
        return Promise.resolve()
    },
})
export function ImagesContextProvider({ children }) {
    const [images, setImages] = useState([])

    const { token, id, isLogged } = useAuth()
    const { handleRequest } = useRequest()

    const getImagesCache = async () => {
        const response = await handleRequest('get', `image/user/${id}`, null, token)

        if (response?.data) {
            setImages(response.data.images)
        }
    }

    const getImages = async () => {
        const response = await handleRequest('get', `image/user/${id}`, null, token, null, true)

        if (response) {
            setImages(response?.data?.images)
        }
    }

    useEffect(() => {
        if (isLogged()) {
            getImagesCache()
        }
    }, [id, token])

    return (
        <ImagesContext.Provider
            value={{
                images: images,
                getImages: getImages,
            }}
        >
            {children}
        </ImagesContext.Provider>
    )
}
export const useImages = () => useContext(ImagesContext)
