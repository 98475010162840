import React from 'react'
import { TitleSection } from '../../../molecules'
import { Client } from '../../../../contexts'

export const Hours = () => {
    const { coiffeurSelected } = Client.useCoiffeur()

    return (
        <>
            <TitleSection textTitle={'Biographie'}>
                <div className="flex justify-center">
                    {coiffeurSelected?._profil?.description && (
                        <>
                            {typeof coiffeurSelected?._profil?.description === 'string'
                                ? coiffeurSelected?._profil?.description
                                : typeof coiffeurSelected?._profil?.description === 'object'
                                ? coiffeurSelected?._profil?.description.length > 0
                                    ? typeof coiffeurSelected?._profil?.description[0] === 'string'
                                        ? coiffeurSelected?._profil?.description[0]
                                        : coiffeurSelected?._profil?.description[0][0]?.children[0]?.text
                                    : ''
                                : ''}
                        </>
                    )}
                </div>
            </TitleSection>
        </>
    )
}
