import React, { useEffect, useState } from 'react'
import { Unlogged } from '../../../components/organisms'
import { Client, Auth } from '../../../contexts'
import { useParams, useNavigate } from 'react-router-dom'
import { Text } from '../../../components/atoms'

const Details = () => {
    const { userId } = useParams()
    const navigate = useNavigate()

    const { id, token, isLogged } = Auth.useAuth()
    const { coiffeurSelected, getCoiffeurById } = Client.useCoiffeur()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!coiffeurSelected) {
            setLoading(true)
        }
        getCoiffeurById(userId)
    }, [])

    useEffect(() => {
        if (coiffeurSelected) setLoading(false)
    }, [coiffeurSelected])

    useEffect(() => {
        if (isLogged()) {
            navigate(`/client/hairdresser/${userId}/details`, { state: { from: `${location.pathname}?instagram` } })
        }
    }, [token, id])

    return (
        <div>
            {loading ? (
                <div></div>
            ) : (
                <>
                    <Unlogged.Hairdresser.Profil />
                    <Unlogged.Hairdresser.Hours />
                    <Unlogged.Hairdresser.Contact />
                    <Unlogged.Hairdresser.Specialties />
                    <div className="grid gap-5 mt-10">
                        <Text.Paragraph.Normal className="text-lg font-medium">Galerie photos</Text.Paragraph.Normal>
                        <div className="grid grid-cols-2 gap-2">
                            {coiffeurSelected?._profil?._photos?.map((photo) => {
                                return (
                                    <>
                                        <div
                                            key={photo._id}
                                            className="relative rounded-lg w-[40vw] h-[40vw] bg-light-gray border border-black border-opacity-10"
                                            style={{
                                                backgroundImage: `url(${photo.thumbnailUrl})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center center',
                                                backgroundSize: 'cover',
                                            }}
                                        ></div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Details
