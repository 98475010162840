import React, { createContext, useContext, useState } from 'react'
import { useAuth } from '../../auth'
import { useRequest } from '../../request'

/* eslint no-unused-vars: 0 */
const BookingsClientContext = createContext({
    bookingsClient: Array,
    method: String,
    bookingSelected: Object,
    deleteBooking: async () => {
        return Promise.resolve()
    },
    getBookings: async () => {
        return Promise.resolve()
    },
    handleBookingSelected: (method, booking) => {},
})
export function BookingsClientContextProvider({ children }) {
    const [bookingsClient, setBookingsClient] = useState([])
    const { token, isLogged } = useAuth()
    const { handleRequest } = useRequest()

    const [bookingSelected, setBookingSelected] = useState({})
    const [method, setMethod] = useState('')

    const handleBookingSelected = (method, booking) => {
        setMethod(method)
        setBookingSelected(booking)
    }

    const deleteBooking = async () => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `client/bookings/${bookingSelected._id}`,
                {
                    state: 'cancel',
                    date: bookingSelected.date,
                },
                token
            )

            if (response?.data) {
                getBookings()
            }

            return
        } else console.log('non connecté')
    }

    const getBookings = async () => {
        if (isLogged()) {
            const response = await handleRequest('get', 'client/bookings', null, token, null, true)

            if (response?.data) {
                setBookingsClient(response.data.bookings)
            }
        }
    }
    return (
        <BookingsClientContext.Provider
            value={{
                bookingsClient: bookingsClient,
                method: method,
                bookingSelected: bookingSelected,
                handleBookingSelected: handleBookingSelected,
                deleteBooking: deleteBooking,
                getBookings: getBookings,
            }}
        >
            {children}
        </BookingsClientContext.Provider>
    )
}
export const useBookingsClient = () => useContext(BookingsClientContext)
