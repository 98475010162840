import React from 'react'
import { ReservationRecap } from '../../components/organisms'

const Basket = () => {
    return (
        <>
            <ReservationRecap.Header />
            <ReservationRecap.Main/>
            <ReservationRecap.Footer/>
        </>
    )
}

export default Basket
