import React from 'react'
import { Button, Icon } from '../../../atoms'
import { Card, Return } from '../../../molecules'
import Boy from '../../../../assets/avatar/Boy.svg'
import { Client } from '../../../../contexts'
import { useNavigate } from 'react-router-dom'

export const Profil = () => {
    const navigate = useNavigate()
    const { coiffeurSelected, selectCoiffeur, address } = Client.useCoiffeur()
    const {setStep} = Client.useBasket()
    return (
        <div className="relative ">
            <Return
                className="font-semibold"
                onClick={() => {
                    selectCoiffeur(null)
                    navigate('/client')
                }}
            ></Return>
            <Card.HairdresserPresentation
                srcImage={`${coiffeurSelected?._profil?.avatar ? coiffeurSelected?._profil?.avatar.thumbnailUrl : Boy}`}
                alt={`Avatar professionnel de beauté Iléa`}
                title={`${coiffeurSelected?._profil.firstName} ${coiffeurSelected?._profil.lastName}`}
                xp={(coiffeurSelected?._profil.yearsExperience)&&`${coiffeurSelected?._profil.yearsExperience} années d'expériences`}
                note={`5`}
                facebook={coiffeurSelected?._profil?.facebook && coiffeurSelected?._profil?.facebook}
                instagram={coiffeurSelected?._profil?.instagram && coiffeurSelected?._profil?.instagram}
                linkedin={coiffeurSelected?._profil?.linkedin && coiffeurSelected?._profil?.linkedin}
                pinterest={coiffeurSelected?._profil?.pinterest && coiffeurSelected?._profil?.pinterest}
                google={coiffeurSelected?._profil?.google && coiffeurSelected?._profil?.google}
                category={coiffeurSelected?._profil?.category}
            />
            <div className="flex justify-center gap-x-2">
                <Button.Secondary
                    onClick={() => {
                        navigate(`/hairdresser/${coiffeurSelected._id}/services`)
                        if(address.formatted == ''){
                            setStep("address")
                        }
                    }}
                >
                    Prestations
                </Button.Secondary>
                <Button.Primary
                    iconRight={<Icon.ArrowNext />}
                    onClick={() => {
                        navigate(`/hairdresser/${coiffeurSelected._id}/services`)
                        if(address.formatted == ''){
                            setStep("address")
                        }
                        
                    }}
                >
                    Prendre RDV
                </Button.Primary>
            </div>
        </div>
    )
}
