import React from 'react'
import { Icon, Text } from '../../atoms'

export const Inscription = () => {
    return (
        <div className="grid gap-[18px] max-w-[90vw]">
            <Text.h2 className="mx-auto text-center max-w-80">Quels sont les avantages de réserver sur Iléa ?</Text.h2>
            <div className="grid justify-start grid-cols-2 mx-auto space-y-1 md:gap-x-8">
                <div className="flex items-center space-x-1 md:space-x-3">
                    <Icon.CheckSquare className="w-7 text-purple" />
                    <Text.Paragraph.Normal>Optimisation</Text.Paragraph.Normal>
                </div>
                <div className="flex items-center space-x-1 md:space-x-3">
                    <Icon.CheckSquare className="w-7 text-purple" />
                    <Text.Paragraph.Normal>Gain de temps</Text.Paragraph.Normal>
                </div>
                <div className="flex items-center space-x-1 md:space-x-3">
                    <Icon.CheckSquare className="w-7 text-purple" />
                    <Text.Paragraph.Normal>Flexibilité</Text.Paragraph.Normal>
                </div>
                <div className="flex items-center space-x-1 md:space-x-3">
                    <Icon.CheckSquare className="w-7 text-purple" />
                    <Text.Paragraph.Normal>Historique</Text.Paragraph.Normal>
                </div>
            </div>
        </div>
    )
}
