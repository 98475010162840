import React from 'react'
import { Button, Icon, Modal, Text } from '../../../atoms'
import { Client } from '../../../../contexts'
import dayjs from 'dayjs'
import { formatSecondsToHHhmm, formatSecondsToInput } from '../../../../utils'

import Boy from '../../../../assets/avatar/Boy.svg'
import Girl from '../../../../assets/avatar/Girl.svg'

export const BookingDetails = () => {
    const { method, handleBookingSelected, bookingSelected } = Client.useBookingsClient()

    const countMap = new Map()
    bookingSelected?._prestation?.forEach((prestation) => {
        countMap.set(prestation._id, (countMap.get(prestation._id) || 0) + 1)
    })
    return (
        <Modal.default
            title={
                bookingSelected?.state === 'done'
                    ? 'Votre réservation passée'
                    : bookingSelected?.state === 'booked'
                    ? 'Votre réservation à venir'
                    : bookingSelected?.state === 'cancel'
                    ? 'Votre réservation annulée'
                    : bookingSelected?.state === 'done' && 'Votre réservation en attente'
            }
            isOpen={method === 'details'}
            setIsOpen={() => {
                handleBookingSelected(null, null)
            }}
            confirmButton={
                bookingSelected?.state === 'done' ? (
                    <Button.Primary
                        iconLeft={<Icon.ArrowBack className="h-4" />}
                        onClick={() => {
                            handleBookingSelected(null, null)
                        }}
                    >
                        Retour
                    </Button.Primary>
                ) : bookingSelected?.state === 'booked' &&
                  dayjs(`${bookingSelected.date}T${formatSecondsToInput(bookingSelected.from)}:00`).format() >=
                      dayjs().format() ? (
                    <Button.Primary
                        iconLeft={<Icon.Close className="h-4" />}
                        onClick={() => {
                            handleBookingSelected('confirm', bookingSelected)
                        }}
                    >
                        Annuler
                    </Button.Primary>
                ) : (
                    <></>
                )
            }
            backButton={
                bookingSelected?.state === 'booked' &&
                dayjs(`${bookingSelected.date}T${formatSecondsToInput(bookingSelected.from)}:00`).format() >=
                    dayjs().format() ? (
                    <></>
                ) : (
                    <></>
                )
            }
        >
            {bookingSelected && (
                <div className="grid gap-3 text-center">
                    <div className="flex items-center justify-around mx-auto mb-4 space-x-3">
                        <Text.Link
                            className="flex items-center gap-4 no-underline"
                            href={`tel:${
                                bookingSelected._coiffeur?._profil?.phoneNumber?.phone.length === 10
                                    ? bookingSelected._coiffeur?._profil?.phoneNumber?.phone
                                    : `0${bookingSelected._coiffeur?._profil?.phoneNumber?.phone}`
                            }`}
                        >
                            <Icon.Phone className="h-8" />
                        </Text.Link>
                        <div
                            style={{
                                backgroundImage: `url(${
                                    bookingSelected._coiffeur?._profil?.avatar?.urlThumnail
                                        ? bookingSelected._coiffeur?._profil?.avatar?.urlThumnail
                                        : bookingSelected._coiffeur?._profil?.sexe === 'female'
                                        ? Girl
                                        : Boy
                                })`,
                                backgroundPosition: `center center`,
                                backgroundSize: '100%',
                            }}
                            className="w-20 h-20 bg-no-repeat rounded-full bg-purple"
                        />
                        <Text.Paragraph.Normal className="text-xl font-bold leading-snug">
                            {bookingSelected._coiffeur?._profil?.firstName} <br />{' '}
                            {bookingSelected._coiffeur?._profil?.lastName}
                        </Text.Paragraph.Normal>
                    </div>
                    <p className="text-[20px] font-medium leading-4 text-center">
                        {Array.from(countMap.entries())
                            .map(([prestationId, quantity], index) => {
                                const prestation = bookingSelected._prestation.find((p) => p._id === prestationId)
                                if (quantity > 1) {
                                    return `${prestation.name} x${quantity}${index !== countMap.size - 1 ? ', ' : ''}`
                                } else {
                                    return `${prestation.name}${index !== countMap.size - 1 ? ', ' : ''}`
                                }
                            })
                            .join('')}
                    </p>
                    <p className="text-[20px] font-medium leading-8 text-center">
                        {`${bookingSelected?.price ? bookingSelected.price + ' € -' : ''}`}{' '}
                        {`${formatSecondsToHHhmm(bookingSelected.duration)}`}
                    </p>
                    <p className="text-[17px] font-medium text-center capitalize">
                        {dayjs(bookingSelected.date).format('ddd DD MMMM YYYY') +
                            ' à ' +
                            formatSecondsToHHhmm(bookingSelected.from)}
                    </p>
                    <div>
                        <span
                            className={` text-xs  text-center px-3 py-1 rounded-full
                                    ${
                                        bookingSelected.state === 'cancel'
                                            ? 'bg-red-400'
                                            : bookingSelected.state === 'waiting'
                                            ? 'bg-yellow'
                                            : bookingSelected.state === 'booked'
                                            ? 'bg-[#5CFC4F]'
                                            : bookingSelected.state === 'done' && 'bg-[#5CFC4F]'
                                    }
                                `}
                        >
                            {bookingSelected.state === 'cancel'
                                ? 'Le rendez-vous a été annulé'
                                : bookingSelected.state === 'waiting'
                                ? 'Le rendez-vous est en attente'
                                : bookingSelected.state === 'booked'
                                ? 'Le rendez-vous est confirmé'
                                : bookingSelected.state === 'done' && 'Le rendez-vous a été effectué'}
                        </span>
                    </div>

                    {bookingSelected.reason !== '' && (
                        <div>
                            <Text.Paragraph.Normal>Motif: {bookingSelected.reason}</Text.Paragraph.Normal>
                        </div>
                    )}
                </div>
            )}
        </Modal.default>
    )
}

export const Confirmation = () => {
    const { getBookings, method, handleBookingSelected, bookingSelected, deleteBooking } = Client.useBookingsClient()
    return (
        <Modal.default
            title={`Êtes-vous certain de vouloir supprimer cette réservation ?`}
            isOpen={method === 'confirm'}
            setIsOpen={() => {
                handleBookingSelected(null, null)
            }}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="h-4" />}
                    onClick={async () => {
                        await deleteBooking(bookingSelected)
                        getBookings()
                        handleBookingSelected(null, null)
                    }}
                >
                    Confirmer
                </Button.Primary>
            }
            backButton={
                <Button.Secondary
                    className="px-6"
                    onClick={() => {
                        handleBookingSelected('details', bookingSelected)
                    }}
                >
                    Annuler
                </Button.Secondary>
            }
        ></Modal.default>
    )
}
