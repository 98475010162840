import React, { useEffect } from 'react'
import { Request } from '../../contexts'

const Info = ({ message }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-white w-[90vw] fixed left-[5vw] border-[1.5px] rounded-md border-light-gray z-[99]">
            <div className="grid gap-2 px-6 py-4 bg-light-gray bg-opacity-20">
                <div className="text-sm font-bold">Information</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

const Warning = ({ message }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-white w-[90vw] fixed left-[5vw] border-[1.5px] rounded-md border-yellow z-[99]">
            <div className="grid gap-2 px-6 py-4 bg-yellow bg-opacity-20">
                <div className="text-sm font-bold">Attention</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

const Success = ({ message }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-white w-[90vw] fixed left-[5vw] border-[1.5px] rounded-md border-green z-[99]">
            <div className="grid gap-2 px-6 py-4 bg-green bg-opacity-20">
                <div className="text-sm font-bold">Félicitation</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

const Error = ({ message }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-white w-[90vw] fixed left-[5vw] border-[1.5px] rounded-md border-pink z-[99]">
            <div className="grid gap-2 px-6 py-4 bg-pink bg-opacity-20">
                <div className="text-sm font-bold">Erreur</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

export { Info, Warning, Success, Error }
