import React, { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Request } from '..'
import { isGoodPassword, persistStorage, getAuthStored, hashPassword, isTokenExpired } from './utils'
import { getLocalStorageValue, getSessionStorageValue, setSessionStorageValue, setLocalStorageValue } from '../storage'

/* eslint no-unused-vars: 0 */
const AuthContext = createContext({
    id: String,
    profil: String,
    email: String,
    type: String,
    firstName: String,
    lastName: String,
    customer: String,
    token: String,
    needNewPassword: Boolean,
    isOpenCguCgv: String,
    showOnboardingDateChoice: Boolean,
    setIsOpenCguCgv: (value) => {},
    handleLoginClassic: async (email, password, noredirect) => {
        return Promise.resolve()
    },
    handleSignup: async (sexe, firstName, lastName, email, address, phoneNumber) => {
        return Promise.resolve()
    },
    handleLogout: () => {},
    getNewPassword: async (email) => {
        return Promise.resolve()
    },
    handleChangePassword: async (actualPassword, newPassword, confirmPassword) => {
        return Promise.resolve()
    },
    isLogged: () => {
        return false
    },
    handleDelete: async () => {},
    handleChooseOnboarding: async (date, time) => {},
})

export function AuthContextProvider({ children }) {
    const navigate = useNavigate()
    const { handleRequest, setMessage } = Request.useRequest()

    const [id, setId] = useState()
    const [profil, setProfil] = useState()
    const [email, setEmail] = useState()
    const [type, setType] = useState('client')
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [customer, setCustomer] = useState()
    const [password, setPassword] = useState()

    const [token, setToken] = useState()
    const [needNewPassword, setNeedNewPassword] = useState(false)

    const [restored, setRestored] = useState(false)
    const [isOpenCguCgv, setIsOpenCguCgv] = useState('')

    const [showOnboardingDateChoice, setShowOnboardingDateChoice] = useState(false)

    useEffect(() => {
        if (isTokenExpired()) {
            const stored = JSON.parse(getLocalStorageValue('auth'))
            if (stored && stored.email) {
                refreshToken(stored?.email)
            }
        }
        const stored = getAuthStored()
        if (stored) {
            setId(stored.id)
            setProfil(stored.profil)
            setEmail(stored.email)
            setType(stored.type)
            setFirstName(stored.firstName)
            setLastName(stored.lastName)
            setCustomer(stored.customer)
            setToken(stored.token)
            setNeedNewPassword(stored.needNewPassword)
            setPassword(stored.password)
        }
        setRestored(true)

        return () => {}
    }, [])

    const refreshToken = async (email) => {
        const response = await handleRequest(
            'post',
            'auth/refresh',
            {
                email: email,
            },
            null,
            null,
            true
        )

        if (response?.data?.user?.token) {
            const jsonify = {
                id: id,
                profil: profil,
                email: email,
                type: type,
                firstName: firstName,
                lastName: lastName,
                customer: customer,
                token: response.data.user.token,
                needNewPassword: needNewPassword,
                password: password,
            }
            setToken(response.data.user.token)
            setLocalStorageValue('auth', JSON.stringify(jsonify))
        }

        return response?.data?.user?.token
    }

    const getUser = async (email) => {
        const response = await handleRequest(
            'post',
            'auth',
            {
                email: email,
            },
            null,
            null,
            true
        )

        return response?.data?.user
    }

    const authUser = async (id, email) => {
        const response = await handleRequest(
            'post',
            `auth/login/${id}`,
            {
                email: email,
            },
            null,
            null,
            true
        )

        return response?.data?.user
    }

    const handleLoginClassic = async (email, password, noredirect) => {
        const user = await getUser(email)

        if (user) {
            const goodPassword = await isGoodPassword(password, user.password)

            if (goodPassword || process.env.NODE_ENV === 'development') {
                const userAuthenticated = await authUser(user._id, email)

                setId(userAuthenticated._id)
                setProfil(userAuthenticated._profil)
                setEmail(email)
                setFirstName(userAuthenticated.prenom)
                setLastName(userAuthenticated.nom)
                setType(userAuthenticated.type)
                setToken(userAuthenticated.token)
                setCustomer(userAuthenticated.customer)
                setNeedNewPassword(userAuthenticated.needNewPassword ? true : false)
                setPassword(user.password)

                persistStorage(
                    userAuthenticated._id,
                    userAuthenticated._profil,
                    email,
                    userAuthenticated.type,
                    userAuthenticated.prenom,
                    userAuthenticated.nom,
                    userAuthenticated.customer,
                    userAuthenticated.token,
                    userAuthenticated.needNewPassword,
                    user.password
                )

                if (!noredirect) {
                    navigate(`/${type === 'coiffeur' ? 'hairdresser' : 'client'}`)
                }
            } else {
                setMessage({
                    type: 'warning',
                    message: "La combinaison email / mot de passe n'est pas bonne.",
                })
            }
        } else {
            setMessage({
                type: 'warning',
                message: 'Aucun compte trouvé avec cet email.',
            })
        }

        return
    }

    function isEmail(email) {
        // Expression régulière pour vérifier le format de l'email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleSignup = async (
        sexe,
        firstName,
        lastName,
        email,
        address,
        phoneNumber,
        password,
        confirmPassword,
        type,
        siret,
        noredirect
    ) => {
        if (firstName.length > 1) {
            if (lastName.length > 1) {
                if (isEmail(email)) {
                    if (address?.place_id) {
                        if (phoneNumber.length >= 10) {
                            if (password === confirmPassword) {
                                if (type === 'coiffeur') {
                                    if (siret.length !== 14) {
                                        setMessage({ type: 'warning', message: 'Numéro de SIRET invalide' })
                                        return
                                    }
                                }

                                const passwordHashed = await hashPassword(password)
                                const response = await handleRequest(
                                    'post',
                                    `auth/signup`,
                                    {
                                        type: type,
                                        sexe: sexe,
                                        firstName: firstName,
                                        lastName: lastName,
                                        email: email,
                                        address: address,
                                        phoneNumber: phoneNumber,
                                        password: passwordHashed,
                                        siret: siret,
                                    },
                                    null,
                                    null,
                                    true
                                )
                                if (response?.data) {
                                    setId(response.data.user._id)
                                    setProfil(response.data.user._profil)
                                    setEmail(response.data.user.email)
                                    setFirstName(firstName)
                                    setLastName(lastName)
                                    setType(type)
                                    setToken(response.data.user.token)
                                    setCustomer(null)
                                    setNeedNewPassword(false)
                                    setPassword(response.data.password)

                                    persistStorage(
                                        response.data.user._id,
                                        response.data.user._profil,
                                        response.data.user.email,
                                        response.data.user.type,
                                        firstName,
                                        lastName,
                                        null,
                                        response.data.user.token,
                                        false,
                                        response.data.userpassword
                                    )

                                    if (type === 'client') {
                                        if (!noredirect) {
                                            navigate(`/client`)
                                        }
                                    } else {
                                        setShowOnboardingDateChoice(true)
                                        navigate('/hairdresser/profil')
                                    }
                                }
                            } else {
                                setMessage({ type: 'warning', message: 'Les mots de passe ne sont pas identique' })
                            }
                        } else {
                            setMessage({ type: 'warning', message: 'Numéro de téléphone invalide' })
                        }
                    } else {
                        setMessage({ type: 'warning', message: 'Adresse invalide' })
                    }
                } else {
                    setMessage({ type: 'warning', message: 'Email invalide' })
                }
            } else {
                setMessage({ type: 'warning', message: 'Prénom invalide' })
            }
        } else {
            setMessage({ type: 'warning', message: 'Prénom invalide' })
        }
    }

    const isLogged = () => {
        if (id && token) {
            return true
        }
        return false
    }

    const createAccount = async () => {
        try {
            await handleRequest(`post`, `coiffeur/account`, { token: token, id: id }, token, null, true)
        } catch (error) {
            console.log('Erreur lors de la request coiffeur/account : ', error.message)
        }
    }

    const handleChangePassword = async (actualPassword, newPassword, confirmPassword) => {
        const goodPassword = await isGoodPassword(actualPassword, password)

        if (goodPassword) {
            if (newPassword === confirmPassword) {
                const response = await handleRequest(
                    'put',
                    'auth/password',
                    { password: newPassword },
                    token,
                    null,
                    true
                )

                if (response.data) {
                    setPassword(response.data.user.password)

                    persistStorage(
                        id,
                        profil,
                        email,
                        type,
                        firstName,
                        lastName,
                        customer,
                        token,
                        false,
                        response.data.user.password
                    )

                    setMessage({ type: 'success', message: 'Mot de passe changé avec succès' })
                }
                return response
            } else {
                setMessage({ type: 'warning', message: 'Les deux mots de passes ne sont pas identique' })
            }
        } else {
            setMessage({ type: 'warning', message: 'Mot de passe actuel invalide' })
        }
    }

    const handleLogout = () => {
        setId()
        setId()
        setProfil()
        setEmail()
        setType('client')
        setFirstName()
        setLastName()
        setCustomer()
        setToken()
        setNeedNewPassword(false)
        setPassword()
        localStorage.clear()
        sessionStorage.clear()
        navigate('/')
    }

    const getNewPassword = async (email) => {
        return await handleRequest('get', `auth/password/${email}`, null, null, null, true)
    }

    const deleteAccount = async () => {
        await handleRequest('delete', `auth`, {}, token)
        handleLogout()
    }

    const handleChooseOnboarding = async (date, time) => {
        await handleRequest('put', 'user/onboarding', { date: date, time: time }, token)

        setShowOnboardingDateChoice(false)
    }

    return (
        <AuthContext.Provider
            value={{
                id: id,
                profil: profil,
                email: email,
                type: type,
                firstName: firstName,
                lastName: lastName,
                customer: customer,
                token: token,
                needNewPassword: needNewPassword,
                restored: restored,
                isOpenCguCgv: isOpenCguCgv,
                showOnboardingDateChoice: showOnboardingDateChoice,
                setIsOpenCguCgv: setIsOpenCguCgv,
                handleLoginClassic: handleLoginClassic,
                handleSignup: handleSignup,
                isLogged: isLogged,
                handleChangePassword: handleChangePassword,
                handleLogout: handleLogout,
                getNewPassword: getNewPassword,
                createAccount: createAccount,
                handleDelete: deleteAccount,
                handleChooseOnboarding: handleChooseOnboarding,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)
