import React from 'react'

const Loader = ({ children }) => {
    return (
        <div className="fixed top-0 bottom-0 left-0 flex flex-col justify-center items-center w-[100vw] h-[100vh] bg-white bg-opacity-80 z-[99]">
            <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
            {children}
        </div>
    )
}

export default Loader
