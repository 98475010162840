/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'

import { getSotredValue, storeValue } from './utils'

import Girl from '../../../assets/avatar/Girl.svg'
import { setSessionStorageValue } from '../../storage'

const ProfilContext = createContext({
    firstName: String,
    lastName: String,
    address: Object,
    rayon: Number,
    phoneNumber: Object,
    avatar: Object,
    yearsExperience: Number,
    description: Array,
    specialities: Array,
    photos: Array,
    facebook: String,
    instagram: String,
    pinterest: String,
    linkedin: String,
    google: String,
    schedules: Array,
    category: Array,
    handleSetAttribute: (attribute, value) => {},
    handleSaveAttribute: async (attribute, value) => {
        return Promise.resolve
    },
    addSpeciality: (name) => {
        return Promise.resolve
    },
    saveSchedule: async (schedules) => {
        return Promise.resolve
    },
})

export function ProfilContextProvider({ children }) {
    const { handleRequest, setMessage } = useRequest()
    const { id, token, isLogged } = useAuth()
    const [globalSpecialities, setGlobalSpecialities] = useState([])

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState({
        formatted: '',
    })
    const [rayon, setRayon] = useState(0)
    const [phoneNumber, setPhoneNumber] = useState({
        code: '+33',
        short: 'FR',
        phone: null,
    })
    const [avatar, setAvatar] = useState({
        thumbnailUrl: Girl,
    })
    const [yearsExperience, setYearsExperience] = useState(0)
    const [description, setDescription] = useState([])
    const [specialities, setSpecialities] = useState([])
    const [photos, setPhotos] = useState([])
    const [category, setCategory] = useState([])
    const [needSave, setNeedSave] = useState(false)

    const [facebook, setFacebook] = useState('')
    const [instagram, setInstagram] = useState('')
    const [pinterest, setPinterest] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [google, setGoogle] = useState('')

    const [schedules, setSchedules] = useState([])

    const handleSetAttribute = (attribute, value) => {
        setNeedSave(true)
        switch (attribute) {
            case 'firstName':
                setFirstName(value)
                break
            case 'lastName':
                setLastName(value)
                break
            case 'address':
                setAddress(value)
                break
            case 'rayon':
                setRayon(value)
                break
            case 'phoneNumber':
                setPhoneNumber(value)
                break
            case 'avatar':
                setAvatar(value)
                break
            case 'yearsExperience':
                setYearsExperience(value)
                break
            case 'description':
                setDescription(value)
                break
            case 'specialities':
                setSpecialities(value)
                break
            case 'photos':
                setPhotos(value)
                break
            case 'facebook':
                setFacebook(value)
                break
            case 'instagram':
                setInstagram(value)
                break
            case 'pinterest':
                setPinterest(value)
                break
            case 'linkedin':
                setLinkedin(value)
                break
            case 'google':
                setGoogle(value)
                break
            case 'category':
                setCategory(value)
                break
        }
    }

    const handleSaveAttribute = async (attribute, value) => {
        const url = /^(https|http):\/\/[^\s/$.?#].[^\s]*$/
        if (
            attribute === 'facebook' ||
            attribute === 'instagram' ||
            attribute === 'pinterest' ||
            attribute === 'linkedin' ||
            attribute === 'google'
        ) {
            if (!url.test(value)) {
                value = ''
            } else {
                setMessage({ type: 'success', message: 'Le lien est au bon format' })
            }
        }
        const response = await handleRequest('put', `profil/${attribute}`, { [attribute]: value }, token)

        handleSetAttribute(attribute, value)
        if (response?.data) {
            if (
                (attribute === 'facebook' && response.data.profil?.facebook === (undefined || null || '')) ||
                (attribute === 'instagram' && response.data.profil?.instagram === (undefined || null || '')) ||
                (attribute === 'pinterest' && response.data.profil?.pinterest === (undefined || null || '')) ||
                (attribute === 'linkedin' && response.data.profil?.linkedin === (undefined || null || '')) ||
                (attribute === 'google' && response.data.profil?.google === (undefined || null || ''))
            ) {
                setMessage({
                    type: 'error',
                    message: `Vérifier que c'est un lien (exemple: "https://www.${attribute}.com")`,
                })
                setNeedSave(false)
            } else {
                setMessage({ type: 'success', message: 'Profil enregistré' })
                setNeedSave(false)
            }
        }
        return
    }

    const addSpeciality = async (name) => {
        const response = await handleRequest('post', `speciality`, { name: name }, token)

        if (response?.data) {
            const newSpecialities = [...specialities]

            newSpecialities.push(response.data.speciality)
            await handleSaveAttribute('specialities', newSpecialities)
            return
        }
        return
    }

    const getProfil = async () => {
        const response = await handleRequest('get', `profil`, null, token)
        if (response) {
            if (!response?.error) {
                const profil = response.data.profil
                setFirstName(profil.firstName)
                setLastName(profil.lastName)
                setAvatar(profil.avatar)
                setDescription(profil.description)
                setAddress(profil.address)
                setPhoneNumber(profil.phoneNumber)
                setRayon(profil.rayon)
                setYearsExperience(profil.yearsExperience)
                setPhotos(profil._photos)
                setSpecialities(profil._specialities)
                setFacebook(profil.facebook)
                setInstagram(profil.instagram)
                setLinkedin(profil.linkedin)
                setPinterest(profil.pinterest)
                setGoogle(profil.google)
                setSchedules(profil.schedules)
                setCategory(profil.category)

                storeValue(
                    profil.firstName,
                    profil.lastName,
                    profil.avatar,
                    profil.description,
                    profil.address,
                    profil.phoneNumber,
                    profil.rayon,
                    profil.yearsExperience,
                    profil._photos,
                    profil._specialities,
                    profil.facebook,
                    profil.instagram,
                    profil.linkedin,
                    profil.pinterest,
                    profil.google,
                    profil.scheudles,
                    profil.category
                )
            }
        }
    }

    const getGlobalSpecialities = async () => {
        const response = await handleRequest('get', 'speciality')

        if (response?.data) {
            setGlobalSpecialities(response?.data.specialities)
        }
    }

    useEffect(() => {
        if (isLogged()) {
            if (window.location.pathname.indexOf('/hairdresser') !== -1) {
                getProfil()
            }
        }
    }, [token, id])

    useEffect(() => {
        getGlobalSpecialities()
        const storedValue = getSotredValue()

        if (storeValue) {
            setFirstName(storedValue.firstName)
            setLastName(storedValue.lastName)
            setAvatar(storedValue.avatar)
            setDescription(storedValue.description)
            setAddress(storedValue.address)
            setPhoneNumber(storedValue.phoneNumber)
            setRayon(storedValue.rayon)
            setYearsExperience(storedValue.yearsExperience)
            setPhotos(storedValue._photos)
            setFacebook(storedValue.facebook)
            setInstagram(storedValue.instagram)
            setLinkedin(storedValue.linkedin)
            setPinterest(storedValue.pinterest)
            setGoogle(storedValue.google)
            setSpecialities(storedValue._specialities)
            setSchedules(storedValue.schedules)
            setCategory(storedValue.category)
        }
    }, [])

    const saveSchedule = async (schedules) => {
        let schedulesSaved = [...schedules]

        const saveDefaultDay = async (schedule, index) => {
            if (schedule.changed) {
                await handleRequest(
                    `put`,
                    `coiffeur/days/default/${id}/${schedule.name}`,
                    { ...schedule },
                    token,
                    null,
                    true
                )
                schedulesSaved[index].changed = false
            }
            setSchedules(schedulesSaved)

            return index
        }

        let index = 0
        for await (const schedule of schedules) {
            await saveDefaultDay(schedule, index)
            index++
        }

        setSchedules(schedulesSaved)
        setSessionStorageValue('schedules', JSON.stringify(schedulesSaved))
    }

    return (
        <ProfilContext.Provider
            value={{
                firstName: firstName,
                lastName: lastName,
                address: address,
                rayon: rayon,
                phoneNumber: phoneNumber,
                avatar: avatar,
                yearsExperience: yearsExperience,
                description: description,
                specialities: specialities,
                photos: photos,
                globalSpecialities: globalSpecialities,
                needSave: needSave,
                facebook: facebook,
                instagram: instagram,
                pinterest: pinterest,
                linkedin: linkedin,
                google: google,
                schedules: schedules,
                category: category,
                handleSaveAttribute: handleSaveAttribute,
                handleSetAttribute: handleSetAttribute,
                addSpeciality: addSpeciality,
                saveSchedule: saveSchedule,
            }}
        >
            {children}
        </ProfilContext.Provider>
    )
}

export const useProfil = () => useContext(ProfilContext)
