import React from 'react'

import { Hairdresser } from '../../../molecules'

const Modal = () => {
    return (
        <>
            <Hairdresser.Modal.Booking.Delete />
            <Hairdresser.Modal.Booking.Update />
            <Hairdresser.Modal.Booking.Create />
            <Hairdresser.Modal.Booking.CreatePrivate />
            <Hairdresser.Modal.Booking.General />
            <Hairdresser.Modal.Booking.GeneralWaiting />
            <Hairdresser.Modal.Booking.Accept />
            <Hairdresser.Modal.Booking.DeleteBreak />
            <Hairdresser.Modal.Booking.AddNote />
        </>
    )
}

export default Modal
