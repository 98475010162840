import React, { useState } from 'react'

import { HairDresser } from '../../components/organisms'
import { Return } from '../../components/molecules'
import { Button, Icon, Modal, Text } from '../../components/atoms'
import { Auth } from '../../contexts'

const Profil = () => {
    const { handleLogout, handleDelete } = Auth.useAuth()

    const [edit, setEdit] = useState(false)
    const [deleteAccount, setDeleteAccount] = useState(false)

    return (
        <>
            {edit ? (
                <div className="grid gap-8">
                    <Return onClick={() => setEdit(false)} />
                    <Text.h1 className="text-center">Paramètres</Text.h1>
                    <HairDresser.Profil.Form />
                </div>
            ) : (
                <div>
                    <HairDresser.Profil.Accueil goToSettings={() => setEdit(true)} />
                </div>
            )}

            <div className="grid justify-center mt-10">
                <Button.Red iconLeft={<Icon.Logout className="w-6" />} onClick={() => handleLogout()}>
                    Déconnexion
                </Button.Red>
                <div className="mx-auto mt-10" onClick={() => setDeleteAccount(true)}>
                    Supprimer le compte
                </div>
            </div>
            <Modal.default
                title="Veux-tu vraiment supprimer ton compte ?"
                isOpen={deleteAccount}
                setIsOpen={() => setDeleteAccount(false)}
                confirmButton={<Button.Red onClick={() => handleDelete()}>Supprimer</Button.Red>}
                backButton={
                    <div className="mr-2">
                        <Button.Secondary onClick={() => setDeleteAccount(false)}>Annuler</Button.Secondary>
                    </div>
                }
            >
                <Text.Paragraph.Normal>
                    Cette action est irréversible et toutes les données seront supprimées définitivement, nous ne
                    pourrons pas les restaurer.
                </Text.Paragraph.Normal>
            </Modal.default>
        </>
    )
}

export default Profil
