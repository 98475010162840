import React, { useEffect, useState } from 'react'
import { Text, Icon, Button } from '../atoms'
import { Link } from '@nextui-org/react'

import placeholder from '../../assets/icons/Image.svg'

export const Profil = ({ srcImage, className, title, subTitle, icon }) => {
    return (
        <div className={`flex gap-x-4 w-full h-16 items-center ${className ? className : ''}`}>
            <div
                className="rounded-full w-[60px] h-[60px] bg-light-gray border border-black border-opacity-10"
                style={{
                    backgroundImage: `url(${srcImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                }}
            ></div>
            <div className="flex flex-col">
                <Text.Paragraph.Normal>{title}</Text.Paragraph.Normal>
                <Text.Paragraph.Small>
                    <p
                        style={{
                            width: '55vw',
                            height: '20px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {subTitle}
                    </p>
                </Text.Paragraph.Small>
            </div>
            {icon ? icon : ''}
        </div>
    )
}

export const LargeProfil = ({ srcImage, firstName, lastName, icon }) => {
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-4">
                <div
                    className="rounded-full w-[120px] h-[120px] bg-light-gray border border-black border-opacity-10"
                    style={{
                        backgroundImage: `url(${srcImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                    }}
                ></div>
                <div>
                    <Text.Paragraph.Normal className="text-xl font-bold">{firstName}</Text.Paragraph.Normal>
                    <Text.Paragraph.Normal className="text-xl font-bold">{lastName}</Text.Paragraph.Normal>
                </div>
            </div>
            {icon ? icon : ''}
        </div>
    )
}

export const Favorite = ({ srcImage, className, title, handleDelete, handleSelect }) => {
    return (
        <div className={`grid grid-cols-12 gap-x-4 w-full h-16 items-center ${className ? className : ''}`}>
            <div
                className="flex items-center col-span-9 space-x-4"
                onClick={() => {
                    handleSelect()
                }}
            >
                <div
                    className="rounded-full w-[80px] h-[80px] bg-light-gray border border-black border-opacity-10"
                    style={{
                        backgroundImage: `url(${srcImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                    }}
                ></div>
                <div className="flex flex-col">
                    <Text.Paragraph.Normal className="text-[24px] font-bold">{title}</Text.Paragraph.Normal>
                </div>
            </div>
            <div
                className="col-span-3"
                onClick={() => {
                    handleDelete()
                }}
            >
                <Icon.Heart className="w-9 text-purple" />
            </div>
        </div>
    )
}

export const HairdresserPresentation = ({
    className,
    srcImage,
    title,
    xp,
    facebook,
    instagram,
    linkedin,
    pinterest,
    google
}) => {
    return (
        <section className={`flex flex-col gap-y-4 mb-4 ${className ? className : ''}`}>
            <div className="flex items-center flex-nowrap max-[350px]:space-x-2 space-x-7">
                <div
                    className="rounded-full w-[100px] h-[100px] bg-light-gray border border-black border-opacity-10"
                    style={{
                        backgroundImage: `url(${srcImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                    }}
                ></div>
                <div className="grid">
                    <Text.h2 className="text-xl">{title}</Text.h2>
                    <Text.Paragraph.Normal>{xp}</Text.Paragraph.Normal>
                    <div className="flex m-1 space-x-2">
                        {facebook && (
                            <Link target="_blank" href={facebook}>
                                <Icon.Facebook className="w-8" />
                            </Link>
                        )}
                        {instagram && (
                            <Link target="_blank" href={instagram}>
                                <Icon.Instagram className="w-8" />
                            </Link>
                        )}
                        {linkedin && (
                            <Link target="_blank" href={linkedin}>
                                <Icon.Linkedin className="w-8 h-8" />
                            </Link>
                        )}
                        {pinterest && (
                            <Link target="_blank" href={pinterest}>
                                <Icon.Pinterest className="w-8 h-8" />
                            </Link>
                        )}
                        {google && (
                            <Link target="_blank" href={google}>
                                <Icon.Google className="w-8" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export const ProfilStars = ({ srcImage, alt, classNameDiv, classNameSection, title, note, profession }) => {
    return (
        <section className={`${classNameSection ? classNameSection : ''}`}>
            {profession ? <Text.Paragraph.Normal className={`my-3`}>{profession}</Text.Paragraph.Normal> : ''}
            <div className={`flex items-center gap-x-8 ${classNameDiv ? classNameDiv : ''}`}>
                <img src={srcImage} alt={alt} />
                <div>
                    <Text.Paragraph.Normal className="font-bold text-10 ">{title}</Text.Paragraph.Normal>
                    <div className="flex items-center gap-x-2 ">
                        <div className="flex">
                            <Icon.Star className="w-24 h-auto text-yellow" />
                            <Icon.Star className="text-yellow" />
                            <Icon.Star className="text-yellow" />
                            <Icon.Star className="text-yellow" />
                            <Icon.Star className="text-yellow" />
                        </div>
                        {note}/5
                    </div>
                </div>
            </div>
        </section>
    )
}

export const BookingEvent = ({
    start,
    prestations,
    client,
    address,
    isCurrent,
    lastIndex,
    deleteBooking,
    updateBooking,
    selectBooking,
    addNote,
    indisponible,
    hoursBreakStart,
    hoursBreakEnd,
}) => {
    const [active, setActive] = useState(false)

    useEffect(() => {
        if (isCurrent) {
            setActive(true)
        }
    }, [])

    const prestationsDisplay = prestations
        .reduce((acc, curr) => {
            const existing = acc.find((item) => item.name === curr.name)
            if (existing) {
                existing.count = (existing.count || 1) + 1
            } else {
                acc.push({ ...curr, count: 1 })
            }
            return acc
        }, [])
        .map((prestation) => `${prestation.name}${prestation.count > 1 ? ` x${prestation.count}` : ''}`)
        .join(', ')

    return (
        <section
            className={`flex space-x-5 justify-between ${
                active ? 'bg-white border border-gray-300 py-3 px-2' : 'px-2 py-3'
            } ${indisponible ? 'bg-gray-100 rounded-lg' : ''}`}
        >
            <div className="relative" onClick={() => selectBooking()}>
                <div className="flex space-x-2">
                    <Text.Paragraph.Normal>{start}</Text.Paragraph.Normal>
                    <span className={`w-5 h-5 rounded-full z-[12] ${isCurrent ? 'bg-blue' : 'bg-[#D9D9D9]'}`}></span>
                </div>
                {lastIndex && <span className="absolute left-[60px] w-[20px] h-[93%] bg-white z-[13]"></span>}
            </div>
            <div className="grid gap-3">
                <div className="grid gap-1" onClick={() => selectBooking()}>
                    {indisponible ? (
                        <Text.Paragraph.Normal>Indiponibilité jusqu'à {hoursBreakEnd}</Text.Paragraph.Normal>
                    ) : (
                        <>
                            <Text.Paragraph.Normal className="text-xl font-medium truncate">
                                {prestationsDisplay}
                            </Text.Paragraph.Normal>
                            <Text.Paragraph.Normal>
                                {client?._profil.firstName} {client?._profil?.lastName}
                                {indisponible ? indisponible : ''}
                            </Text.Paragraph.Normal>
                            <Text.Paragraph.Normal>
                                {address?.formatted}
                                {hoursBreakStart && hoursBreakEnd ? `${hoursBreakStart} - ${hoursBreakEnd}` : ''}
                            </Text.Paragraph.Normal>
                        </>
                    )}
                </div>
                {active && indisponible ? (
                    <div className="flex space-x-3">
                        <div onClick={() => deleteBooking()}>
                            <Icon.CrossSquare className="w-9" />
                        </div>
                    </div>
                ) : active ? (
                    <div className="flex max-[350px]:space-x-2 max-[500px]:space-x-3 space-x-5">
                        <div onClick={() => deleteBooking()}>
                            <Icon.CrossSquare className="w-9" />
                        </div>
                        <div onClick={() => updateBooking()}>
                            <Icon.Clock className="w-9" />
                        </div>
                        <Link
                            href={`https://www.waze.com/ul?ll=${address?.lat}%2C${address?.lon}&navigate=yes`}
                            target="_blank"
                        >
                            <Icon.Map className="w-9" />
                        </Link>
                        <div onClick={() => addNote()}>
                            <Icon.Message className="w-9" />
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div onClick={() => setActive(!active)}>
                <Icon.ArrowBack className={`w-8 ${active ? 'rotate-[90deg]' : 'rotate-[-90deg]'}`} />
            </div>
        </section>
    )
}

export const PrestationClassique = ({
    srcImage,
    className,
    title,
    subTitle,
    badge,
    onClick,
    quantity,
    changePrestation,
}) => {
    return (
        <div className={`grid grid-cols-12 gap-2 w-full min-h-16 h-fit items-center ${className ? className : ''}`}>
            <div onClick={() => onClick()} className="grid grid-cols-9 col-span-9">
                <div
                    className="rounded-full col-span-2 w-[60px] h-[60px] bg-light-gray border border-black border-opacity-10"
                    style={{
                        backgroundImage: `url(${srcImage || placeholder})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'contain',
                    }}
                ></div>
                <div className="flex col-span-7 pl-5 space-x-2">
                    <div className="flex flex-col">
                        <Text.Paragraph.Normal>{title}</Text.Paragraph.Normal>
                        <Text.Paragraph.Small>
                            <p>{subTitle}</p>
                        </Text.Paragraph.Small>
                    </div>
                </div>
            </div>

            <div
                className={`grid items-center grid-cols-3 col-span-3 gap-1 px-3 py-2 text-center border border-black rounded-full ${
                    badge ? 'bg-purple' : ''
                }`}
            >
                <div
                    onClick={() => {
                        changePrestation(quantity > 1 ? quantity - 1 : 0)
                    }}
                >
                    <Icon.Less className="w-4" />
                </div>
                <Text.Paragraph.Normal>{quantity || 0}</Text.Paragraph.Normal>
                <div
                    onClick={() => {
                        changePrestation(quantity + 1)
                    }}
                >
                    <Icon.Add className="w-4" />
                </div>
            </div>
        </div>
    )
}

export const PrestationRecap = ({ srcImage, className, title, subTitle, quantity, changePrestation }) => {
    return (
        <div className={`flex w-full mint-h-16 h-fit items-center space-x-1 ${className ? className : ''}`}>
            <div
                className="rounded-full col-span-1 w-[40px] h-[40px] bg-light-gray border border-black border-opacity-10"
                style={{
                    backgroundImage: `url(${srcImage || placeholder})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                }}
            ></div>
            <div className="flex w-[60vw] pl-5 space-x-2">
                <div className="flex flex-col">
                    <Text.Paragraph.Normal>{title}</Text.Paragraph.Normal>
                    <Text.Paragraph.Small>
                        <p>{subTitle}</p>
                    </Text.Paragraph.Small>
                </div>
            </div>

            <div className="grid items-center grid-cols-3 col-span-3 gap-1 px-3 py-2 ml-auto text-center border border-black rounded-full">
                <div
                    onClick={() => {
                        changePrestation(quantity > 1 ? quantity - 1 : 0)
                    }}
                >
                    <Icon.Less className="w-4" />
                </div>
                <Text.Paragraph.Normal>{quantity || 0}</Text.Paragraph.Normal>
                <div
                    onClick={() => {
                        changePrestation(quantity + 1)
                    }}
                >
                    <Icon.Add className="w-4" />
                </div>
            </div>
        </div>
    )
}
export const PrestationRecapConfirmed = ({ srcImage, className, title, subTitle, text, infoRdv }) => {
    return (
        <div className={`flex flex-col items-center gap-y-4${className ? className : ''}`}>
            <div
                className="rounded-full col-span-2 w-[60px] h-[60px] bg-light-gray border border-black border-opacity-10"
                style={{
                    backgroundImage: `url(${srcImage || placeholder})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                }}
            ></div>
            <Text.Paragraph.Normal className={`text-center`}>{title}</Text.Paragraph.Normal>
            <div className="flex col-span-9 pl-5 space-x-2">
                <div className="flex flex-col items-center gap-y-4">
                    <Text.Paragraph.Normal>{subTitle}</Text.Paragraph.Normal>
                    <Text.Paragraph.Small className={``}>{infoRdv}</Text.Paragraph.Small>
                    <Text.Paragraph.Small className={`text-center`}>{text}</Text.Paragraph.Small>
                </div>
            </div>
        </div>
    )
}

export const WaitingEvent = ({
    start,
    prestations,
    client,
    address,
    price,
    deleteBooking,
    updateBooking,
    acceptBooking,
    selectBooking,
}) => {
    const countMap = new Map()
    prestations.forEach((prestation) => {
        countMap.set(prestation.name, (countMap.get(prestation.name) || 0) + 1)
    })

    return (
        <section className={`flex space-x-5 px-2 py-3`}>
            <div className="relative" onClick={() => selectBooking()}>
                <div className="flex space-x-2">
                    <Text.Paragraph.Normal>{start}</Text.Paragraph.Normal>
                    <span className={`w-5 h-5 rounded-full z-[12] bg-blue`}></span>
                </div>
            </div>
            <div className="grid gap-3">
                <div className="grid gap-2" onClick={() => selectBooking()}>
                    <Text.Paragraph.Normal className="text-xl font-medium truncate">
                        {[...countMap.keys()]
                            .map((name) => {
                                const count = countMap.get(name)
                                return count > 1 ? `${name} x${count}` : name
                            })
                            .join(', ')}
                    </Text.Paragraph.Normal>
                    <Text.Paragraph.Normal>
                        {client?._profil.firstName} {client?._profil?.lastName}
                    </Text.Paragraph.Normal>
                    <Text.Paragraph.Normal>{address?.formatted}</Text.Paragraph.Normal>
                    <Text.Paragraph.Normal>{price}€</Text.Paragraph.Normal>
                </div>
                <div className="flex space-x-5">
                    <div onClick={() => deleteBooking()}>
                        <Icon.CrossSquare className="w-9" />
                    </div>
                    <div onClick={() => updateBooking()}>
                        <Icon.Clock className="w-9" />
                    </div>
                    <div onClick={() => acceptBooking()}>
                        <Icon.CheckSquare className="w-9" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export const CancelEvent = ({ start, prestations, client, address, price, selectBooking, sayOkay }) => {
    return (
        <section className={`flex space-x-5 px-2 py-3 rounded-md`}>
            <div className="relative">
                <div className="flex space-x-2">
                    <Text.Paragraph.Normal>{start}</Text.Paragraph.Normal>
                    <span className={`w-5 h-5 rounded-full z-[12] bg-red-200`}></span>
                </div>
                <div className="mt-2 text-center bg-red-200 rounded">Annulé</div>
            </div>
            <div className="grid gap-1">
                <div className="grid" onClick={() => selectBooking()}>
                    <Text.Paragraph.Normal className="text-xl font-medium truncate">
                        {prestations.map((prestation, index) =>
                            index !== prestations.length - 1 ? `${prestation.name},` : prestation.name
                        )}
                    </Text.Paragraph.Normal>
                    <Text.Paragraph.Small>
                        {client?._profil.firstName} {client?._profil?.lastName}
                    </Text.Paragraph.Small>
                    <Text.Paragraph.Small>{address?.formatted}</Text.Paragraph.Small>
                    <Text.Paragraph.Small>{price}€</Text.Paragraph.Small>
                </div>
                <div className="flex w-full space-x-1 overflow-hidden flex-nowrap">
                    <a
                        className="px-1 py-3 bg-white border border-black rounded-full"
                        href={`tel:${
                            client?._profil?.phoneNumber?.phone.length === 10
                                ? client?._profil?.phoneNumber?.phone
                                : `0${client?._profil?.phoneNumber?.phone}`
                        }`}
                    >
                        Appeler le client
                    </a>
                    <Button.Primary onClick={() => sayOkay()}>OK</Button.Primary>
                </div>
            </div>
        </section>
    )
}

export const PrestationComing = ({ srcImage, className, title, text, infoRdv, rebook, bookingState }) => {
    return (
        <div className={`flex justify-around gap-x-5 m-4 items-center ${className ? className : ''}`}>
            <div
                className="rounded-full col-span-2 w-[100px] h-[60px] bg-light-gray border border-black border-opacity-10"
                style={{
                    backgroundImage: `url(${srcImage || placeholder})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                }}
            ></div>
            <div className="flex w-full col-span-9 pl-5 space-x-2">
                <div className="flex flex-col gap-y-1">
                    <Text.Paragraph.Normal className={` font-bold`}>{title}</Text.Paragraph.Normal>
                    <Text.Paragraph.Small className={``}>{infoRdv}</Text.Paragraph.Small>
                    <Text.Paragraph.Small className={``}>{text}</Text.Paragraph.Small>
                    {!bookingState ? (
                        <></>
                    ) : (
                        <div>
                            <span
                                className={` text-xs  text-center px-3 py-1 rounded-full
                                    ${
                                        bookingState === 'cancel'
                                            ? 'bg-red-400'
                                            : bookingState === 'waiting'
                                            ? 'bg-yellow'
                                            : bookingState === 'booked'
                                            ? 'bg-[#5CFC4F]'
                                            : bookingState === 'done'
                                            ? 'bg-[#5CFC4F]'
                                            : ''
                                    }
                                `}
                            >
                                {bookingState === 'cancel'
                                    ? 'Annulée'
                                    : bookingState === 'waiting'
                                    ? 'En attente'
                                    : bookingState === 'booked'
                                    ? 'Confirmée'
                                    : bookingState === 'done'
                                    ? 'Effectuée'
                                    : ''}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div onClick={rebook}>
                <Icon.Reload className="w-[35px] h-[35px]" />
            </div>
        </div>
    )
}
