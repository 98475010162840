import React, { useState } from 'react'

const Defaut = ({ children, text }) => {
    const [show, setShow] = useState(true)

    return (
        <div>
            <div onClick={() => setShow(!show)}>{children}</div>
            {show && (
                <div
                    onClick={() => setShow(false)}
                    className="absolute left-1 right-1 bg-white p-4 shadow-lg rounded-lg z-[30] border-[0.1px] border-black"
                >
                    {text}
                </div>
            )}
        </div>
    )
}

export default Defaut
