import { setSessionStorageValue, getSessionStorageValue } from '../../storage'

export const storeValue = (firstName, lastName, avatar, address, phoneNumber, _photos, _favorites) => {
    setSessionStorageValue('firstName', firstName)
    setSessionStorageValue('lastName', lastName)
    setSessionStorageValue('avatar', JSON.stringify(avatar))
    setSessionStorageValue('address', JSON.stringify(address))
    setSessionStorageValue('phoneNumber', JSON.stringify(phoneNumber))
    setSessionStorageValue('_photos', JSON.stringify(_photos))
    setSessionStorageValue('_favorites', JSON.stringify(_favorites))
}

export const getSotredValue = () => {
    const firstName = getSessionStorageValue('firstName')
    const lastName = getSessionStorageValue('lastName')
    const address = JSON.parse(getSessionStorageValue('address'))
    const phoneNumber = JSON.parse(getSessionStorageValue('phoneNumber'))
    const _photos = JSON.parse(getSessionStorageValue('_photos'))
    const _favorites = JSON.parse(getSessionStorageValue('_favorites'))

    return {
        firstName: firstName,
        lastName: lastName,
        address: address,
        phoneNumber: phoneNumber,
        _photos: _photos,
        _favorites: _favorites,
    }
}
