import React from 'react'

const ToggleTab = ({ icons, onChange, active }) => {
    return (
        <div className="flex p-1 space-x-1 border border-black rounded-full">
            {icons.map((value) => {
                return (
                    <div
                        key={value.key}
                        onClick={() => onChange(value.key)}
                        className={`p-1 ${value.key === active ? 'rounded-full bg-green text-black' : 'text-black'}`}
                    >
                        {value.icon}
                    </div>
                )
            })}
        </div>
    )
}

export { ToggleTab }
