import React from 'react'
import { Text } from '../atoms'

export const TitleSection = ({ children, textTitle, icon, className }) => {
    return (
        <section>
            <Text.Clickable className={`flex justify-between items-center my-4 ${className ? className : ''}`}>
                <span>{textTitle}</span> {icon}
            </Text.Clickable>
            {children}
        </section>
    )
}
