'use client'

import React, { useState } from 'react'

import { Button, Icon, Input, Modal, Text } from '../../components/atoms'
import { Return } from '../../components/molecules'

import { Auth } from '../../contexts'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const navigate = useNavigate()
    const { handleLoginClassic, getNewPassword, type } = Auth.useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [forgotPassword, setForgotPassword] = useState(false)
    const [success, setSuccess] = useState(false)

    const [message, setMessage] = useState()

    return (
        <div className="grid gap-20">
            <Return onClick={() => navigate('/')} />
            <div className="grid gap-4 text-center">
                <Text.h1>Connexion</Text.h1>
                <div>
                    <Text.Paragraph.Normal>Vous n'avez pas encore de compte ?</Text.Paragraph.Normal>
                    <Text.Link href="/signup">Inscription</Text.Link>
                </div>
            </div>
            <div className="grid gap-8">
                <div className="grid gap-5">
                    <Input.Text.Classic type="email" value={email} setValue={setEmail} labelValue="Email" />
                    <Input.Text.Password value={password} setValue={setPassword} labelValue="Mot de passe" />
                </div>
                <div className="grid gap-5 text-center">
                    <div className="mx-auto">
                        <Button.Primary
                            onClick={async () => {
                                await handleLoginClassic(email, password)
                                if (type === 'client') {
                                    navigate('/client/favorites')
                                }
                            }}
                        >
                            Connexion
                        </Button.Primary>
                    </div>
                    <div onClick={() => setForgotPassword(true)}>
                        <Text.Link>Mot de passe oublié</Text.Link>
                    </div>
                </div>
            </div>
            <Modal.default
                title="Mot de passe oublié ?"
                isOpen={forgotPassword}
                setIsOpen={() => setForgotPassword(false)}
                backButton={
                    <Button.Secondary className="mx-auto" onClick={() => setForgotPassword(false)}>
                        Annuler
                    </Button.Secondary>
                }
                confirmButton={
                    <Button.Primary
                        iconRight={<Icon.ArrowNext className="w-5" />}
                        onClick={async () => {
                            if (email) {
                                const response = await getNewPassword(email)
                                if (response?.data?.success) {
                                    setSuccess(true)
                                    setForgotPassword(false)
                                } else {
                                    setMessage(response?.data?.message || true)
                                }
                            }
                        }}
                    >
                        Envoyer
                    </Button.Primary>
                }
            >
                <Text.Paragraph.Normal className="text-center">
                    Entrez votre adresse email et nous vous enverrons un nouveau mot de passe.
                </Text.Paragraph.Normal>
                <Input.Text.Classic value={email} setValue={(value) => setEmail(value)} labelValue="Email" />
                {message && (
                    <p className="text-red-500">
                        Aucun utilisateur trouvé avec cet email, veuillez réeesayer avec un autre email
                    </p>
                )}
            </Modal.default>
            <Modal.default
                title="Mot de passe réinitialisé envoyé"
                isOpen={success}
                setIsOpen={() => setSuccess(false)}
                backButton={<Button.Secondary onClick={() => setSuccess(false)}>Retour</Button.Secondary>}
            >
                <Text.Paragraph.Normal className="text-center">
                    Veuillez vous rendre sur votre messagerie électronique et utiliser le mot de passe que nous vous
                    avons envoyé pour vous connecter.
                </Text.Paragraph.Normal>
            </Modal.default>
        </div>
    )
}

export default Login
