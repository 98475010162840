/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'

import { getSotredValue, storeValue } from './utils'

import Girl from '../../../assets/avatar/Girl.svg'

const ProfilContext = createContext({
    firstName: String,
    lastName: String,
    address: Object,
    phoneNumber: Object,
    avatar: Object,
    photos: Array,
    favorites: Array,
    handleSetAttribute: (attribute, value) => {},
    handleSaveAttribute: async (attribute, value) => {
        return Promise.resolve
    },
    getProfil: async () => {},
})

export function ProfilContextProvider({ children }) {
    const { handleRequest } = useRequest()
    const { id, token, isLogged } = useAuth()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState({
        formatted: '',
    })
    const [phoneNumber, setPhoneNumber] = useState({
        code: '+33',
        short: 'FR',
        phone: null,
    })
    const [avatar, setAvatar] = useState({
        thumbnailUrl: Girl,
    })
    const [photos, setPhotos] = useState([])
    const [favorites, setFavorites] = useState([])

    const handleSetAttribute = (attribute, value) => {
        switch (attribute) {
            case 'firstName':
                setFirstName(value)
                break
            case 'lastName':
                setLastName(value)
                break
            case 'address':
                setAddress(value)
                break
            case 'phoneNumber':
                setPhoneNumber(value)
                break
            case 'avatar':
                setAvatar(value)
                break
            case 'photos':
                setPhotos(value)
                break
            case 'favorites':
                setFavorites(value)
        }
    }

    const handleSaveAttribute = async (attribute, value) => {
        await handleRequest('put', `profil/${attribute}`, { [attribute]: value }, token)

        handleSetAttribute(attribute, value)
        return
    }

    const getProfil = async () => {
        const response = await handleRequest('get', `profil`, null, token)

        if (response) {
            if (!response?.error) {
                const profil = response.data.profil
                setFirstName(profil.firstName)
                setLastName(profil.lastName)
                setAvatar(profil.avatar)
                setAddress(profil.address)
                setPhoneNumber(profil.phoneNumber)
                setPhotos(profil._photos)
                setFavorites(profil._favorites)

                storeValue(
                    profil.firstName,
                    profil.lastName,
                    profil.avatar,
                    profil.address,
                    profil.phoneNumber,
                    profil._photos,
                    profil._favorites
                )
            }
        }
    }

    useEffect(() => {
        if (isLogged()) {
            if (window.location.pathname.indexOf('/client') !== -1) {
                getProfil()
            }
        }
    }, [token, id])

    useEffect(() => {
        const storedValue = getSotredValue()

        if (storeValue) {
            setFirstName(storedValue.firstName)
            setLastName(storedValue.lastName)
            setAvatar(storedValue.avatar)
            setAddress(storedValue.address)
            setPhoneNumber(storedValue.phoneNumber)
            setPhotos(storedValue._photos)
            setFavorites(storedValue._favorites)
        }
    }, [])

    return (
        <ProfilContext.Provider
            value={{
                firstName: firstName,
                lastName: lastName,
                address: address,
                phoneNumber: phoneNumber,
                avatar: avatar,
                photos: photos,
                favorites: favorites,
                handleSaveAttribute: handleSaveAttribute,
                handleSetAttribute: handleSetAttribute,
                getProfil: getProfil,
            }}
        >
            {children}
        </ProfilContext.Provider>
    )
}

export const useProfil = () => useContext(ProfilContext)
