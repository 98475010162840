import React from 'react'
import { Input, Text, Icon } from '../../atoms'
import { Card } from '../../molecules'
import { Client } from '../../../contexts'
import Girl from '../../../assets/avatar/Girl.svg'

export const Research = () => {
    const { loading, coiffeurs, address, categories, setCategories, selectCoiffeur } = Client.useCoiffeur()

    return (
        <div className="grid w-full gap-10">
            <Text.h1 className="text-center">Trouver un professionnel de la beauté près de chez vous</Text.h1>
            <div className="mx-auto w-[90vw]">
                <Input.Searchbar className="justify-center w-full" />
                {address?.formatted !== '' && (
                    <div className="grid gap-2 mt-2 px-2">
                        <Text.Paragraph.Normal>Que recherchez vous ?</Text.Paragraph.Normal>
                        <div className="flex space-x-6">
                            <div className={`flex ${categories?.indexOf('coiffure') === -1 ? 'opacity-30' : ''}`}>
                                <Input.Checkbox
                                    initialValue={categories?.indexOf('coiffure') !== -1}
                                    setValue={() => {
                                        if (categories?.indexOf('coiffure') === -1) {
                                            setCategories([...categories, 'coiffure'])
                                        } else {
                                            if (categories?.indexOf('esthetique') === -1) setCategories([])
                                            else setCategories(['esthetique'])
                                        }
                                    }}
                                />
                                <span className="ml-2">Coiffure</span>
                            </div>
                            <div className={`flex ${categories?.indexOf('esthetique') === -1 ? 'opacity-30' : ''}`}>
                                <Input.Checkbox
                                    initialValue={categories?.indexOf('esthetique') !== -1}
                                    setValue={() => {
                                        if (categories?.indexOf('esthetique') === -1) {
                                            setCategories([...categories, 'esthetique'])
                                        } else {
                                            if (categories?.indexOf('coiffure') === -1) setCategories([])
                                            else setCategories(['coiffure'])
                                        }
                                    }}
                                />
                                <span className="ml-2">Esthétique</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {address.formatted !== '' && (
                <div className="flex flex-col w-full mt-4 space-y-4">
                    {loading ? (
                        <div className="grid w-full py-10">
                            <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
                        </div>
                    ) : (
                        <div className="grid justify-center w-full gap-5">
                            {coiffeurs.length === 0 && 'Aucun coiffeur dans votre région'}
                            {coiffeurs.map((coiffeur) => (
                                <div key={coiffeur._id} onClick={() => selectCoiffeur(coiffeur)}>
                                    <Card.Profil
                                        srcImage={`${
                                            coiffeur._profil.avatar ? coiffeur._profil.avatar?.thumbnailUrl : Girl
                                        }`}
                                        alt={`Avatar Girl`}
                                        title={`${coiffeur._profil.firstName} ${coiffeur._profil.lastName}`}
                                        subTitle={coiffeur._profil._specialities?.map(
                                            (speciality, index) =>
                                                `${speciality.name} ${
                                                    index !== coiffeur._profil._specialities.length - 1 && ','
                                                }`
                                        )}
                                        icon={<Icon.ArrowNext className={`h-10 w-10`} />}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
