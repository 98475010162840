import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'
import { useAgenda } from '../agenda'
import { useClients } from '../clients'

import dayjs from 'dayjs'

/* eslint no-unused-vars: 0 */
const BookingContext = createContext({
    bookingSelected: Object,
    method: String,
    note: String,
    showMenu: Boolean,
    handleBookingSelected: (method, booking) => {
        // method : 'delete', 'update'
        // booking: Obkject details
    },
    deleteBooking: async (reason) => {
        return Promise.resolve()
    },
    readBooking: async (bookingId) => {
        return Promise.resolve()
    },
    editBooking: async (newDate, newTimes, reason) => {
        return Promise.resolve()
    },
    createPrivate: async (date, from, to, dayEnd) => {
        return Promise.resolve()
    },
    acceptBooking: async (date) => {
        return Promise.resolve()
    },
    creatBooking: async (date, prestations, client, from, to, duration, price, note, priceVariable) => {
        return Promise.resolve()
    },
    getAlgorithmResult: async (address, duration) => {},
    addNote: (clientId, bookingId, note) => {
        return Promise.resolve()
    },
    setShowMenu: (value) => {},
})

export function BookingContextProvider({ children }) {
    const { handleRequest, setMessage } = useRequest()
    const { id, token, isLogged } = useAuth()
    const { data, updateLoadedDay, fetchWaitings, handleDate } = useAgenda()
    const { clientSelected, getClientById, setClientSelected } = useClients()

    const [bookingSelected, setBookingSelected] = useState()
    const [method, setMethod] = useState()

    const [showMenu, setShowMenu] = useState(false)

    const handleBookingSelected = (method, booking) => {
        setMethod(method)
        setBookingSelected(booking)
    }

    const deleteBooking = async (reason) => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingSelected._id}`,
                {
                    state: 'cancel',
                    reason: reason,
                    date: bookingSelected.date,
                },
                token
            )

            if (response?.data) {
                if (clientSelected) {
                    const client = getClientById(clientSelected?._id)
                    setClientSelected(client)
                }

                fetchWaitings()
            }

            return
        } else console.log('non connecté')
    }

    const readBooking = async (bookingId) => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingId}`,
                { state: 'read' },
                token
            )

            if (response.data) {
                if (clientSelected) {
                    const client = getClientById(clientSelected?._id)
                    setClientSelected(client)
                }
                fetchWaitings()
            }

            return
        }
    }

    const editBooking = async (newDate, newTimes, reason) => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingSelected._id}`,
                {
                    state: 'move',
                    reason: reason,
                    date: bookingSelected.date,
                    newDate: newDate,
                    newTimes: newTimes,
                },
                token
            )

            if (response?.data) {
                updateLoadedDay(response.data.newDay.date, response.data.newDay.bookings)
                updateLoadedDay(response.data.previousDay.date, response.data.previousDay.bookings)
                fetchWaitings()
            }

            return
        }
    }

    const acceptBooking = async () => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingSelected._id}`,
                {
                    state: 'booked',
                    date: bookingSelected.date,
                },
                token
            )

            if (response?.data) {
                // updateLoadedDay(response.data.day.date, response.data.day.bookings)
                fetchWaitings()
                handleDate(response.data.day.date)
            }

            return
        } else console.log('non connecté')
    }

    const createPrivate = async (date, from, to, dayEnd, allDay) => {
        if (isLogged()) {
            await handleRequest(
                'post',
                `coiffeur/bookings/${id}/private`,
                {
                    date: date,
                    from: from,
                    to: to,
                    dateEnd: dayEnd,
                    allDay: allDay,
                },
                token
            )
        } else console.log('non connecté')
    }

    const creatBooking = async (date, prestations, client, from, to, duration, price, note, priceVariable) => {
        if (isLogged()) {
            await handleRequest(
                'post',
                `coiffeur/bookings/${id}`,
                {
                    date: date,
                    client: client,
                    prestation: prestations,
                    from: from,
                    to: to,
                    duration: duration,
                    price: price,
                    note: note,
                    priceVariable: priceVariable,
                },
                token
            )
        }

        return
    }

    const getAlgorithmResult = async (address, duration) => {
        if (isLogged()) {
            if (address) {
                const response = await handleRequest(
                    'post',
                    `coiffeur/algorithme`,
                    {
                        coiffeurId: id,
                        clientLocation: [address?.lon, address?.lat],
                        duration: duration,
                    },
                    token,
                    null,
                    true
                )

                if (response?.data) {
                    return response.data.bookables
                }
            } else {
                setMessage({ type: 'info', message: 'Aucune adresse pour le client sélectionné.' })
            }

            return
        } else console.log('non connecté')
    }

    const addNote = async (clientId, bookingId, note) => {
        if (isLogged()) {
            updateLoadedDay(bookingSelected.date, { ...data.loadedDays[bookingSelected.date], note: note })

            const response = await handleRequest(
                'put',
                `booking/${id}/${clientId}/${bookingId}/note`,
                {
                    note: {
                        value: note,
                        date: dayjs(bookingSelected.date).toISOString(),
                    },
                },
                token
            )

            if (response.data) {
                if (clientSelected) {
                    const client = getClientById(clientId)
                    setClientSelected(client)
                    handleDate(bookingSelected.date, response.data.bookings)
                }
            }

            updateLoadedDay(bookingSelected.date, response.data.day)

            return response?.data?.bookings
        } else console.log('non connecté')
    }

    return (
        <BookingContext.Provider
            value={{
                bookingSelected: bookingSelected,
                method: method,
                setMethod: setMethod,
                showMenu: showMenu,
                handleBookingSelected: handleBookingSelected,
                deleteBooking: deleteBooking,
                editBooking: editBooking,
                createPrivate: createPrivate,
                acceptBooking: acceptBooking,
                getAlgorithmResult: getAlgorithmResult,
                creatBooking: creatBooking,
                readBooking: readBooking,
                addNote: addNote,
                setShowMenu: setShowMenu,
            }}
        >
            {children}{' '}
        </BookingContext.Provider>
    )
}

export const useBooking = () => useContext(BookingContext)
