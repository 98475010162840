import React from 'react'
import { FavoriteHairdresser } from '../../components/organisms'

const Favoris = () => {
    return (
        <>
            <FavoriteHairdresser.Header />
            <FavoriteHairdresser.Main />
        </>
    )
}

export default Favoris
