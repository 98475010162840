import React, { useState, useEffect } from 'react'
import { Button, Icon, Input, Modal, Text } from '../../../atoms'
import { Hairdresser, Request } from '../../../../contexts'
import { Return } from '../../../molecules'
import dayjs from 'dayjs'
import { formatSecondsToHHhmm } from '../../../../utils'

import Boy from '../../../../assets/avatar/Boy.svg'
import Girl from '../../../../assets/avatar/Girl.svg'
import { useLocation, useNavigate } from 'react-router'

const AddNote = () => {
    const { method, handleBookingSelected, bookingSelected, addNote } = Hairdresser.useBooking()
    const { clientSelected } = Hairdresser.useClients()
    const [note, setNote] = useState('')

    useEffect(() => {
        if (bookingSelected && bookingSelected.note?.length > 0) {
            let noteString = ''
            for (let i = 0; i < bookingSelected.note.length; i++) {
                noteString += bookingSelected.note[i].value + ' '
                setNote(noteString)
            }
        } else {
            setNote('')
        }
    }, [bookingSelected])

    return (
        <Modal.default
            title={'Ajouter un commentaire'}
            isOpen={method === 'note'}
            setIsOpen={() => {
                handleBookingSelected(null, null)
            }}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="h-8" />}
                    onClick={async () => {
                        await addNote(clientSelected?._id, bookingSelected?._id, note)
                        handleBookingSelected(null, null)
                    }}
                >
                    Ajouter
                </Button.Primary>
            }
        >
            <Input.Text.TextArea
                placeholder={`Votre commentaire privé`}
                value={note}
                setValue={(value) => setNote(value)}
            />
        </Modal.default>
    )
}

const Delete = () => {
    const { clientSelected, method, setMethod, deleteClient } = Hairdresser.useClients()
    return (
        <Modal.default
            title={'Supprimer le client ?'}
            isOpen={method === 'delete'}
            setIsOpen={() => {
                setMethod()
            }}
            backButton={
                <Button.Secondary
                    onClick={() => {
                        setMethod()
                    }}
                >
                    Annuler
                </Button.Secondary>
            }
            confirmButton={
                <Button.Red
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={async () => {
                        deleteClient(clientSelected._id)
                    }}
                >
                    Confirmer
                </Button.Red>
            }
        ></Modal.default>
    )
}

const Edit = () => {
    const { clientSelected, setMethod, editClient, updateEmail } = Hairdresser.useClients()

    const [address, setAddress] = useState(clientSelected?._user._profil?.address)
    const [phoneNumber, setPhoneNumber] = useState(clientSelected?._user._profil?.phoneNumber?.phone)
    const [email, setEmail] = useState('')

    useEffect(() => {
        if (clientSelected) {
            setAddress(clientSelected?._user._profil?.address)
            setPhoneNumber(clientSelected?._user._profil?.phoneNumber?.phone)
        }
    }, [clientSelected])

    return (
        <div className="grid gap-6">
            <Return onClick={() => setMethod()} />
            <Text.h2 className="text-center">Éditer le client ?</Text.h2>
            <div>
                <Input.AutocompleteSearch.Default
                    initialValue={address?.formatted}
                    setValue={(value) => setAddress(value)}
                />
                <Input.Text.Classic
                    labelValue="Téléphone"
                    value={phoneNumber}
                    setValue={(value) => setPhoneNumber(value)}
                />
                <Input.Text.Classic
                    type="email"
                    labelValue="Email"
                    value={email}
                    setValue={(value) => setEmail(value)}
                />
            </div>
            <div className="flex mx-auto space-x-3">
                <Button.Secondary
                    onClick={() => {
                        setMethod()
                    }}
                >
                    Annuler
                </Button.Secondary>
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={async () => {
                        if (email) {
                            await updateEmail(email)
                        }
                        await editClient(address, phoneNumber)
                        setMethod()
                    }}
                >
                    Confirmer
                </Button.Primary>
            </div>
        </div>
    )
}

const Create = () => {
    const { search } = useLocation()
    const navigate = useNavigate()

    const { setMessage } = Request.useRequest()
    const { setMethod, createClient } = Hairdresser.useClients()

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState()
    const [phoneNumber, setPhoneNumber] = useState()

    return (
        <div className="grid gap-6">
            <Return
                onClick={() => {
                    if (search.indexOf('new') === -1) {
                        setMethod()
                    } else {
                        navigate(-1)
                    }
                }}
            />
            <Text.h2 className="text-center">Création d'un client</Text.h2>
            <div>
                <Input.Text.Classic
                    type="email"
                    labelValue="Email"
                    value={email}
                    setValue={(value) => setEmail(value)}
                />
                <Input.Text.Classic labelValue="Prénom" value={firstName} setValue={(value) => setFirstName(value)} />
                <Input.Text.Classic labelValue="Nom" value={lastName} setValue={(value) => setLastName(value)} />
                <Input.AutocompleteSearch.Default setValue={(value) => setAddress(value)} />
                <Input.Text.Classic
                    labelValue="Téléphone"
                    value={phoneNumber}
                    setValue={(value) => setPhoneNumber(value)}
                />
            </div>
            <div className="flex mx-auto space-x-3">
                <Button.Secondary
                    onClick={() => {
                        if (search.indexOf('new') === -1) {
                            setMethod()
                        } else {
                            navigate(-1)
                        }
                    }}
                >
                    Annuler
                </Button.Secondary>
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={async () => {
                        if (firstName.length > 1) {
                            if (lastName.length > 1) {
                                if (address?.formatted) {
                                    if (phoneNumber && phoneNumber?.length > 8) {
                                        await createClient(email, firstName, lastName, address, phoneNumber)
                                        if (search.indexOf('new') === -1) {
                                            setMethod()
                                        } else {
                                            navigate('/hairdresser?clientSelected')
                                        }
                                    } else setMessage({ type: 'info', message: 'Numéro de téléphone invalide' })
                                } else setMessage({ type: 'info', message: 'Adresse invalide' })
                            } else setMessage({ type: 'info', message: 'Nom invalide' })
                        } else setMessage({ type: 'info', message: 'Prénom invalide' })
                    }}
                >
                    Confirmer
                </Button.Primary>
            </div>
        </div>
    )
}

const DetailsBooking = () => {
    const { method, handleBookingSelected, bookingSelected, deleteBooking } = Hairdresser.useBooking()
    const { clientSelected } = Hairdresser.useClients()

    const prestationsDisplay = bookingSelected?._prestation
        .reduce((accumulator, currentValue) => {
            const valueFind = accumulator.find((item) => item.name === currentValue.name)

            if (valueFind) {
                valueFind.count = (valueFind.count || 1) + 1
            } else {
                accumulator.push({ ...currentValue, count: 1 })
            }
            return accumulator
        }, [])
        .map((prestation) => `${prestation.name}${prestation.count > 1 ? ` x${prestation.count}` : ''}`)
        .join(', ')

    return (
        <Modal.default
            title={bookingSelected?.state === 'done' ? 'Votre réservation passée' : 'Votre réservation à venir'}
            isOpen={method === 'details'}
            setIsOpen={() => {
                handleBookingSelected(null, null)
            }}
            confirmButton={
                bookingSelected?.state === 'done' ? (
                    <Button.Primary
                        iconLeft={<Icon.ArrowBack className="h-4" />}
                        onClick={() => {
                            handleBookingSelected(null, null)
                        }}
                    >
                        Retour
                    </Button.Primary>
                ) : (
                    <Button.Primary
                        iconRight={<Icon.Close className="h-4" />}
                        onClick={async () => {
                            await deleteBooking('')
                            handleBookingSelected(null, null)
                        }}
                    >
                        Annuler
                    </Button.Primary>
                )
            }
            backButton={
                bookingSelected?.state !== 'done' ? (
                    <Button.Secondary
                        iconLeft={<Icon.ArrowBack className="h-4" />}
                        onClick={() => {
                            handleBookingSelected(null, null)
                        }}
                    >
                        Retour
                    </Button.Secondary>
                ) : (
                    <></>
                )
            }
        >
            {bookingSelected && (
                <div className="grid gap-3 text-center">
                    <div className="flex items-center justify-around mx-auto mb-4 space-x-3">
                        <Text.Link
                            className="flex items-center gap-4 no-underline"
                            href={`tel:${
                                clientSelected?._user?._profil?.phoneNumber?.phone.length === 10
                                    ? clientSelected?._user?._profil?.phoneNumber?.phone
                                    : `0${clientSelected?._user?._profil?.phoneNumber?.phone}`
                            }`}
                        >
                            <Icon.Phone className="h-8" />
                        </Text.Link>
                        <div
                            style={{
                                backgroundImage: `url(${
                                    clientSelected?._user?._profil?.avatar?.urlThumnail
                                        ? clientSelected?._user?._profil?.avatar?.urlThumnail
                                        : clientSelected?._user?._profil?.sexe === 'female'
                                        ? Girl
                                        : Boy
                                })`,
                                backgroundPosition: `center center`,
                                backgroundSize: '100%',
                            }}
                            className="w-20 h-20 bg-no-repeat rounded-full bg-purple"
                        />
                        <Text.Paragraph.Normal className="text-xl font-bold leading-snug">
                            {clientSelected?._user?._profil?.firstName} <br />{' '}
                            {clientSelected?._user?._profil?.lastName}
                        </Text.Paragraph.Normal>
                    </div>

                    <p className="text-[20px] font-medium leading-4 text-center">{prestationsDisplay}</p>

                    <p className="text-[20px] font-medium leading-8 text-center">
                        {bookingSelected.priceVariable
                            ? `À partir de ${bookingSelected.price}€ - `
                            : `${bookingSelected.price}€ - `}
                        {`${formatSecondsToHHhmm(bookingSelected.duration)}`}
                    </p>
                    <p className="text-[17px] font-medium text-center capitalize">
                        {dayjs(bookingSelected.date).format('ddd DD MMMM YYYY') +
                            ' à ' +
                            formatSecondsToHHhmm(bookingSelected.from)}
                    </p>
                    <Text.Paragraph.Normal>{bookingSelected.address?.formatted}</Text.Paragraph.Normal>
                    <div>
                        {bookingSelected.note?.map((bookingNote) => (
                            <Text.Paragraph.Normal key={bookingNote._id}>{bookingNote.value}</Text.Paragraph.Normal>
                        ))}
                    </div>
                </div>
            )}
        </Modal.default>
    )
}

const Email = () => {
    const { method, setMethod, updateEmail } = Hairdresser.useClients()

    const [email, setEmail] = useState('')

    return (
        <Modal.default
            title={"Ajouter l'email"}
            isOpen={method === 'email'}
            setIsOpen={() => {
                setEmail('')
                setMethod()
            }}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="h-8" />}
                    onClick={async () => {
                        console.log('save email')
                        await updateEmail(email)
                        setEmail('')
                        setMethod()
                    }}
                >
                    Confirmer
                </Button.Primary>
            }
        >
            <div className="grid gap-6">
                <div>
                    <Input.Text.Classic
                        type="email"
                        labelValue="Email"
                        value={email}
                        setValue={(value) => setEmail(value)}
                    />
                </div>
            </div>
        </Modal.default>
    )
}

export { AddNote, Delete, Edit, Create, DetailsBooking, Email }
