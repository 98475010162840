import React from 'react'

import { Hairdresser } from '../../components/molecules'

import { Hairdresser as Context } from '../../contexts'

const Prestation = () => {
    const { step } = Context.usePrestation()
    return (
        <div>
            {!step || step === 'new' ? (
                <div className="grid">
                    <Hairdresser.Prestation.Navigation />
                    <Hairdresser.Prestation.Prestations />
                </div>
            ) : (
                <Hairdresser.Prestation.Modification />
            )}
            <Hairdresser.Prestation.Form />
        </div>
    )
}

export default Prestation
