import React, { useState } from 'react'
import { Button, Icon, Modal, Input, Text } from '../../../atoms'
import * as DraggableList from '../DragAndDrop'

import { Hairdresser } from '../../../../contexts'
import { SelectAdd } from '../../../atoms/Input/Dropdown'

const Biography = ({ isOpen, setIsOpen }) => {
    const { description, handleSetAttribute, handleSaveAttribute } = Hairdresser.useProfil()

    return (
        <Modal.default
            isOpen={isOpen}
            title={'Biographie'}
            setIsOpen={(value) => {
                setIsOpen(value)
            }}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={async () => {
                        await handleSaveAttribute('description', description)
                        setIsOpen(false)
                    }}
                >
                    Valider
                </Button.Primary>
            }
        >
            <Input.Text.TextArea
                value={
                    description
                        ? typeof description === 'string'
                            ? description
                            : typeof description === 'object'
                            ? description.length > 0
                                ? typeof description[0] === 'string'
                                    ? description[0]
                                    : description[0][0]?.children[0]?.text
                                : ''
                            : ''
                        : ''
                }
                setValue={(value) => {
                    handleSetAttribute('description', value)
                }}
            />
        </Modal.default>
    )
}

const Speciality = ({ isOpen, setIsOpen }) => {
    const { specialities, globalSpecialities, handleSetAttribute, addSpeciality, handleSaveAttribute } =
        Hairdresser.useProfil()

    const steps = ['general', 'new']
    const [step, setStep] = useState(steps[0])

    const [name, setName] = useState('')

    return (
        <Modal.default
            isOpen={isOpen}
            title={step === steps[0] ? 'Modifier mes spécialités' : 'Créer une spécialité'}
            setIsOpen={
                step === steps[0]
                    ? (value) => {
                          setIsOpen(value)
                      }
                    : () => setStep(steps[0])
            }
            confirmButton={
                <>
                    {step === steps[0] ? (
                        <Button.Primary
                            iconRight={<Icon.ArrowNext className="w-5" />}
                            onClick={() => {
                                handleSaveAttribute('specialities', specialities)
                            }}
                        >
                            Valider
                        </Button.Primary>
                    ) : (
                        <Button.Primary
                            iconRight={<Icon.ArrowNext className="w-5" />}
                            onClick={async () => {
                                await addSpeciality(name)
                                setStep(steps[0])
                            }}
                        >
                            Ajouter
                        </Button.Primary>
                    )}
                </>
            }
        >
            {step === steps[0] ? (
                <>
                    <DraggableList.Specialities
                        initialItems={specialities}
                        setNewValues={(value) => {
                            handleSetAttribute('specialities', value)
                        }}
                        deleteItem={(index) => {
                            const newSpecialities = [...specialities]
                            newSpecialities.splice(index, 1)
                            handleSetAttribute('specialities', newSpecialities)
                        }}
                    />
                    <div className="grid gap-2">
                        <SelectAdd
                            value=""
                            options={globalSpecialities?.map((speciality) => {
                                return { label: speciality.name, value: speciality._id }
                            })}
                            setValue={(value) => {
                                const newSpecialities = [...specialities]

                                let specialityObject
                                globalSpecialities?.forEach((speciality) => {
                                    if (speciality._id === value) specialityObject = speciality
                                })
                                if (specialityObject) {
                                    newSpecialities?.push(specialityObject)
                                }
                                handleSetAttribute('specialities', newSpecialities)
                            }}
                        />
                        <div onClick={() => setStep(steps[1])}>
                            <Text.Link className="ml-2">Créer une spécialité personnalisé</Text.Link>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Input.Text.Classic
                        labelValue="Nom de la spécialité"
                        value={name}
                        setValue={(value) => setName(value)}
                        placeholder="Coupe homme"
                    />
                </>
            )}
        </Modal.default>
    )
}

const Category = ({ isOpen, setIsOpen }) => {
    const { category, handleSetAttribute, handleSaveAttribute } = Hairdresser.useProfil()

    return (
        <Modal.default
            isOpen={isOpen}
            title={'Modifier mes catégories'}
            setIsOpen={(value) => {
                setIsOpen(value)
            }}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={() => {
                        handleSaveAttribute('category', category)
                    }}
                >
                    Valider
                </Button.Primary>
            }
        >
            <div
                className={`bg-blue text-black border-[1.5px] border-black rounded px-2 ${
                    category?.indexOf('coiffure') === -1 ? 'opacity-30' : ''
                }`}
                onClick={() => {
                    if (category?.indexOf('coiffure') === -1) {
                        handleSetAttribute('category', [...category, 'coiffure'])
                    } else {
                        if (category?.indexOf('esthetique') === -1) handleSetAttribute('category', [])
                        else handleSetAttribute('category', ['esthetique'])
                    }
                }}
            >
                Coiffure
            </div>
            <div
                className={`bg-purple text-black border-[1.5px] border-black rounded px-2 ${
                    category?.indexOf('esthetique') === -1 ? 'opacity-30' : ''
                }`}
                onClick={() => {
                    if (category?.indexOf('esthetique') === -1) {
                        handleSetAttribute('category', [...category, 'esthetique'])
                    } else {
                        if (category?.indexOf('coiffure') === -1) handleSetAttribute('category', [])
                        else handleSetAttribute('category', ['coiffure'])
                    }
                }}
            >
                Esthétique
            </div>
        </Modal.default>
    )
}

export { Biography, Speciality, Category }
