import React, { useEffect, useState } from 'react'

import { DateProvider } from '../../../atoms'
import { DateCalendar } from '@mui/x-date-pickers'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'

import { Auth, Hairdresser } from '../../../../contexts'

import dayjs from 'dayjs'
import fr from 'dayjs/locale'
dayjs.locale(fr)

function ServerDay(props) {
    const { booked = [], waitings = [], day, outsideCurrentMonth, ...other } = props

    const isBooked =
        booked.filter((day) => day.format('YYYY-MM-DD') === dayjs(props.day).format('YYYY-MM-DD'))?.length > 0
    const isWaiting =
        waitings.filter((day) => day.format('YYYY-MM-DD') === dayjs(props.day).format('YYYY-MM-DD'))?.length > 0

    return (
        <div className="relative mx-auto">
            <div
                className={`absolute bottom-0 left-[40%] rounded-full ${
                    isBooked && !outsideCurrentMonth ? 'bg-blue w-[6px] h-[6px]' : ''
                } ${isWaiting && !outsideCurrentMonth ? 'bg-yellow w-[6px] h-[6px]' : ''}`}
            ></div>
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </div>
    )
}

const CalendarGroup = () => {
    const { isLogged } = Auth.useAuth()
    const { data, date, loaded, fetchMonth, handleDate, stateRefresh } = Hairdresser.useAgenda()

    const [booked, setBooked] = useState([])
    const [waitings, setWaitings] = useState([])
    const [nothing, setNothing] = useState(false)

    useEffect(() => {
        if (isLogged()) {
            console.log(date)
            if (data.loadedDays) {
                if (!data.loadedDays[date] && !nothing) {
                    fetchMonth(date)
                    setNothing(true)
                } else {
                    const daysBooked = []
                    const daysWaiting = []

                    Object.keys(data.loadedDays).map((day) => {
                        const isBooked =
                            data.loadedDays[day]?.bookings?.filter(
                                (booking) =>
                                    (booking.state === 'booked' || booking.state === 'done') && !booking.isPrivate
                            ).length > 0

                        const isWaiting =
                            data.loadedDays[day]?.bookings?.filter((booking) => booking.state === 'waiting').length > 0

                        if (isWaiting) {
                            daysWaiting.push(dayjs(day))
                        } else if (isBooked) {
                            daysBooked.push(dayjs(day))
                        }
                    })

                    setBooked(daysBooked)
                    setWaitings(daysWaiting)
                }
            }
        }
    }, [data.loadedDays, stateRefresh])

    return (
        <div className="grid gap-6">
            <div className="border rounded-md">
                <DateProvider>
                    {loaded ? (
                        <DateCalendar
                            defaultValue={dayjs(date)}
                            value={dayjs(date)}
                            onChange={(value) => {
                                handleDate(value.format('YYYY-MM-DD'))
                            }}
                            views={['day']}
                            slots={{ day: ServerDay }}
                            slotProps={{ day: { booked: booked, waitings: waitings } }}
                        />
                    ) : (
                        <DateCalendar views={['day']} />
                    )}
                </DateProvider>
            </div>
        </div>
    )
}

export default CalendarGroup
