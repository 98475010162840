import React, { useState } from 'react'
import { DateProvider, Input, Text } from '../atoms'
import { DateCalendar } from '@mui/x-date-pickers'

import { Hairdresser } from '../../contexts'
import dayjs from 'dayjs'
import fr from 'dayjs/locale'
dayjs.locale(fr)

const CalendarHeader = ({ activeWeek }) => {
    const views = [
        { label: 'Mois', value: 'month' },
        { label: 'Semaine', value: 'week' },
        { label: 'Jour', value: 'day' },
    ]
    const [view, setView] = useState('month')

    return (
        <div className="grid items-center grid-cols-2">
            <div className="flex">
                <Input.Dropdown value={view} options={views} setValue={(value) => setView(value)} />
            </div>
            <div className="flex justify-end">
                <div className="bg-[#D9D9D9] bg-opacity-40 w-10 flex justify-center items-center h-10">
                    <Text.Paragraph.Normal className="text-xl">{activeWeek}</Text.Paragraph.Normal>
                </div>
            </div>
        </div>
    )
}

const Calendar = () => {
    const { date, handleDate, fetchMonth } = Hairdresser.useAgenda()

    return (
        <div className="border border-[#D9D9D9] rounded-lg">
            <DateProvider>
                <DateCalendar
                    value={dayjs(date)}
                    onChange={(value) => {
                        if (value.month() !== dayjs(date).month()) {
                            fetchMonth(value.format('YYYY-MM-DD'))
                        }
                        handleDate(value.format('YYYY-MM-DD'))
                    }}
                    views={['day']}
                />
            </DateProvider>
        </div>
    )
}

export default Calendar
export { CalendarHeader }
