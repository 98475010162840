import React from 'react'
import { Input } from '..'
import { Client } from '../../../contexts'

const Searchbar = ({ setValue, setLoading }) => {
    const { getCoiffeurs, address } = Client.useCoiffeur()
    return (
        <Input.AutocompleteSearch.Default
            initialValue={address?.formatted}
            setValue={(value) => {
                if (setValue) {
                    setValue(value)
                } else {
                    getCoiffeurs(value)
                }
            }}
            setLoading={(value) => {
                if (setLoading) {
                    setLoading(value)
                }
            }}
        />
    )
}

export default Searchbar
