/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'

const SEARCH_BY_VALUES = [
    { value: 'Prénom', method: 'firstName', placeholder: 'Jean' },
    { value: 'Nom', method: 'lastName', placeholder: 'Dupont' },
    { value: 'Nom + Prénom', method: 'firstName,lastName', placeholder: 'Dupont Jean' },
]
const SORT_VALUES = ['Z-A', 'A-Z']

const ClientsContext = createContext({
    clients: Array,
    clientSelected: Object,
    search: String,
    method: String,
    searchBy: Number,
    SEARCH_BY_VALUES: SEARCH_BY_VALUES,
    sort: Number,
    SORT_VALUES: SORT_VALUES,
    getClients: async () => {
        return Promise.resolve()
    },
    setClientSelected: () => {},
    setSearch: (search) => {},
    filterClients: () => {},
    getClientById: async (id) => {},
    deleteClient: async (id) => {},
    editClient: async (address, phoneNumber) => {},
    createClient: async (email, firstName, lastName, address, phoneNumber) => {},
    setMethod: (value) => {},
    updateEmail: async (email) => {},
    nextSearchBy: () => {},
    nextSort: () => {},
})

export function ClientsContextProvider({ children }) {
    const { handleRequest, setMessage } = useRequest()
    const { token, id, isLogged } = useAuth()

    const [clients, setClients] = useState([])
    const [clientSelected, setClientSelected] = useState()
    const [search, setSearch] = useState('')
    const [method, setMethod] = useState()
    const [searchBy, setSearchBy] = useState(0)
    const [sort, setSort] = useState(1)

    const getClients = async () => {
        if (isLogged()) {
            const response = await handleRequest('get', `coiffeur/clients`, null, token)

            if (response?.data) {
                setClients(response.data.clients)
            }
            return
        }
    }

    const filterClients = async (value) => {
        setSearch(value)
        if (value !== '') {
            const response = await handleRequest('get', `coiffeur/clients/search?search=${value}&sort=1`, null, token)

            if (response?.data) {
                console.log(response.data)
                setClients(response.data.clients)
            }
        } else {
            await getClients()
        }
    }

    const getClientById = async (userId) => {
        if (isLogged()) {
            const response = await handleRequest('get', `coiffeur/clients/${userId}`, null, token)

            if (response?.data) {
                setClientSelected({
                    ...clientSelected,
                    fetched: true,
                    bookings: response.data.client.bookings,
                })
            }
            return
        } else console.log('non conntecté')
    }

    const deleteClient = async (id) => {
        if (isLogged()) {
            const response = await handleRequest('delete', `coiffeur/clients/${id}`, {}, token)

            if (response?.data) {
                setClientSelected()
                setClients(response.data.clients)
            }
            return
        } else console.log()
    }

    const editClient = async (address, phoneNumber) => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `coiffeur/clients/${clientSelected?._user?._id}`,
                { address: address, phoneNumber: phoneNumber },
                token
            )

            if (response?.data) {
                setClientSelected(response.data.client)
                setClients(response.data.clients)
            }
            return
        } else console.log('non connecté')
    }

    const createClient = async (email, firstName, lastName, address, phoneNumber) => {
        if (isLogged()) {
            const response = await handleRequest(
                'post',
                `coiffeur/clients`,
                {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    address: address,
                    phoneNumber: phoneNumber,
                },
                token
            )

            if (response?.data) {
                setClientSelected(response.data.client)
                setClients(response.data.clients)
            }
            return
        } else console.log('non connecté')
    }

    const updateEmail = async (email) => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `coiffeur/clients/${clientSelected?._user?._id}/email`,
                {
                    email: email,
                },
                token
            )

            if(response.data?.message){
                setMessage({type: "info", message: response.data.message})
            }

            return
        } else console.log('non connecté')
    }

    const nextSearchBy = () => {
        const nextIndex = searchBy < 2 ? searchBy + 1 : 0

        setSearchBy(nextIndex)
    }

    const nextSort = () => {
        const nextIndex = sort === 0 ? 1 : 0

        setSort(nextIndex)
    }

    return (
        <ClientsContext.Provider
            value={{
                clients: clients,
                clientSelected: clientSelected,
                search: search,
                method: method,
                searchBy: searchBy,
                SEARCH_BY_VALUES: SEARCH_BY_VALUES,
                sort: sort,
                SORT_VALUES: SORT_VALUES,
                getClients: getClients,
                setClientSelected: setClientSelected,
                setSearch: setSearch,
                filterClients: filterClients,
                getClientById: getClientById,
                deleteClient: deleteClient,
                editClient: editClient,
                createClient: createClient,
                setMethod: setMethod,
                updateEmail: updateEmail,
                nextSearchBy: nextSearchBy,
                nextSort: nextSort,
            }}
        >
            {children}{' '}
        </ClientsContext.Provider>
    )
}

export const useClients = () => useContext(ClientsContext)
