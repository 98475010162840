import React, { useEffect, useState } from 'react'
import { Icon, Input, Text, Modal as AtomModal, Button } from '../../atoms'
import { Image } from '../../organisms'

import { Tooltip, Button as ButtonNext } from '@nextui-org/react'
import { Auth, Hairdresser } from '../../../contexts'

import { Modal } from '.'

const Card = ({ goToSettings }) => {
    const { firstName, lastName, avatar } = Hairdresser.useProfil()

    return (
        <div className="flex justify-between">
            <div className="flex items-center space-x-8">
                <div
                    className="rounded-full w-[100px] h-[100px] bg-light-gray"
                    style={{
                        backgroundImage: `url(${avatar?.thumbnailUrl})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                    }}
                ></div>
                <Text.h2>
                    {firstName}
                    <br />
                    {lastName}
                </Text.h2>
            </div>
            <div className="my-auto h-fit w-fit" onClick={() => goToSettings()}>
                <Icon.Settings className="w-9" />
            </div>
        </div>
    )
}

const Visibility = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => setIsOpen(false), 3000)
        }
    }, [isOpen])

    return (
        <div className="flex items-center space-x-3 w-fit">
            <Input.Toggle />
            <Tooltip
                isOpen={isOpen}
                className="p-2 px-4 rounded bg-light-gray"
                content="Souhaitez vous apparaître dans les recherches"
            >
                <ButtonNext className="flex items-center space-x-3" onMouseEnter={() => setIsOpen(true)}>
                    <Text.Paragraph.Normal>Visibilité du profil</Text.Paragraph.Normal>
                    <Icon.Info className="w-6" />
                </ButtonNext>
            </Tooltip>
        </div>
    )
}

const Contact = () => {
    const { email } = Auth.useAuth()
    const { phoneNumber } = Hairdresser.useProfil()

    return (
        <div className="grid gap-3">
            <Text.Paragraph.Normal className="text-[18px] leading-8 font-medium">Coordonées</Text.Paragraph.Normal>
            <div className="flex items-center space-x-3">
                <Icon.Email className="w-9" />
                <Text.Paragraph.Normal>{email}</Text.Paragraph.Normal>
            </div>
            <div className="flex items-center space-x-3">
                <Icon.Phone className="w-9" />
                <Text.Paragraph.Normal>
                    {phoneNumber?.phone?.length === 9
                        ? ('0' + phoneNumber?.phone)?.replace(/(.{2})/g, '$1 ')
                        : phoneNumber?.phone?.replace(/(.{2})/g, '$1 ')}
                </Text.Paragraph.Normal>
            </div>
        </div>
    )
}

const SocialAndYears = () => {
    const { yearsExperience, handleSetAttribute, handleSaveAttribute } = Hairdresser.useProfil()
    return (
        <div className="grid grid-cols-2">
            <Input.Text.Classic
                value={yearsExperience}
                labelValue="Expérience (années)"
                type="number"
                setValue={(value) => handleSetAttribute('yearsExperience', value)}
                onBlur={() => handleSaveAttribute('yearsExperience', parseInt(yearsExperience))}
                unit={'ans'}
            />
        </div>
    )
}

const Biography = () => {
    const { description } = Hairdresser.useProfil()

    const [edit, setEdit] = useState(false)

    return (
        <div className="grid gap-4">
            <div className="flex justify-between">
                <Text.Paragraph.Normal className="text-[18px] leading-8 font-medium">Biographie</Text.Paragraph.Normal>
                <div
                    className="flex items-center space-x-1"
                    onClick={() => {
                        setEdit(true)
                    }}
                >
                    <Icon.Pencil className="w-6" />
                    <Text.Link>Modifier</Text.Link>
                </div>
            </div>
            <Text.Paragraph.Normal>
                {description && (
                    <>
                        {typeof description === 'string'
                            ? description
                            : typeof description === 'object'
                            ? description?.length > 0
                                ? typeof description[0] === 'string'
                                    ? description[0]
                                    : description[0][0]?.children[0]?.text
                                : ''
                            : ''}
                    </>
                )}
            </Text.Paragraph.Normal>
            <Modal.Profil.Biography isOpen={edit} setIsOpen={(value) => setEdit(value)} />
        </div>
    )
}

const Category = () => {
    const { category, needSave } = Hairdresser.useProfil()

    const [edit, setEdit] = useState(false)

    useEffect(() => {
        if (edit && !needSave) {
            setEdit(false)
        }
    }, [needSave])

    return (
        <div className="grid gap-4">
            <div className="flex justify-between">
                <Text.Paragraph.Normal className="text-[18px] leading-8 font-medium">Catégories</Text.Paragraph.Normal>
                <div
                    className="flex items-center space-x-1"
                    onClick={() => {
                        setEdit(true)
                    }}
                >
                    <Icon.Pencil className="w-6" />
                    <Text.Link>Modifier</Text.Link>
                </div>
            </div>
            <div className="flex flex-wrap space-x-1">
                {category?.length === 0 && <span className="italic">Aucune catégorie</span>}
                {category?.map((cat, index) => (
                    <div className="px-3 mb-2 leading-8 capitalize rounded-full bg-green h-fit" key={index}>
                        {cat}
                    </div>
                ))}
            </div>
            <Modal.Profil.Category isOpen={edit} setIsOpen={(value) => setEdit(value)} />
        </div>
    )
}

const Speciality = () => {
    const { specialities, needSave } = Hairdresser.useProfil()

    const [edit, setEdit] = useState(false)

    useEffect(() => {
        if (edit && !needSave) {
            setEdit(false)
        }
    }, [needSave])

    return (
        <div className="grid gap-4">
            <div className="flex justify-between">
                <Text.Paragraph.Normal className="text-[18px] leading-8 font-medium">Spécialités</Text.Paragraph.Normal>
                <div
                    className="flex items-center space-x-1"
                    onClick={() => {
                        setEdit(true)
                    }}
                >
                    <Icon.Pencil className="w-6" />
                    <Text.Link>Modifier</Text.Link>
                </div>
            </div>
            <div className="flex flex-wrap space-x-1">
                {specialities?.map((spaciality) => (
                    <div className="px-3 mb-2 leading-8 rounded-full bg-green h-fit" key={spaciality._id}>
                        {spaciality.name}
                    </div>
                ))}
            </div>
            <Modal.Profil.Speciality isOpen={edit} setIsOpen={(value) => setEdit(value)} />
        </div>
    )
}

const Galery = () => {
    const { photos, handleSaveAttribute } = Hairdresser.useProfil()

    const [addPhoto, setAddPhoto] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState()

    return (
        <div className="grid gap-4">
            <div className="grid gap-2">
                <Text.Paragraph.Normal className="text-xl font-medium">Galerie photos</Text.Paragraph.Normal>
                <div className="grid grid-cols-2 gap-2">
                    {photos?.map((photo, index) => {
                        return (
                            <>
                                <div
                                    key={photo?._id}
                                    className="relative rounded-lg w-[40vw] h-[40vw] bg-light-gray border border-black border-opacity-10"
                                    style={{
                                        backgroundImage: `url(${photo?.thumbnailUrl})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    <div
                                        className="absolute p-2 rounded-full bottom-2 right-2 bg-light-gray"
                                        onClick={() => {
                                            setDeleteIndex(index)
                                        }}
                                    >
                                        <Icon.Trash className="w-6" />
                                    </div>
                                </div>
                            </>
                        )
                    })}
                    <div
                        className="w-[40vw] h-[40vw] bg-light-gray flex items-center justify-center rounded-lg relative"
                        onClick={() => {
                            setAddPhoto(true)
                        }}
                    >
                        <Icon.Camera className="text-gray-500 w-14" />
                        <Icon.Add className="absolute w-4 text-gray-500 right-11 top-11" />
                    </div>
                </div>
            </div>
            <Image.Upload
                title="Ajouter un média"
                setIsOpen={() => setAddPhoto(false)}
                isOpen={addPhoto}
                afterUpload={async (value) => {
                    const newPhotos = [...photos]
                    newPhotos.push(value)
                    handleSaveAttribute('photos', newPhotos)
                    setAddPhoto(false)
                }}
            />
            <AtomModal.default
                title={'Supprimer le média ?'}
                isOpen={typeof deleteIndex === 'number'}
                setIsOpen={() => setDeleteIndex()}
                backButton={<Button.Secondary onClick={() => setDeleteIndex()}>Annuler</Button.Secondary>}
                confirmButton={
                    <Button.Red
                        onClick={() => {
                            const newPhotos = [...photos]
                            newPhotos.splice(deleteIndex, 1)
                            handleSaveAttribute('photos', newPhotos)
                            setDeleteIndex()
                        }}
                    >
                        Confirmer
                    </Button.Red>
                }
            ></AtomModal.default>
        </div>
    )
}

const CopyLink = () => {
    const { id } = Auth.useAuth()

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => setIsOpen(false), 3000)
        }
    }, [isOpen])

    return (
        <Tooltip
            isOpen={isOpen}
            className="px-4 py-2 border border-white rounded bg-light-gray"
            content={
                <span className="flex space-x-2">
                    <Icon.Check className="w-6 text-green" /> Lien copié dans ton presse papier
                </span>
            }
        >
            <div
                className="flex items-center justify-start space-x-3"
                onClick={() => {
                    navigator.clipboard.writeText(`https://ilea-app.com/hairdresser/${id}/details`)
                    setIsOpen(true)
                }}
            >
                <Icon.Copy className="w-6 h-6" />
                <Text.Link>Copier le lien de mon profil</Text.Link>
            </div>
        </Tooltip>
    )
}

export { Card, Visibility, Contact, SocialAndYears, Biography, Speciality, Galery, CopyLink, Category }
