import React from 'react'

export const h1 = ({ className, children }) => {
    return <h1 className={`text-3xl font-bold leading-[48px] ${className ? className : ''}`}>{children}</h1>
}

export const h2 = ({ className, children }) => {
    return <h1 className={`text-2xl font-bold leading-8 ${className ? className : ''}`}>{children}</h1>
}

export const Clickable = ({ className, children }) => {
    return <h3 className={`text-lg font-medium leading-5 cursor-pointer ${className ? className : ''}`}>{children}</h3>
}

export const Link = ({ href, className, children }) => {
    return (
        <a href={href} className={`leading-5 underline underline-offset-2 ${className ? className : ''}`}>
            {children}
        </a>
    )
}

const Normal = ({ className, children }) => {
    return <p className={`text-base leading-5 ${className ? className : ''}`}>{children}</p>
}

const Small = ({ className, children }) => {
    return <p className={`text-xs leading-4 ${className ? className : ''}`}>{children}</p>
}

export const Paragraph = { Small, Normal }

export const Legend = ({ className, children }) => {
    return <legend className={`text-2xs leading-3 uppercase ${className ? className : ''}`}>{children}</legend>
}
