exports.formatSecondsToHHhmm = (seconds) => {
    var hours = Math.floor(seconds / 3600)
    var minutes = Math.floor((seconds % 3600) / 60)

    var formattedTime = hours.toString().padStart(2, '0') + 'h' + minutes.toString().padStart(2, '0')

    return formattedTime
}

exports.formatHHmmToMilliseconds = (hhmm) => {
    var hours = hhmm.split(':')[0]
    var minutes = hhmm.split(':')[1]

    return (hours * 3600 + minutes * 60) * 1000
}

exports.formatSecondsToInput = (seconds) => {
    var hours = Math.floor(seconds / 3600)
    var minutes = Math.floor((seconds % 3600) / 60)

    var formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0')

    return formattedTime
}

exports.prestationObjectsToArray = (prestationObjects) => {
    const prestations = []

    prestationObjects.forEach((prestationObject) => {
        if (prestationObject.quantity > 0) {
            for (let i = 0; i < prestationObject.quantity; i++) {
                prestations.push(prestationObject._id)
            }
        }
    })

    console.log(prestations)

    return prestations
}

exports.arraysEqual = (a, b) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false
    }
    return true
}
